<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts"></script>
<template>
  <div
    style="font-family: Geist Mono Variable"
    class="inline-flex px-2xs justify-center items-center rounded-xs border-[1px] bg-subtle text-[12px] leading-5 font-medium text-fg-subtle"
  >
    <slot />
  </div>
</template>
