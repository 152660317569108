<script setup lang="ts">
import { ref, watch } from "vue";

import { useAuthStore } from "@/stores/AuthStore";
import { backend } from "@/main";
import { toast } from "vue3-toastify";

import { TabsContent, TabsList, TabsRoot } from "radix-vue";
import Tab from "@/components/ui/tabs/Tab.vue";
import SelectedTabIndicator from "@/components/ui/tabs/SelectedTabIndicator.vue";

import Drawer from "@/components/logs/Drawer.vue";

import {
  SelectGroup,
  SelectPortal,
  SelectRoot,
  SelectViewport,
} from "radix-vue";
import SelectTrigger from "@/components/ui/select/selectTrigger/SelectTrigger.vue";
import SelectContent from "@/components/ui/select/SelectContent.vue";
import SelectItem from "@/components/ui/select/SelectItem.vue";

import Icon from "@/components/ui/icons/Icon.vue";
import Counter from "@/components/ui/Counter.vue";

import { toSnakeCase } from "@/utils/utils";

import { type IApiKey } from "@/types/apiKey";
import {
  type PlaygroundLogItem,
  type ApiLogItem,
  type SelectedLog,
} from "@/types/logs";

import "vue3-toastify/dist/index.css";
import { CanceledError } from "axios";

const authStore = useAuthStore();

const apiKeys = ref<IApiKey[] | undefined[]>([]);
const selectedApiKey = ref("");

const data_list = ref<(PlaygroundLogItem | ApiLogItem)[]>([]);
const isDataLoading = ref<boolean>(false);
const openedLog = ref<SelectedLog>({
  isLogOpen: false,
  log: {} as PlaygroundLogItem | ApiLogItem,
});
const openLogDetails = (log: PlaygroundLogItem | ApiLogItem) => {
  openedLog.value.isLogOpen = true;
  openedLog.value.log = log;
};
const closeLogDetails = () => (openedLog.value.isLogOpen = false);

const detectors = [
  "Prompt Injection",
  "PII",
  "Keywords",
  "Allowed Subjects",
  "Banned Subjects",
  "Secrets",
  "Toxicity",
];

const selectedDetector = ref(detectors[0]);

backend
  .get(`/api_key/get/${authStore.user.uid}`)
  .then((response) => {
    apiKeys.value = response.data.keys;
  })
  .catch((error) => {
    toast.error("Failed to fetch api keys", { autoClose: 500 });
    console.error(error);
  });

const onClickTab = async (key_id = "") => {
  if (selectedApiKey.value === key_id) return;
  selectedApiKey.value = key_id;
  getLogs();
};

let abortContr;

function getAbortController(): AbortController {
  if (abortContr) abortContr.abort();
  abortContr = new AbortController();
  return abortContr;
}

const getLogs = () => {
  data_list.value = [];
  const signal = getAbortController().signal;

  if (selectedApiKey.value !== "") {
    backend
      .get(`/api_key/logs/${selectedApiKey.value}`, { signal })
      .then((payload) => {
        const detectorLogs =
          payload.data.logs[toSnakeCase(selectedDetector.value)];
        if (!detectorLogs) {
          toast.error("Detector not found", {
            autoClose: 500,
          });
          return;
        }

        detectorLogs.forEach((element: ApiLogItem) => {
          element.created_at = new Date(element.created_at).toLocaleString();
          data_list.value.push(element);
        });
      })
      .catch((error) => {
        if (error instanceof CanceledError) return;
        toast.error("Failed to fetch logs", { autoClose: 500 });
        console.error(error);
      });

    return;
  }

  backend
    .get(`/chat/logs/get/${authStore.user.uid}`, { signal })
    .then((payload) => {
      payload.data.forEach((element: PlaygroundLogItem) => {
        const expectedLlm = PRETTY_LLM_NAMES[element.llm];
        if (expectedLlm) element.llm = expectedLlm;
        element.created_at = new Date(element.created_at).toLocaleString();

        data_list.value.push(element);
      });
    })
    .catch((error) => {
      if (error instanceof CanceledError) return;
      toast.error("Failed to fetch logs", { autoClose: 500 });
      console.error(error);
    });
};

const PRETTY_LLM_NAMES = {
  chatgpt: "ChatGPT",
  llama2: "Llama 2",
  claude: "Claude",
  gemini: "Gemini",
} as const;

getLogs();

watch(selectedDetector, () => {
  getLogs();
});
</script>

<template>
  <Drawer :openedLog="openedLog" @clicked="closeLogDetails" />
  <div class="py-7xl px-6xl">
    <TabsRoot class="flex flex-col w-full" default-value="tab1">
      <TabsList class="relative flex justify-between border-b mb-4xl">
        <SelectedTabIndicator />
        <div>
          <Tab value="tab1" @click="onClickTab('')"> Playground</Tab>

          <Tab
            v-for="key in apiKeys"
            :key="key.key_id"
            @click="onClickTab(key.key_id)"
            :value="key.key_id"
          >
            Api Key: <Counter>{{ key.key_name }}</Counter>
          </Tab>
        </div>

        <SelectRoot v-if="selectedApiKey !== ''" v-model="selectedDetector">
          <SelectTrigger styles="mb-[8px] max-h-[36px]" size="small" />

          <SelectPortal>
            <SelectContent :side-offset="5" side="bottom" position="popper">
              <SelectViewport class="p-[5px]">
                <SelectGroup>
                  <SelectItem :items="detectors" value="any" />
                </SelectGroup>
              </SelectViewport>
            </SelectContent>
          </SelectPortal>
        </SelectRoot>
      </TabsList>

      <TabsContent class="relative grow bg-white" value="tab1">
        <div
          class="text-fg-subtle text-[13px] leading-5 font-weight min-w-[1056px] flex flex-row items-center border-[1px] justify-between border-base rounded-small bg-subtle overflow-x-scroll"
        >
          <div class="pl-2xl pr-medium py-medium w-[43px]">#</div>
          <div class="px-medium flex-1 max-w-[120px]">LLM</div>
          <div class="px-medium flex-1 max-w-[581px]">Message</div>
          <div class="px-medium flex-1 max-w-[200px] text-right">Timestamp</div>
          <div class="px-medium flex-1 max-w-[87px]">Cache hits</div>
        </div>

        <div class="min-h-[692px] relative">
          <div v-if="isDataLoading === true">
            <Icon
              name="LoadingSpinner"
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 items-center text-fg-base"
            />
          </div>

          <div v-if="data_list.length === 0">
            <div
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 items-center flex flex-col gap-[4px]"
            >
              <Icon name="Flag" />

              <p>You don't have any logs yet</p>
            </div>
          </div>

          <div class="min-h-[692px]" v-else>
            <div
              v-for="(singleData, index) in (data_list as PlaygroundLogItem[])"
              :key="index"
              @click="openLogDetails(singleData)"
              class="text-fg-base text-[14px] leading-5 font-normal flex flex-row items-center justify-between relative min-w-[1056px] border-b border-b-muted cursor-pointer hover:bg-[#F9F9FB]"
            >
              <div
                class="pl-2xl pr-medium py-medium w-[43px]"
                style="font-family: Geist Mono Variable"
              >
                {{ index + 1 }}
              </div>

              <div class="px-medium flex-1 max-w-[120px]">
                {{ singleData.llm }}
              </div>
              <div class="px-medium flex-1 max-w-[581px] truncate">
                {{ singleData.message }}
              </div>
              <div
                class="px-medium flex-1 max-w-[200px] text-right"
                style="font-family: Geist Mono Variable"
              >
                {{ singleData.created_at }}
              </div>
              <div
                class="px-medium flex-1 max-w-[87px] text-right"
                style="font-family: Geist Mono Variable"
              >
                {{ singleData.cache_hits }}
              </div>
            </div>
          </div>
        </div>
      </TabsContent>

      <!-- FOR API KEYS -->
      <TabsContent
        v-for="key in apiKeys"
        :key="key.key_id"
        class="grow bg-white"
        :value="key.key_id"
      >
        <div
          class="text-fg-subtle text-[13px] leading-5 font-weight min-w-[1056px] flex flex-row items-center border-[1px] justify-between border-base rounded-small bg-subtle overflow-x-scroll"
        >
          <div class="pl-2xl pr-medium py-medium w-[43px]">#</div>
          <div class="px-medium flex-1 max-w-[581px]">Message</div>
          <div class="px-medium flex-1 max-w-[200px] text-right">Timestamp</div>
          <div class="px-medium flex-1 max-w-[87px]">Cache hits</div>
        </div>

        <div class="min-h-[692px] relative">
          <div v-if="isDataLoading === true">
            <Icon
              name="LoadingSpinner"
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 items-center text-fg-base"
            />
          </div>

          <div v-if="data_list.length === 0">
            <div
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 items-center flex flex-col gap-[4px]"
            >
              <Icon name="Flag" />

              <p>You don't have any logs yet</p>
            </div>
          </div>

          <div v-else>
            <div
              v-for="(singleData, index) in (data_list as ApiLogItem[])"
              :key="index"
              @click="openLogDetails(singleData)"
              class="text-fg-base text-[14px] leading-5 font-normal flex flex-row items-center justify-between relative min-w-[1056px] border-b border-b-muted cursor-pointer hover:bg-[#F9F9FB]"
            >
              <div
                class="pl-2xl pr-medium py-medium w-[43px]"
                style="font-family: Geist Mono Variable"
              >
                {{ index + 1 }}
              </div>

              <div class="px-medium flex-1 max-w-[581px] truncate">
                {{
                  singleData.messages !== undefined
                    ? singleData.messages.join(", ")
                    : ""
                }}
              </div>
              <div
                class="px-medium flex-1 max-w-[200px] text-right"
                style="font-family: Geist Mono Variable"
              >
                {{ singleData.created_at }}
              </div>
              <div
                class="px-medium flex-1 max-w-[87px] text-right"
                style="font-family: Geist Mono Variable"
              >
                {{ singleData.cache_hits }}
              </div>
            </div>
          </div>
        </div>
      </TabsContent>
    </TabsRoot>
  </div>
</template>
