<script setup lang="ts">
import { type Ref, reactive, ref, watch } from "vue";

import useChatDetails from "@/composables/useDetails";

import { backend } from "@/main";
import { useAuthStore } from "@/stores/AuthStore";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import KeywordBadges from "@/components/policy/KeywordBadges.vue";

import { TabsContent, TabsList, TabsRoot } from "radix-vue";
import Tab from "@/components/ui/tabs/Tab.vue";
import SelectedTabIndicator from "@/components/ui/tabs/SelectedTabIndicator.vue";
import Counter from "@/components/ui/Counter.vue";

import {
  SelectGroup,
  SelectPortal,
  SelectRoot,
  SelectViewport,
} from "radix-vue";
import SelectTrigger from "@/components/ui/select/selectTrigger/SelectTrigger.vue";
import SelectContent from "@/components/ui/select/SelectContent.vue";
import SelectItem from "@/components/ui/select/SelectItem.vue";

import Separator from "@/components/ui/separator/Separator.vue";
import Icon from "@/components/ui/icons/Icon.vue";
import Badge from "@/components/ui/Badge.vue";

import { useForm } from "vee-validate";
import { CanceledError } from "axios";
import Input from "@/components/ui/input/Input.vue";
import Button from "@/components/ui/button/Button.vue";

import { cn, toSnakeCase } from "@/utils/utils";
import SkeletonLoading from "@/components/policy/SkeletonLoading.vue";

import { type IApiKey } from "@/types/apiKey";

const { values, resetField, handleSubmit } = useForm({
  initialValues: { allowedSubject: "", bannedSubject: "" },
});
const authStore = useAuthStore();

const chatDetectors = useChatDetails().chatDetectors;
const selectedDetectorsAPI = chatDetectors.selectedDetectorsAPI;

const apiKeys = ref<IApiKey[]>([]);
const selectedApiKey = ref("");

const { selectedModel } = useChatDetails();
const models = ref<string[]>([]);

const isPayloadLoading = ref<boolean>(true);

const startup = async () => {
  if (!authStore.user) return;
  isPayloadLoading.value = true;
  try {
    const [apiKeysResponse, llmResponse] = await Promise.all([
      backend.get(`/api_key/get/${authStore.user.uid}`),
      backend.get("/supported_llms"),
    ]);

    if (!llmResponse.data.llms) {
      throw new Error("Models field are not found");
    }

    apiKeys.value = apiKeysResponse.data.keys;
    if (Object.keys(selectedDetectorsAPI).length === 0) {
      apiKeys.value.forEach((key) => {
        selectedDetectorsAPI[key["key_id"]] = [];
      });
    }
    models.value = llmResponse.data.llms;
    getPolicies();
    const path = `/policy/retention/get/${authStore.user.uid}?api_key_id=${selectedApiKey.value}`;
    const retentionResponse = await backend.get(path);
    selRetPolicy.value = retentionResponse.data.retention;
  } catch (error) {
    toast.error("Failed to fetch data", { autoClose: 500 });
    console.error(error);
  } finally {
    isPayloadLoading.value = false;
  }
};

const getPolicies = async () => {
  if (authStore.user) {
    try {
      const path = `/policy/${authStore.user.uid}/${authStore.user.email}?api_key_id=${selectedApiKey.value}`;
      const payload = await backend.get(path);
      if (!payload.data.policy) {
        toast.error("Policy field are not found", { autoClose: 500 });
      }

      const parsedData = payload.data.policy;

      if (selectedApiKey.value === "") {
        chatDetectors.selectedDetectors = payload.data.policy.detectors;
      } else {
        selectedDetectorsAPI[selectedApiKey.value] =
          payload.data.policy.detectors;
      }

      if (!parsedData.allowed_subjects) {
        toast.error("Allowed subjects field are not found", { autoClose: 500 });
      }
      allowed_subjects.value = parsedData.allowed_subjects;

      if (!parsedData.banned_subjects) {
        toast.error("Banned subjects field are not found", { autoClose: 500 });
      }
      banned_subjects.value = parsedData.banned_subjects;

      if (!parsedData.keywords) {
        toast.error("Keywords field are not found", { autoClose: 500 });
      }
      keywords.Block = parsedData.keywords.block;
      keywords.Passthrough = parsedData.keywords.passthrough;
      keywords.Redact = Object.entries(parsedData.keywords.redact).map(
        ([key, value]) => `${key} -> ${value}`
      );
      keywords.Warn = parsedData.keywords.warn;

      for (const key in pii_state) {
        pii_state[key] = parsedData.pii[key];
      }
      for (const key in pii_state_2) {
        pii_state_2[key] = parsedData.pii[key];
      }

      selRetPolicy.value = parsedData.retention;
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch policies", { autoClose: 500 });
    }
  }
};

startup();

const allowed_subjects = ref<string[]>([]);
const banned_subjects = ref<string[]>([]);

const isAllowedSubjectButtonDisabled = ref<boolean>(true);
const isBannedSubjectButtonDisabled = ref<boolean>(true);

const handleSubmitNewSubject = async (
  values: {
    allowedSubject: string;
    bannedSubject: string;
  },
  subjectType: "bannedSubject" | "allowedSubject",
  subjects: Ref<string[]>
) => {
  const subTag = subjectType === "allowedSubject" ? "allowed" : "banned";

  try {
    for (let i = 0; i < subjects.value.length; i++) {
      if (values[subjectType] === subjects.value[i]) {
        toast.error("Already added subject", { autoClose: 500 });
        return;
      }
    }

    if (values[subjectType].trim() === "") {
      toast.error("Empty input is not allowed", { autoClose: 500 });
      return;
    }

    subjects.value.push(values[subjectType]);

    const path = `/policy/subject/${subTag}/add/${authStore.user.uid}/${values[subjectType]}?api_key_id=${selectedApiKey.value}`;

    await backend.post(path, {}, { signal: getAbortController().signal });
  } catch (error) {
    if (error instanceof CanceledError) return;
    console.log(error);
    toast.error(`Failed to add ${subTag} topic`, { autoClose: 500 });
  } finally {
    resetField(subjectType);
  }
};

const onSubmitNewBannedSubject = handleSubmit(
  async (values) =>
    await handleSubmitNewSubject(values, "bannedSubject", banned_subjects)
);

const onSubmitNewAllowedSubject = handleSubmit(
  async (values) =>
    await handleSubmitNewSubject(values, "allowedSubject", allowed_subjects)
);

const deleteSubject = async (
  subject_value: string,
  subjectType: "allowed" | "banned",
  store: Ref<string[]>
) => {
  try {
    if (!subject_value) {
      toast.error(`Failed to delete ${subjectType} topic`, { autoClose: 500 });
      return;
    }

    const index = store.value.indexOf(subject_value);
    if (index === -1) {
      toast.error(`Failed to delete ${subjectType} topic`, { autoClose: 500 });
      return;
    }

    store.value.splice(index, 1);

    const path = `/policy/subject/${subjectType}/delete/${authStore.user.uid}/${subject_value}?api_key_id=${selectedApiKey.value}`;

    await backend.delete(path, {
      signal: getAbortController().signal,
    });
  } catch (error) {
    if (error instanceof CanceledError) return;
    console.log(error);
    toast.error(`Failed to delete ${subjectType} topic`, { autoClose: 500 });
  }
};

const onDeleteAllowedSubject = async (subject_value: string) =>
  await deleteSubject(subject_value, "allowed", allowed_subjects);

const onDeleteBannedSubject = async (subject_value: string) =>
  await deleteSubject(subject_value, "banned", banned_subjects);

watch(values, (newValue) => {
  if (newValue.allowedSubject !== "")
    isAllowedSubjectButtonDisabled.value = false;
  if (newValue.bannedSubject !== "")
    isBannedSubjectButtonDisabled.value = false;
});

const pii_controls = ["Name", "Email", "Phone", "Address", "Credit Card"];
const pii_controls_2 = ["SSN", "Location", "IP Address", "UUID"];

const pii_state = reactive({
  name: "",
  email: "",
  phone: "",
  address: "",
  credit_card: "",
});

const pii_state_2 = reactive({
  ssn: "",
  location: "",
  ip_address: "",
  uuid: "",
});

watch(
  pii_state,
  async (newState) => {
    const path = `/policy/pii/update/${authStore.user.uid}?api_key_id=${selectedApiKey.value}`;

    try {
      const controller = new AbortController();
      const signal = controller.signal;

      await backend.post(path, { ...newState, ...pii_state_2 }, { signal });
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.log(error);
      toast.error("Failed to update PII policy", { autoClose: 500 });
    }
  },
  { deep: true }
);

watch(
  pii_state_2,
  async (newState) => {
    const path = `/policy/pii/update/${authStore.user.uid}?api_key_id=${selectedApiKey.value}`;

    try {
      const controller = new AbortController();
      const signal = controller.signal;

      await backend.post(path, { ...pii_state, ...newState }, { signal });
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.log(error);
      toast.error("Failed to update PII policy", { autoClose: 500 });
    }
  },
  { deep: true }
);

let abortControllers: AbortController[] = [];
function getAbortController(): AbortController {
  const conroller = new AbortController();
  abortControllers.push(conroller);
  return conroller;
}

watch(selectedApiKey, () => {
  abortControllers.forEach((controller) => controller.abort());
  abortControllers = [];
});

export interface Keywords {
  Warn: string[];
  Passthrough: string[];
  Redact: string[];
  Block: string[];
}

type KeywordFilter = "Warn" | "Passthrough" | "Redact" | "Block";
const selectedKeywordFilter = ref<KeywordFilter>("Block");
const keywordFilters = ["Block", "Warn", "Redact", "Passthrough"];

const keywords = reactive<Keywords>({
  Warn: [],
  Passthrough: [],
  Redact: [],
  Block: [],
});

const isInputButtonDisabled = reactive<{
  inputKeyword: boolean;
  inputKeywordReplace: boolean;
}>({ inputKeyword: true, inputKeywordReplace: true });

let inputKeyword = ref<HTMLInputElement | null>(null);
let inputKeywordReplace = ref<HTMLInputElement | null>(null);

watch(
  () => inputKeyword.value?.value,
  (newVal) => (isInputButtonDisabled.inputKeyword = !newVal?.trim())
);

watch(
  () => inputKeywordReplace.value?.value,
  (newVal) => (isInputButtonDisabled.inputKeywordReplace = !newVal?.trim())
);

const handleInput = (
  field: "inputKeyword" | "inputKeywordReplace",
  inputInTabKey: string
) => {
  if (field === "inputKeyword") {
    if (inputInTabKey === null)
      isInputButtonDisabled.inputKeyword = !inputKeyword.value?.value.trim();
    else
      isInputButtonDisabled.inputKeyword = !inputKeyword.value[0].value.trim();
  } else if (field === "inputKeywordReplace") {
    if (inputInTabKey === null)
      isInputButtonDisabled.inputKeywordReplace =
        !inputKeywordReplace.value?.value.trim();
    else
      isInputButtonDisabled.inputKeywordReplace =
        !inputKeywordReplace.value[0].value.trim();
  }
};

const onSubmitNewKeyword = async (event: SubmitEvent) => {
  event.preventDefault();
  const target = event.target as HTMLFormElement;
  const key = target.id;

  let inputValue: string;
  let replaceInputValue: string;

  if (key !== "") {
    inputValue = inputKeyword.value[0].value;
    if (selectedKeywordFilter.value === "Redact")
      replaceInputValue = inputKeywordReplace.value[0].value;
  } else {
    inputValue = inputKeyword.value.value;
    if (selectedKeywordFilter.value === "Redact")
      replaceInputValue = inputKeywordReplace.value.value;
  }

  try {
    if (inputValue === "")
      return toast.error("Empty input is not allowed", { autoClose: 500 });

    if (selectedKeywordFilter.value !== "Redact") {
      // Check if redact. Accessing inputKeywordReplace when it's not selected will cause a null error.

      if (keywords[selectedKeywordFilter.value].includes(inputValue)) {
        return toast.warning("Keyword already exists", { autoClose: 500 });
      } else {
        keywords[selectedKeywordFilter.value].push(inputValue);
        const path = `/policy/keyword/add/${authStore.user.uid}?api_key_id=${selectedApiKey.value}`;
        await backend.post(path, {
          keyword_type: selectedKeywordFilter.value.toLowerCase(),
          keyword: inputValue,
          substitute_words: "",
        });
      }
    } else {
      const existedRedactKeyword = keywords.Redact.find((keyword) => {
        const [redactebleWord] = keyword.split(" --> ");
        if (redactebleWord === inputValue) return keyword;
      });
      if (
        existedRedactKeyword ||
        keywords[selectedKeywordFilter.value].includes(inputValue)
      ) {
        toast.warning("Keyword already exists", { autoClose: 500 });
        return;
      }

      replaceInputValue = replaceInputValue || "****";
      const path = `/policy/keyword/add/${authStore.user.uid}?api_key_id=${selectedApiKey.value}`;
      await backend.post(path, {
        keyword_type: selectedKeywordFilter.value.toLowerCase(), //because check for lowercase filter
        keyword: inputValue,
        substitute_words: replaceInputValue,
      });
      keywords[selectedKeywordFilter.value].push(
        inputValue + `${" -> " + replaceInputValue}`
      );
    }
  } catch (error) {
    console.log(error);
    toast.error("Failed to add keyword", { autoClose: 500 });
  } finally {
    if (key !== "") {
      inputKeyword.value[0].value = "";
      if (selectedKeywordFilter.value === "Redact")
        inputKeywordReplace.value[0].value = "";
    } else {
      inputKeyword.value.value = "";
      if (selectedKeywordFilter.value === "Redact")
        inputKeywordReplace.value.value = "";
    }
  }
};

async function deleteKeyword(
  keyword: string,
  keywordType: string,
  index: number
) {
  try {
    if (keyword === "" || keywordType === "") return;
    const path = `/policy/keyword/${keywordType}/delete/${authStore.user.uid}/${
      keyword.split(" -> ")[0]
    }?api_key_id=${selectedApiKey.value}`;
    await backend.delete(path, { signal: getAbortController().signal });
    keywords[keywordType.charAt(0).toUpperCase() + keywordType.slice(1)].splice(
      index,
      1
    );
  } catch (error) {
    if (error instanceof CanceledError) return;
    console.log(error);
    toast.error("Failed to delete keyword", { autoClose: 500 });
  }
}

let detectorsController: AbortController;

function getDetectorsController(): AbortController {
  if (detectorsController) detectorsController.abort();
  detectorsController = new AbortController();
  return detectorsController;
}
async function onChangeDetectors() {
  chatDetectors.selectedDetectors = chatDetectors.defaultDetectors.filter((v) =>
    chatDetectors.selectedDetectors.includes(v)
  );

  const selectedDetectors =
    selectedApiKey.value === ""
      ? chatDetectors.selectedDetectors
      : selectedDetectorsAPI[selectedApiKey.value];

  try {
    const signal = getDetectorsController().signal;
    await backend.put(
      `/policy/detectors/update/${authStore.user.uid}?api_key_id=${selectedApiKey.value}`,
      { signal },
      {
        params: {
          detectors: selectedDetectors,
        },
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  } catch (err) {
    console.log(err);
    toast.error("Failed to update detectors", { autoClose: 500 });
  }
}

const policies = [
  {
    name: "90 days",
    val: -1,
  },
  {
    name: "30 days",
    val: 30,
  },
  {
    name: "0 days",
    val: 0,
  },
];

const selRetPolicy = ref(-1);

const saveRet = async (type: number) => {
  const path = `/policy/retention/set/${authStore.user.uid}/${type}?api_key_id=${selectedApiKey.value}`;
  const controller = getAbortController();

  try {
    await backend.post(path, {}, { signal: controller.signal });
  } catch (error) {
    if (error instanceof CanceledError) {
      console.error("An error occurred:", error);
      return;
    }
  }
};

const onClickTab = (key_id = "") => {
  if (selectedApiKey.value === key_id) return;
  selectedApiKey.value = key_id;

  for (let i = 0; i < apiKeys.value.length; i++) {
    if (key_id === apiKeys.value[i].key_id) {
      inputKeyword = ref(document.getElementById(key_id) as HTMLInputElement);
      break; // if match just break
    }
  }

  for (let i = 0; i < apiKeys.value.length; i++) {
    if (key_id === apiKeys.value[i].key_id) {
      inputKeywordReplace = ref(
        document.getElementById(key_id + "Replace") as HTMLInputElement
      );
      break; // if match just break, no need to run entrie loop
    }
  }

  isPayloadLoading.value = true;
  getPolicies();

  isPayloadLoading.value = false;
};

const onClearCache = async () => {
  const path = `/policy/cache/clear/${authStore.user.uid}?api_key_id=${selectedApiKey.value}`;
  try {
    await backend.put(path, {});
  } catch (error) {
    console.error(error);
    toast.error("Failed to clear cache", { autoClose: 500 });
  }
};

const generateTopicInput = ref("");
const generatedTopics = reactive([]);
type GenerateTopicType = "allowed" | "banned";
const generateTopicType = ref<GenerateTopicType>();

async function generateTopics() {
  try {
    generatedTopics.length = 0;
    const response = await backend.post(
      `/policy/subject/generate/${authStore.user.uid}`,
      {
        message: generateTopicInput.value,
        subject_type: generateTopicType.value.toUpperCase(),
      },
      { signal: getAbortController().signal }
    );
    generateTopicInput.value = "";
    generatedTopics.push(...response.data.generated_subjects);
  } catch (error) {
    if (error instanceof CanceledError) return;
    if (error.response.status === 400) {
      toast.warn(error.response.data.detail);
    } else {
      toast.error(`Failed to generate topics`, { autoClose: 500 });
    }
    const MODAL_RENDERED_MS = 150;
    setTimeout(() => {
      document.getElementById("showGeneratedTopicsModal").style.display =
        "none";
      const modalBackdropElements =
        document.getElementsByClassName("modal-backdrop");
      modalBackdropElements.length === 2
        ? modalBackdropElements.item(1).remove()
        : modalBackdropElements.item(0).remove();
    }, MODAL_RENDERED_MS);
    console.log(error);
  }
}

function setTopicType(topicType: GenerateTopicType) {
  generateTopicType.value = topicType;
}

async function addSubject(subject: string, subjectType: GenerateTopicType) {
  try {
    if (subject.trim() === "") return;
    const path = `/policy/subject/${subjectType}/add/${authStore.user.uid}/${subject}?api_key=${selectedApiKey.value}`;
    await backend.post(path, {}, { signal: getAbortController().signal });
    subjectType === "allowed"
      ? allowed_subjects.value.push(subject)
      : banned_subjects.value.push(subject);
  } catch (error) {
    if (error instanceof CanceledError) return;
    console.log(error);
    toast.error(`Failed to add ${subjectType} topic`, { autoClose: 500 });
  }
}

const zenguardWizardInput = ref("");
const zenguardWizardButtonDisabled = ref(true);
const zenguardWizardInputDisabled = ref(false);

watch(zenguardWizardInput, () => {
  zenguardWizardButtonDisabled.value = zenguardWizardInput.value.length < 3;
});

async function handleZenguardWizardInputSubmit(event: SubmitEvent) {
  try {
    event.preventDefault();
    toast.info("Policy configurations will take about 30 seconds", {
      autoClose: 500,
    });
    zenguardWizardButtonDisabled.value = true;
    zenguardWizardInputDisabled.value = true;
    await backend.post(
      `/policy/configure/${authStore.user.uid}?api_key_id=${selectedApiKey.value}`,
      zenguardWizardInput.value
    );
    zenguardWizardInput.value = "";
    await getPolicies();
  } catch (error) {
    toast.error("Failed to configure policy. Please try later", {
      autoClose: 500,
    });
    zenguardWizardButtonDisabled.value = false;
  } finally {
    zenguardWizardInputDisabled.value = false;
  }
}
</script>

<template>
  <!-- Modal Generate Topics With AI -->
  <div
    class="modal fade"
    id="modalGenerateTopics"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="modalGenerateTopicsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalGenerateTopicsLabel">
            Generate Topics
          </h5>
          <Button
            intent="secondary"
            size="small"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></Button>
        </div>
        <div class="modal-body">
          <div>Enter a keyword to generate suggestions.</div>
          <input
            type="text"
            class="form-control mb-2"
            id="generateTopics"
            aria-describedby="generateTopics"
            v-model="generateTopicInput"
            required
          />
        </div>
        <div class="modal-footer">
          <Button
            intent="secondary"
            size="small"
            type="button"
            data-bs-dismiss="modal"
            @click="generateTopicInput = ''"
          >
            Close
          </Button>
          <Button
            intent="primary"
            size="small"
            @click="generateTopics"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#showGeneratedTopicsModal"
            :disabled="!/\w{2,}/.test(generateTopicInput)"
          >
            Generate
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="showGeneratedTopicsModal"
    tabindex="-1"
    aria-labelledby="showGeneratedTopicsModalLabel"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="showGeneratedTopicsModalLabel">
            Generated Topics
          </h1>
          <Button
            intent="secondary"
            size="small"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="generatedTopics.length = 0"
          ></Button>
        </div>
        <div class="modal-body">
          <div
            v-if="generatedTopics.length === 0"
            class="spinner-border"
            role="status"
          ></div>
          <div v-else>
            <div :style="{ margin: '0 5px' }">
              Click on the topics you want to add
            </div>
            <button
              v-for="(topic, index) in generatedTopics"
              :key="index"
              type="button"
              :style="{ margin: '5px 5px' }"
              class="btn btn-outline-success"
              :data-bs-dismiss="generatedTopics.length === 1 ? 'modal' : ''"
              @click="
                generatedTopics.splice(index, 1);
                addSubject(topic, generateTopicType);
              "
            >
              {{ topic }}
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <Button
            intent="secondary"
            size="small"
            type="button"
            data-bs-dismiss="modal"
            @click="generatedTopics.length = 0"
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  </div>
  <SkeletonLoading v-if="isPayloadLoading" />

  <div v-else class="py-7xl px-6xl">
    <h2 class="page-title pb-medium">Detector Controls</h2>
    <TabsRoot class="flex flex-col w-full" default-value="tab1">
      <TabsList class="relative flex border-b">
        <SelectedTabIndicator />

        <Tab value="tab1" @click="onClickTab()"> Playground </Tab>

        <Tab
          v-for="key in apiKeys"
          :key="key.key_id"
          @click="onClickTab(key.key_id)"
          :value="key.key_id"
        >
          API Key: <Counter>{{ key.key_name }}</Counter>
        </Tab>
      </TabsList>

      <TabsContent class="grow bg-white" value="tab1">
        <div class="py-6xl">
          <h3 class="page-subtitle">ZenGuard AI Wizard</h3>
          <p class="paragraph mb-xl">
            Automatically configure the policy for your use cases.
          </p>
          <form
            @submit="handleZenguardWizardInputSubmit"
            class="transition max-w-[770px] items-center flex border-base rounded-small border-[1px] p-medium py-2xs pl-xl pr-2xs hover:bg-subtle focus-within:border-brand focus-within:shadow-input-focus"
          >
            <input
              :disabled="zenguardWizardInputDisabled"
              class="w-[97%] bg-transparent placeholder:text-[14px] placeholder:leading-5 placeholder:text-fg-muted placeholder:font-normal mr-xl"
              type="text"
              placeholder="Enter your app name or use case"
              v-model.trim="zenguardWizardInput"
            />
            <Button
              intent="tertiary"
              size="small"
              type="submit"
              :disabled="zenguardWizardButtonDisabled"
            >
              Preconfigure
            </Button>
          </form>
        </div>

        <Separator intent="base" styles="mb-6xl" />

        <div class="mb-6xl">
          <h3 class="page-subtitle pb-3xs">Available LLM Models</h3>
          <p class="paragraph pb-xl">
            The LLM model to be used in the Playground window.
          </p>

          <SelectRoot v-model="selectedModel">
            <SelectTrigger />

            <SelectPortal>
              <SelectContent :side-offset="5" side="bottom" position="popper">
                <SelectViewport class="p-[5px]">
                  <SelectGroup>
                    <SelectItem :items="models" value="any" />
                  </SelectGroup>
                </SelectViewport>
              </SelectContent>
            </SelectPortal>
          </SelectRoot>
        </div>

        <Separator intent="base" styles="mb-6xl" />
        <div>
          <h3 class="page-subtitle flex justify-between items-center pb-3xs">
            <span class="flex gap-xs items-center">
              Allowed Topics <Counter>{{ allowed_subjects.length }}</Counter>
            </span>

            <div class="toggle-switch" v-if="selectedApiKey == ''">
              <input
                type="checkbox"
                v-model="chatDetectors.selectedDetectors"
                class="checkbox"
                id="allowed_subjects"
                value="allowed_subjects"
                @change="onChangeDetectors()"
              />
              <label class="switch" for="allowed_subjects"></label>
            </div>
          </h3>
          <a
            style="display: inline"
            href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/allowed_topics.ipynb"
            target="_parent"
            ><img
              style="display: inline"
              src="https://colab.research.google.com/assets/colab-badge.svg"
              alt="Open In Colab"
          /></a>
          <p class="paragraph">
            Restrict the prompts to only specific allowed topics.
          </p>

          <form
            @submit.prevent="onSubmitNewAllowedSubject"
            :class="
              cn(
                'pt-xl max-w-[770px]',
                allowed_subjects.length === 0 ? 'pb-6xl' : 'pb-xl'
              )
            "
          >
            <Input
              placeholder="Enter allowed topics"
              intent="default"
              size="medium"
              name="allowedSubject"
              id="allowedSubject"
              type="text"
              :style="'py-2xs pl-xl pr-2xs'"
            >
              <template #iconRight>
                <Button
                  :disabled="
                    isAllowedSubjectButtonDisabled ||
                    values.allowedSubject.length === 0
                  "
                  type="submit"
                  intent="tertiary"
                  size="small"
                  style="margin-right: 5px"
                >
                  <template #iconLeft>
                    <Icon styles="" name="Plus" />
                  </template>
                  Add
                </Button>
                <Button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#modalGenerateTopics"
                  @click="setTopicType('allowed')"
                  intent="tertiary"
                  size="small"
                >
                  Generate
                </Button>
              </template>
            </Input>
          </form>

          <div class="pb-6xl flex gap-small">
            <Badge
              v-for="(subject, index) in allowed_subjects"
              :key="index"
              @clicked="onDeleteAllowedSubject(subject)"
            >
              {{ subject }}
            </Badge>
          </div>
        </div>

        <Separator intent="base" styles="mb-6xl" />
        <!-- Banned subjects start -->
        <div>
          <h3 class="page-subtitle flex justify-between items-center pb-3xs">
            <span class="flex gap-xs items-center">
              Banned Topics <Counter>{{ banned_subjects.length }}</Counter>
            </span>

            <div class="toggle-switch">
              <input
                type="checkbox"
                v-model="chatDetectors.selectedDetectors"
                class="checkbox"
                id="banned_subjects"
                value="banned_subjects"
                @change="onChangeDetectors()"
              />
              <label class="switch" for="banned_subjects"></label>
            </div>
          </h3>
          <a
            style="display: inline"
            href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/banned_topics.ipynb"
            target="_parent"
            ><img
              style="display: inline"
              src="https://colab.research.google.com/assets/colab-badge.svg"
              alt="Open In Colab"
          /></a>
          <p class="paragraph">
            Ban the prompts from discussing any of these topics.<br />
            Note: the allowed topics restriction is checked before banned
            topics.
          </p>

          <form
            @submit.prevent="onSubmitNewBannedSubject"
            :class="
              cn(
                'pt-xl max-w-[770px]',
                banned_subjects.length === 0 ? 'pb-6xl' : 'pb-xl'
              )
            "
          >
            <Input
              placeholder="Enter banned topics"
              intent="default"
              size="medium"
              name="bannedSubject"
              id="bannedSubject"
              type="text"
              :style="'py-2xs pl-xl pr-2xs'"
            >
              <template #iconRight>
                <Button
                  :disabled="
                    isBannedSubjectButtonDisabled ||
                    values.bannedSubject.length === 0
                  "
                  type="submit"
                  intent="tertiary"
                  size="small"
                  style="margin-right: 5px"
                >
                  <template #iconLeft>
                    <Icon name="Plus" />
                  </template>
                  Add
                </Button>
                <Button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#modalGenerateTopics"
                  @click="setTopicType('banned')"
                  intent="tertiary"
                  size="small"
                >
                  Generate
                </Button>
              </template>
            </Input>
          </form>
          <div class="pb-6xl flex gap-small">
            <Badge
              v-for="(subject, index) in banned_subjects"
              :key="index"
              @clicked="onDeleteBannedSubject(subject)"
            >
              {{ subject }}
            </Badge>
          </div>
        </div>
        <!-- Banned subjects end -->

        <Separator intent="base" styles="mb-6xl" />
        <!-- Keywords start -->
        <div>
          <h3 class="page-subtitle flex justify-between items-center pb-3xs">
            <span class="flex gap-xs items-center">
              Keywords
              <Counter>{{
                keywords.Block.length +
                keywords.Passthrough.length +
                keywords.Redact.length +
                keywords.Warn.length
              }}</Counter>
            </span>
            <div class="toggle-switch ml-2" v-if="selectedApiKey == ''">
              <input
                type="checkbox"
                v-model="chatDetectors.selectedDetectors"
                class="checkbox"
                id="keywords"
                value="keywords"
                @change="onChangeDetectors()"
              />
              <label class="switch" for="keywords"></label>
            </div>
          </h3>
          <a
            style="display: inline"
            href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/keywords.ipynb"
            target="_parent"
            ><img
              style="display: inline"
              src="https://colab.research.google.com/assets/colab-badge.svg"
              alt="Open In Colab"
          /></a>
          <p class="paragraph">
            Block, warn or redact specific words and/or phrases.
          </p>

          <form
            class="flex flex-col py-xl"
            @submit.prevent="onSubmitNewKeyword"
          >
            <label
              class="pb-2xs text-[14px] font-medium leading-5 text-fg-base"
              for="keyword"
            >
              Keyword
            </label>

            <div
              class="w-full flex flex-row items-center border-[1px] border-base rounded-small max-w-[770px]"
            >
              <SelectRoot v-model="selectedKeywordFilter">
                <SelectTrigger styles="border-[0px] rounded-none border-r" />

                <SelectPortal>
                  <SelectContent
                    :side-offset="5"
                    side="bottom"
                    position="popper"
                  >
                    <SelectViewport class="p-[5px]">
                      <SelectGroup>
                        <SelectItem :items="keywordFilters" value="any" />
                      </SelectGroup>
                    </SelectViewport>
                  </SelectContent>
                </SelectPortal>
              </SelectRoot>

              <div
                class="py-2xs pl-large pr-2xs flex flex-row flex-1 justify-between items-center text-[14px] font-normal leading-5 text-fg-base placeholder:text-fg-muted"
              >
                <input
                  ref="inputKeyword"
                  @input="handleInput('inputKeyword', null)"
                  class="flex-grow max-w-[253px]"
                  type="text"
                  id="keyword"
                  name="keyword"
                  placeholder="Enter keyword"
                />
                <div
                  class="flex flex-grow flex-row items-center"
                  v-if="selectedKeywordFilter === 'Redact'"
                >
                  <div class="w-[1px] h-[20px] mx-medium bg-[#E2E3E9]" />
                  <input
                    ref="inputKeywordReplace"
                    @input="handleInput('inputKeywordReplace', null)"
                    class="flex-grow max-w-[253px]"
                    type="text"
                    name="redactInput"
                    placeholder="Replace with"
                  />
                </div>

                <Button
                  :disabled="
                    selectedKeywordFilter === 'Redact'
                      ? isInputButtonDisabled.inputKeyword ||
                        isInputButtonDisabled.inputKeywordReplace
                      : isInputButtonDisabled.inputKeyword
                  "
                  type="submit"
                  intent="tertiary"
                  size="small"
                >
                  <template #iconLeft>
                    <Icon name="Plus" />
                  </template>

                  Add
                </Button>
              </div>
            </div>
          </form>

          <KeywordBadges :deleteKeyword="deleteKeyword" :keywords="keywords" />
        </div>

        <Separator intent="muted" styles="my-6xl" />

        <div>
          <h3 class="page-subtitle flex justify-between items-center pb-3xs">
            <span class="flex gap-xs items-center"> Prompt Injection </span>
            <div class="toggle-switch ml-2" v-if="selectedApiKey == ''">
              <input
                type="checkbox"
                v-model="chatDetectors.selectedDetectors"
                class="checkbox"
                id="prompt_injection"
                value="prompt_injection"
                @change="onChangeDetectors()"
              />
              <label class="switch" for="prompt_injection" />
            </div>
          </h3>
          <a
            style="display: inline"
            href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/zenguard_library.ipynb"
            target="_parent"
            ><img
              style="display: inline"
              src="https://colab.research.google.com/assets/colab-badge.svg"
              alt="Open In Colab"
          /></a>
          <p class="paragraph">
            Detect and neutralize prompt injection attempts to safeguard large
            language models (LLMs) from potential attacks.
          </p>
        </div>

        <Separator intent="muted" styles="my-6xl" />

        <div>
          <h3 class="page-subtitle flex justify-between items-center pb-3xs">
            <span class="flex gap-xs items-center"> Secrets </span>
            <div class="toggle-switch ml-2" v-if="selectedApiKey == ''">
              <input
                type="checkbox"
                v-model="chatDetectors.selectedDetectors"
                class="checkbox"
                id="secrets"
                value="secrets"
                @change="onChangeDetectors()"
              />
              <label class="switch" for="secrets" />
            </div>
          </h3>
          <a
            style="display: inline"
            href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/secrets.ipynb"
            target="_parent"
            ><img
              style="display: inline"
              src="https://colab.research.google.com/assets/colab-badge.svg"
              alt="Open In Colab"
          /></a>
          <p class="paragraph">
            Detect and mask sensitive information such as API Keys, Tokens,
            Private Keys, and JWTs to prevent unauthorized access and actions.
          </p>
        </div>

        <Separator intent="muted" styles="my-6xl" />

        <div>
          <h3 class="page-subtitle flex justify-between items-center pb-3xs">
            <span class="flex gap-xs items-center"> Toxicity </span>
            <div class="toggle-switch ml-2" v-if="selectedApiKey == ''">
              <input
                type="checkbox"
                v-model="chatDetectors.selectedDetectors"
                class="checkbox"
                id="toxicity"
                value="toxicity"
                @change="onChangeDetectors()"
              />
              <label class="switch" for="toxicity" />
            </div>
          </h3>
          <a
            style="display: inline"
            href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/toxicity.ipynb"
            target="_parent"
            ><img
              style="display: inline"
              src="https://colab.research.google.com/assets/colab-badge.svg"
              alt="Open In Colab"
          /></a>
          <p class="paragraph">
            Detect and address toxicity in textual content to maintain a safe
            and constructive online environment.
          </p>
        </div>

        <Separator intent="muted" styles="my-6xl" />

        <div>
          <h3 class="page-subtitle flex justify-between items-center pb-3xs">
            <span class="flex gap-xs items-center">
              Personally Identifiable Information(PII)
            </span>
            <div class="toggle-switch ml-2">
              <input
                type="checkbox"
                v-model="chatDetectors.selectedDetectors"
                class="checkbox"
                id="pii"
                value="pii"
                @change="onChangeDetectors()"
              />
              <label class="switch" for="pii" />
            </div>
          </h3>
          <a
            style="display: inline"
            href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/pii.ipynb"
            target="_parent"
            ><img
              style="display: inline"
              src="https://colab.research.google.com/assets/colab-badge.svg"
              alt="Open In Colab"
          /></a>
          <p class="paragraph pb-xl">Block, warn or redact entire PII types.</p>

          <div class="flex items-start">
            <div class="max-w-[496px] pr-6xl border-r-[1px] border-[#E2E3E9]">
              <div
                v-for="(item, index) in pii_controls"
                :key="index"
                class="inline-flex justify-between items-center w-full min-w-[296px] py-medium border-b-[1px] last:border-b-[0px] border-muted"
              >
                <h5 class="text-[14px] font-normal leading-5 text-fg-base">
                  {{ item }}
                </h5>

                <div
                  class="rounded-xs border-[1px] bg-subtle inline-flex items-center relative ]"
                  role="group"
                  aria-label="PII Name"
                  :id="item"
                >
                  <input
                    type="radio"
                    class="pointer-events-none absolute invisible"
                    :name="'btnradio' + item"
                    :id="'btnradio' + 4 * index + 0"
                    v-model="pii_state[toSnakeCase(item)]"
                    value="block"
                    autocomplete="off"
                  />

                  <label
                    :class="
                      cn(
                        'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                        pii_state[toSnakeCase(item)] === 'block'
                          ? 'bg-[#ECEBFF] text-fg-base'
                          : ''
                      )
                    "
                    :for="'btnradio' + 4 * index + 0"
                    >Block
                  </label>

                  <input
                    type="radio"
                    class="pointer-events-none absolute invisible"
                    :name="'btnradio' + item"
                    :id="'btnradio' + 4 * index + 1"
                    v-model="pii_state[toSnakeCase(item)]"
                    value="warn"
                    autocomplete="off"
                  />
                  <label
                    :class="
                      cn(
                        'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                        pii_state[toSnakeCase(item)] === 'warn'
                          ? 'bg-[#ECEBFF] text-fg-base'
                          : ''
                      )
                    "
                    :for="'btnradio' + 4 * index + 1"
                    >Warn</label
                  >

                  <input
                    type="radio"
                    class="pointer-events-none absolute invisible"
                    :name="'btnradio' + item"
                    :id="'btnradio' + 4 * index + 2"
                    v-model="pii_state[toSnakeCase(item)]"
                    value="redact"
                    autocomplete="off"
                  />
                  <label
                    :class="
                      cn(
                        'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                        pii_state[toSnakeCase(item)] === 'redact'
                          ? 'bg-[#ECEBFF] text-fg-base'
                          : ''
                      )
                    "
                    :for="'btnradio' + 4 * index + 2"
                    >Redact</label
                  >
                  <input
                    type="radio"
                    class="pointer-events-none absolute invisible"
                    :name="'btnradio' + item"
                    :id="'btnradio' + 4 * index + 3"
                    v-model="pii_state[toSnakeCase(item)]"
                    value="passthrough"
                    autocomplete="off"
                  />
                  <label
                    :class="
                      cn(
                        'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                        pii_state[toSnakeCase(item)] === 'passthrough'
                          ? 'bg-[#ECEBFF] text-fg-base'
                          : ''
                      )
                    "
                    :for="'btnradio' + 4 * index + 3"
                  >
                    Passthrough
                  </label>
                </div>
              </div>
            </div>

            <div>
              <div class="max-w-[496px] pl-6xl">
                <div
                  v-for="(item, index) in pii_controls_2"
                  :key="index"
                  class="inline-flex justify-between items-center w-full min-w-[296px] py-medium border-b-[1px] last:border-b-[0px] border-muted"
                >
                  <h5 class="text-[14px] font-normal leading-5 text-fg-base">
                    {{ item }}
                  </h5>

                  <div
                    class="rounded-xs border-[1px] bg-subtle inline-flex items-center relative"
                    role="group"
                    aria-label="PII Name"
                    :id="item"
                  >
                    <input
                      type="radio"
                      class="pointer-events-none absolute invisible"
                      :name="'btnradio' + item"
                      :id="'btnradio' + 4 * index + 4"
                      v-model="pii_state_2[toSnakeCase(item)]"
                      value="block"
                      autocomplete="off"
                    />

                    <label
                      :class="
                        cn(
                          'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                          pii_state_2[toSnakeCase(item)] === 'block'
                            ? 'bg-[#ECEBFF] text-fg-base'
                            : ''
                        )
                      "
                      :for="'btnradio' + 4 * index + 4"
                      >Block
                    </label>

                    <input
                      type="radio"
                      class="pointer-events-none absolute invisible"
                      :name="'btnradio' + item"
                      :id="'btnradio' + 4 * index + 5"
                      v-model="pii_state_2[toSnakeCase(item)]"
                      value="warn"
                      autocomplete="off"
                    />
                    <label
                      :class="
                        cn(
                          'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                          pii_state_2[toSnakeCase(item)] === 'warn'
                            ? 'bg-[#ECEBFF] text-fg-base'
                            : ''
                        )
                      "
                      :for="'btnradio' + 4 * index + 5"
                      >Warn</label
                    >

                    <input
                      type="radio"
                      class="pointer-events-none absolute invisible"
                      :name="'btnradio' + item"
                      :id="'btnradio' + 4 * index + 6"
                      v-model="pii_state_2[toSnakeCase(item)]"
                      value="redact"
                      autocomplete="off"
                    />
                    <label
                      :class="
                        cn(
                          'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                          pii_state_2[toSnakeCase(item)] === 'redact'
                            ? 'bg-[#ECEBFF] text-fg-base'
                            : ''
                        )
                      "
                      :for="'btnradio' + 4 * index + 6"
                      >Redact</label
                    >
                    <input
                      type="radio"
                      class="pointer-events-none absolute invisible"
                      :name="'btnradio' + item"
                      :id="'btnradio' + 4 * index + 7"
                      v-model="pii_state_2[toSnakeCase(item)]"
                      value="passthrough"
                      autocomplete="off"
                    />
                    <label
                      :class="
                        cn(
                          'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                          pii_state_2[toSnakeCase(item)] === 'passthrough'
                            ? 'bg-[#ECEBFF] text-fg-base'
                            : ''
                        )
                      "
                      :for="'btnradio' + 4 * index + 7"
                    >
                      Passthrough
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Separator intent="muted" styles="my-6xl" />

        <div>
          <h3 class="page-subtitle flex justify-between items-center pb-3xs">
            <span class="flex gap-xs items-center"> Logs </span>
          </h3>
          <p class="paragraph">
            Specify the retention horizon for policy logs.
          </p>

          <div
            class="rounded-xs border-[1px] bg-subtle inline-flex items-center relative my-xl"
          >
            <div
              role="group"
              aria-label="Model Name"
              v-for="(item, index) in policies"
              :key="index"
            >
              <input
                type="radio"
                class="pointer-events-none absolute invisible"
                :name="'btnmodel' + item"
                :id="'btnmodel' + 7 * index + item.val"
                :value="item.val"
                v-model="selRetPolicy"
                autocomplete="off"
                readonly
              />
              <label
                @click="saveRet(item.val)"
                :class="
                  cn(
                    'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                    selRetPolicy === item.val ? 'bg-[#ECEBFF] text-fg-base' : ''
                  )
                "
                :for="'btnmodel' + 7 * index + item.val"
                >{{ item.name }}</label
              >
            </div>
          </div>

          <Button @click="onClearCache()" size="small" intent="secondary">
            <template #iconLeft>
              <Icon styles="stroke-[#959AB1]" name="TrashBin" />
            </template>
            Clear Cache
          </Button>
        </div>
      </TabsContent>

      <TabsContent
        v-for="key in apiKeys"
        :key="key.key_id"
        class="grow bg-white"
        :value="key.key_id"
      >
        <div class="py-6xl">
          <div>
            <h3 class="page-subtitle">ZenGuard AI Wizard</h3>
            <p class="paragraph mb-xl">
              Automatically configure the policy for your use cases.
            </p>
            <form
              @submit="handleZenguardWizardInputSubmit"
              class="transition max-w-[770px] mb-6xl items-center flex border-base rounded-small border-[1px] p-medium py-2xs pl-xl pr-2xs hover:bg-subtle focus-within:border-brand focus-within:shadow-input-focus"
            >
              <input
                :disabled="zenguardWizardInputDisabled"
                class="w-[97%] bg-transparent placeholder:text-[14px] placeholder:leading-5 placeholder:text-fg-muted placeholder:font-normal mr-xl"
                type="text"
                placeholder="Enter your app name or use case"
                v-model.trim="zenguardWizardInput"
              />
              <Button
                intent="tertiary"
                size="small"
                type="submit"
                :disabled="zenguardWizardButtonDisabled"
              >
                Preconfigure
              </Button>
            </form>
          </div>

          <Separator intent="muted" />
          <!-- Allowed Subjects starts -->

          <div class="py-6xl">
            <h3 class="page-subtitle flex justify-between items-center pb-3xs">
              <span class="flex gap-xs items-center">
                Allowed Topics <Counter>{{ allowed_subjects.length }}</Counter>
              </span>

              <div class="toggle-switch">
                <input
                  type="checkbox"
                  v-model="selectedDetectorsAPI[selectedApiKey]"
                  class="checkbox"
                  id="allowed_subjects"
                  value="allowed_subjects"
                  @change="onChangeDetectors()"
                />
                <label class="switch" for="allowed_subjects"></label>
              </div>
            </h3>
            <a
              style="display: inline"
              href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/allowed_topics.ipynb"
              target="_parent"
              ><img
                style="display: inline"
                src="https://colab.research.google.com/assets/colab-badge.svg"
                alt="Open In Colab"
            /></a>
            <p class="paragraph">
              Restrict the prompts to only specific allowed topics.
            </p>

            <form
              @submit.prevent="onSubmitNewAllowedSubject"
              :class="
                cn(
                  'pt-xl max-w-[770px]',
                  allowed_subjects.length === 0 ? 'pb-6xl' : 'pb-xl'
                )
              "
            >
              <Input
                placeholder="Enter allowed topics"
                intent="default"
                size="medium"
                name="allowedSubject"
                id="allowedSubject"
                type="text"
                :style="'py-2xs pl-xl pr-2xs'"
              >
                <template #iconRight>
                  <Button
                    :disabled="
                      isAllowedSubjectButtonDisabled ||
                      values.allowedSubject.length === 0
                    "
                    type="submit"
                    intent="tertiary"
                    size="small"
                  >
                    <template #iconLeft>
                      <Icon styles="" name="Plus" />
                    </template>
                    Add
                  </Button>
                </template>
              </Input>
            </form>

            <div class="flex gap-small">
              <Badge
                v-for="(subject, index) in allowed_subjects"
                :key="index"
                @clicked="onDeleteAllowedSubject(subject)"
              >
                {{ subject }}
              </Badge>
            </div>
          </div>

          <!-- Allowed Subjects end -->

          <Separator intent="muted" />
          <!-- Banned Subjects start -->

          <div class="py-6xl">
            <h3 class="page-subtitle flex justify-between items-center pb-3xs">
              <span class="flex gap-xs items-center">
                Banned Topics <Counter>{{ banned_subjects.length }}</Counter>
              </span>

              <div class="toggle-switch">
                <input
                  type="checkbox"
                  v-model="selectedDetectorsAPI[selectedApiKey]"
                  class="checkbox"
                  id="banned_subjects"
                  value="banned_subjects"
                  @change="onChangeDetectors()"
                />
                <label class="switch" for="banned_subjects" />
              </div>
            </h3>
            <a
              style="display: inline"
              href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/banned_topics.ipynb"
              target="_parent"
              ><img
                style="display: inline"
                src="https://colab.research.google.com/assets/colab-badge.svg"
                alt="Open In Colab"
            /></a>
            <p class="paragraph">
              Ban the prompts from discussing any of these topics.<br />
              Note: the allowed topics restriction is checked before banned
              topics.
            </p>

            <form
              @submit.prevent="onSubmitNewBannedSubject"
              :class="
                cn(
                  'pt-xl max-w-[770px]',
                  banned_subjects.length === 0 ? 'pb-6xl' : 'pb-xl'
                )
              "
            >
              <Input
                placeholder="Enter banned topics"
                intent="default"
                size="medium"
                name="bannedSubject"
                id="bannedSubject"
                type="text"
                :style="'py-2xs pl-xl pr-2xs'"
              >
                <template #iconRight>
                  <Button
                    :disabled="
                      isBannedSubjectButtonDisabled ||
                      values.bannedSubject.length === 0
                    "
                    type="submit"
                    intent="tertiary"
                    size="small"
                  >
                    <template #iconLeft>
                      <Icon name="Plus" />
                    </template>
                    Add
                  </Button>
                </template>
              </Input>
            </form>

            <div class="flex gap-small">
              <Badge
                v-for="(subject, index) in banned_subjects"
                :key="index"
                @clicked="onDeleteBannedSubject(subject)"
              >
                {{ subject }}
              </Badge>
            </div>
          </div>

          <!-- Banned Subjects end -->

          <Separator intent="muted" />
          <!-- Keywords start -->
          <div class="py-6xl">
            <h3 class="page-subtitle flex justify-between items-center pb-3xs">
              <span class="flex gap-xs items-center">
                Keywords
                <Counter>{{
                  keywords.Block.length +
                  keywords.Passthrough.length +
                  keywords.Redact.length +
                  keywords.Warn.length
                }}</Counter>
              </span>
              <div class="toggle-switch ml-2">
                <input
                  type="checkbox"
                  v-model="selectedDetectorsAPI[selectedApiKey]"
                  class="checkbox"
                  id="keywords"
                  value="keywords"
                  @change="onChangeDetectors()"
                />
                <label class="switch" for="keywords"></label>
              </div>
            </h3>
            <a
              style="display: inline"
              href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/keywords.ipynb"
              target="_parent"
              ><img
                style="display: inline"
                src="https://colab.research.google.com/assets/colab-badge.svg"
                alt="Open In Colab"
            /></a>
            <p class="paragraph">
              Block, warn or redact specific words and/or phrases.
            </p>

            <form
              :id="key.key_id"
              class="flex flex-col py-xl"
              @submit.prevent="onSubmitNewKeyword"
            >
              <label
                class="pb-2xs text-[14px] font-medium leading-5 text-fg-base"
                for="keyword"
              >
                Keyword
              </label>

              <div
                class="w-full flex flex-row items-center border-[1px] border-base rounded-small max-w-[770px]"
              >
                <SelectRoot v-model="selectedKeywordFilter">
                  <SelectTrigger styles="border-[0px] rounded-none border-r" />

                  <SelectPortal>
                    <SelectContent
                      :side-offset="5"
                      side="bottom"
                      position="popper"
                    >
                      <SelectViewport class="p-[5px]">
                        <SelectGroup>
                          <SelectItem :items="keywordFilters" value="any" />
                        </SelectGroup>
                      </SelectViewport>
                    </SelectContent>
                  </SelectPortal>
                </SelectRoot>

                <div
                  class="py-2xs pl-large pr-2xs flex flex-row flex-1 justify-between items-center text-[14px] font-normal leading-5 text-fg-base placeholder:text-fg-muted"
                >
                  <input
                    ref="inputKeyword"
                    @input="handleInput('inputKeyword', key.key_id)"
                    class="flex-grow max-w-[253px]"
                    type="text"
                    :id="key.key_id"
                    name="keyword"
                    placeholder="Enter keyword"
                  />
                  <div
                    class="flex flex-grow flex-row items-center"
                    v-if="selectedKeywordFilter === 'Redact'"
                  >
                    <div class="w-[1px] h-[20px] mx-medium bg-[#E2E3E9]" />
                    <input
                      ref="inputKeywordReplace"
                      @input="handleInput('inputKeywordReplace', key.key_id)"
                      :id="key.key_id + 'Replace'"
                      class="flex-grow max-w-[253px]"
                      type="text"
                      name="redactInput"
                      placeholder="Replace with"
                    />
                  </div>

                  <Button
                    :disabled="
                      selectedKeywordFilter === 'Redact'
                        ? isInputButtonDisabled.inputKeyword ||
                          isInputButtonDisabled.inputKeywordReplace
                        : isInputButtonDisabled.inputKeyword
                    "
                    type="submit"
                    intent="tertiary"
                    size="small"
                  >
                    <template #iconLeft>
                      <Icon name="Plus" />
                    </template>

                    Add
                  </Button>
                </div>
              </div>
            </form>

            <KeywordBadges
              :deleteKeyword="deleteKeyword"
              :keywords="keywords"
            />
          </div>

          <!-- Keywords end -->

          <Separator intent="muted" />

          <div class="my-6xl">
            <h3 class="page-subtitle flex justify-between items-center pb-3xs">
              <span class="flex gap-xs items-center"> Prompt Injection </span>
              <div class="toggle-switch ml-2">
                <input
                  type="checkbox"
                  v-model="selectedDetectorsAPI[selectedApiKey]"
                  class="checkbox"
                  id="prompt_injection"
                  value="prompt_injection"
                  @change="onChangeDetectors()"
                />
                <label class="switch" for="prompt_injection" />
              </div>
            </h3>
            <a
              style="display: inline"
              href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/zenguard_library.ipynb"
              target="_parent"
              ><img
                style="display: inline"
                src="https://colab.research.google.com/assets/colab-badge.svg"
                alt="Open In Colab"
            /></a>
            <p class="paragraph">
              Detect and neutralize prompt injection attempts to safeguard large
              language models (LLMs) from potential attacks.
            </p>
          </div>

          <Separator intent="muted" />

          <div class="my-6xl">
            <h3 class="page-subtitle flex justify-between items-center pb-3xs">
              <span class="flex gap-xs items-center"> Toxicity </span>
              <div class="toggle-switch ml-2">
                <input
                  type="checkbox"
                  v-model="selectedDetectorsAPI[selectedApiKey]"
                  class="checkbox"
                  id="toxicity"
                  value="toxicity"
                  @change="onChangeDetectors()"
                />
                <label class="switch" for="toxicity" />
              </div>
            </h3>
            <a
              style="display: inline"
              href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/toxicity.ipynb"
              target="_parent"
              ><img
                style="display: inline"
                src="https://colab.research.google.com/assets/colab-badge.svg"
                alt="Open In Colab"
            /></a>
            <p class="paragraph">
              Detect and address toxicity in textual content to maintain a safe
              and constructive online environment.
            </p>
          </div>

          <Separator intent="muted" />

          <div class="my-6xl">
            <h3 class="page-subtitle flex justify-between items-center pb-3xs">
              <span class="flex gap-xs items-center"> Secrets </span>
              <div class="toggle-switch ml-2">
                <input
                  type="checkbox"
                  v-model="selectedDetectorsAPI[selectedApiKey]"
                  class="checkbox"
                  id="secrets"
                  value="secrets"
                  @change="onChangeDetectors()"
                />
                <label class="switch" for="secrets" />
              </div>
            </h3>
            <a
              style="display: inline"
              href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/secrets.ipynb"
              target="_parent"
              ><img
                style="display: inline"
                src="https://colab.research.google.com/assets/colab-badge.svg"
                alt="Open In Colab"
            /></a>
            <p class="paragraph">
              Detect and mask sensitive information such as API Keys, Tokens,
              Private Keys, and JWTs to prevent unauthorized access and actions.
            </p>
          </div>

          <Separator intent="muted" />

          <div class="my-6xl">
            <h3 class="page-subtitle flex justify-between items-center pb-3xs">
              <span class="flex gap-xs items-center">
                Personally Identifiable Information(PII)
              </span>
              <div class="toggle-switch ml-2">
                <input
                  type="checkbox"
                  v-model="selectedDetectorsAPI[selectedApiKey]"
                  class="checkbox"
                  id="pii"
                  value="pii"
                  @change="onChangeDetectors()"
                />
                <label class="switch" for="pii" />
              </div>
            </h3>
            <a
              style="display: inline"
              href="https://colab.research.google.com/github/ZenGuard-AI/fast-llm-security-guardrails/blob/main/docs/colabs/pii.ipynb"
              target="_parent"
              ><img
                style="display: inline"
                src="https://colab.research.google.com/assets/colab-badge.svg"
                alt="Open In Colab"
            /></a>
            <p class="paragraph pb-xl">
              Block, warn or redact entire PII types.
            </p>

            <div class="flex items-start">
              <div class="max-w-[496px] pr-6xl border-r-[1px] border-[#E2E3E9]">
                <div
                  v-for="(item, index) in pii_controls"
                  :key="index"
                  class="inline-flex justify-between items-center w-full min-w-[296px] py-medium border-b-[1px] border-muted last:border-b-[0px]"
                >
                  <h5 class="text-[14px] font-normal leading-5 text-fg-base">
                    {{ item }}
                  </h5>

                  <div
                    class="rounded-xs border-[1px] bg-subtle inline-flex items-center relative ]"
                    role="group"
                    aria-label="PII Name"
                    :id="item"
                  >
                    <input
                      type="radio"
                      class="pointer-events-none absolute invisible"
                      :name="'btnradio' + item"
                      :id="'btnradio' + 4 * index + 0"
                      v-model="pii_state[toSnakeCase(item)]"
                      value="block"
                      autocomplete="off"
                    />

                    <label
                      :class="
                        cn(
                          'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                          pii_state[toSnakeCase(item)] === 'block'
                            ? 'bg-[#ECEBFF] text-fg-base'
                            : ''
                        )
                      "
                      :for="'btnradio' + 4 * index + 0"
                      >Block
                    </label>

                    <input
                      type="radio"
                      class="pointer-events-none absolute invisible"
                      :name="'btnradio' + item"
                      :id="'btnradio' + 4 * index + 1"
                      v-model="pii_state[toSnakeCase(item)]"
                      value="warn"
                      autocomplete="off"
                    />
                    <label
                      :class="
                        cn(
                          'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                          pii_state[toSnakeCase(item)] === 'warn'
                            ? 'bg-[#ECEBFF] text-fg-base'
                            : ''
                        )
                      "
                      :for="'btnradio' + 4 * index + 1"
                      >Warn</label
                    >

                    <input
                      type="radio"
                      class="pointer-events-none absolute invisible"
                      :name="'btnradio' + item"
                      :id="'btnradio' + 4 * index + 2"
                      v-model="pii_state[toSnakeCase(item)]"
                      value="redact"
                      autocomplete="off"
                    />
                    <label
                      :class="
                        cn(
                          'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                          pii_state[toSnakeCase(item)] === 'redact'
                            ? 'bg-[#ECEBFF] text-fg-base'
                            : ''
                        )
                      "
                      :for="'btnradio' + 4 * index + 2"
                      >Redact</label
                    >
                    <input
                      type="radio"
                      class="pointer-events-none absolute invisible"
                      :name="'btnradio' + item"
                      :id="'btnradio' + 4 * index + 3"
                      v-model="pii_state[toSnakeCase(item)]"
                      value="passthrough"
                      autocomplete="off"
                    />
                    <label
                      :class="
                        cn(
                          'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                          pii_state[toSnakeCase(item)] === 'passthrough'
                            ? 'bg-[#ECEBFF] text-fg-base'
                            : ''
                        )
                      "
                      :for="'btnradio' + 4 * index + 3"
                    >
                      Passthrough
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <div class="max-w-[496px] pl-6xl">
                  <div
                    v-for="(item, index) in pii_controls_2"
                    :key="index"
                    class="inline-flex justify-between items-center w-full min-w-[296px] py-medium border-b-[1px] last:border-b-[0px] border-muted"
                  >
                    <h5 class="text-[14px] font-normal leading-5 text-fg-base">
                      {{ item }}
                    </h5>

                    <div
                      class="rounded-xs border-[1px] bg-subtle inline-flex items-center relative"
                      role="group"
                      aria-label="PII Name"
                      :id="item"
                    >
                      <input
                        type="radio"
                        class="pointer-events-none absolute invisible"
                        :name="'btnradio' + item"
                        :id="'btnradio' + 4 * index + 4"
                        v-model="pii_state_2[toSnakeCase(item)]"
                        value="block"
                        autocomplete="off"
                      />

                      <label
                        :class="
                          cn(
                            'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                            pii_state_2[toSnakeCase(item)] === 'block'
                              ? 'bg-[#ECEBFF] text-fg-base'
                              : ''
                          )
                        "
                        :for="'btnradio' + 4 * index + 4"
                        >Block
                      </label>

                      <input
                        type="radio"
                        class="pointer-events-none absolute invisible"
                        :name="'btnradio' + item"
                        :id="'btnradio' + 4 * index + 5"
                        v-model="pii_state_2[toSnakeCase(item)]"
                        value="warn"
                        autocomplete="off"
                      />
                      <label
                        :class="
                          cn(
                            'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                            pii_state_2[toSnakeCase(item)] === 'warn'
                              ? 'bg-[#ECEBFF] text-fg-base'
                              : ''
                          )
                        "
                        :for="'btnradio' + 4 * index + 5"
                        >Warn</label
                      >

                      <input
                        type="radio"
                        class="pointer-events-none absolute invisible"
                        :name="'btnradio' + item"
                        :id="'btnradio' + 4 * index + 6"
                        v-model="pii_state_2[toSnakeCase(item)]"
                        value="redact"
                        autocomplete="off"
                      />
                      <label
                        :class="
                          cn(
                            'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                            pii_state_2[toSnakeCase(item)] === 'redact'
                              ? 'bg-[#ECEBFF] text-fg-base'
                              : ''
                          )
                        "
                        :for="'btnradio' + 4 * index + 6"
                        >Redact</label
                      >
                      <input
                        type="radio"
                        class="pointer-events-none absolute invisible"
                        :name="'btnradio' + item"
                        :id="'btnradio' + 4 * index + 7"
                        v-model="pii_state_2[toSnakeCase(item)]"
                        value="passthrough"
                        autocomplete="off"
                      />
                      <label
                        :class="
                          cn(
                            'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                            pii_state_2[toSnakeCase(item)] === 'passthrough'
                              ? 'bg-[#ECEBFF] text-fg-base'
                              : ''
                          )
                        "
                        :for="'btnradio' + 4 * index + 7"
                      >
                        Passthrough
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Separator intent="muted" />

          <div class="pt-6xl">
            <h3 class="page-subtitle flex justify-between items-center pb-3xs">
              <span class="flex gap-xs items-center"> Logs </span>
            </h3>
            <p class="paragraph">
              Specify the retention horizon for policy logs.
            </p>

            <div
              class="rounded-xs border-[1px] bg-subtle inline-flex items-center relative my-xl"
            >
              <div
                role="group"
                aria-label="Model Name"
                v-for="(item, index) in policies"
                :key="index"
              >
                <input
                  type="radio"
                  class="pointer-events-none absolute invisible"
                  :name="'btnmodel' + item"
                  :id="'btnmodel' + 7 * index + item.val"
                  :value="item.val"
                  v-model="selRetPolicy"
                  autocomplete="off"
                  readonly
                />
                <label
                  @click="saveRet(item.val)"
                  :class="
                    cn(
                      'py-xs px-3xl leading-5 text-[12px] rounded-xs font-medium text-fg-subtle transition cursor-pointer',
                      selRetPolicy === item.val
                        ? 'bg-[#ECEBFF] text-fg-base'
                        : ''
                    )
                  "
                  :for="'btnmodel' + 7 * index + item.val"
                  >{{ item.name }}</label
                >
              </div>
            </div>

            <Button @click="onClearCache()" size="small" intent="secondary">
              <template #iconLeft>
                <Icon styles="stroke-[#959AB1]" name="TrashBin" />
              </template>
              Clear Cache
            </Button>
          </div>
        </div>
      </TabsContent>
    </TabsRoot>
  </div>
</template>
