<template>
  <div class="container-fluid">
    <div class="left-section">
      <div class="favicon" />
      <div class="starting">ZenGuard AI</div>
      <p>Peace of mind when adopting GenAI</p>
    </div>
    <div class="right-section">
      <div class="starting">Get Started</div>

      <div class="buttons">
        <button @click="$router.push('/login')">Sign In</button>
        <button @click="$router.push('/signup')">Sign Up</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.container-fluid {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-content: flex-start;
  padding: 0;
}

.left-section {
  flex-grow: 2;
  width: 60vw;
  height: 100vh;
  background-color: #ffc580;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.right-section {
  flex-grow: 1;
  width: 40vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.starting {
  font-size: 2.5rem;
  font-weight: 500;
}
@media only screen and (max-width: 615px) {
  .starting {
    font-size: 2rem;
  }
}

button {
  margin: 10px;
  width: 200px;
  height: 50px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 1100px) {
  .buttons {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .left-section {
    width: 55vw;
  }
  .right-section {
    width: 45vw;
  }
  button {
    margin: 5px;
    width: 175px;
    height: 40px;
  }
}

@media only screen and (max-width: 535px) {
  .starting {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 520px) {
  button {
    width: 150px;
    height: 35px;
  }
  p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 465px) {
  p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  .starting {
    font-size: 1rem;
  }
  button {
    width: 125px;
    height: 35px;
  }
  p {
    font-size: 10px;
  }
}
@media only screen and (max-width: 325px) {
  button {
    width: 100px;
    height: 35px;
  }
  p {
    font-size: 8px;
  }
}
.favicon {
  background-image: url("@/assets/favicon.png");
  background-size: cover;
  width: 32px;
  height: 32px;
}
</style>
