<script setup lang="ts">
import { ref } from "vue";
import { useAuthStore } from "@/stores/AuthStore";

import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { z } from "zod";
import { emailSchema } from "@/validations/auth/utils";

import AuthLayout from "@/components/auth/AuthLayout.vue";
import Input from "@/components/ui/input/Input.vue";
import Button from "@/components/ui/button/Button.vue";
import Link from "@/components/ui/links/Link.vue";

const authStore = useAuthStore();

const { values, setErrors, handleSubmit } = useForm({
  validationSchema: toTypedSchema(
    z.object({
      email: emailSchema,
    })
  ),
  initialValues: {
    email: "",
  },
});
const isFormSubmitted = ref<boolean>(false);

const submitedBefore = ref<boolean>(false);
const onResendEmail = async () => {
  isEmailResendVisible.value = true;
  startTimer();
  await authStore.resetPassword(values.email);
};

const isEmailResendVisible = ref(false);
const timerEnabled = ref(false);
const timerDisplay = ref("");
const timerSeconds = 60;

const startTimer = () => {
  timerEnabled.value = true;
  let timer = timerSeconds;
  const interval = setInterval(() => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    timerDisplay.value = `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    if (--timer < 0) {
      clearInterval(interval);
      timerEnabled.value = false;
      timerDisplay.value = "";
      submitedBefore.value = true;
      isEmailResendVisible.value = false;
    }
  }, 1000);
};

const onSubmit = handleSubmit(async (values) => {
  if (!values.email) {
    return setErrors({ email: "Please enter your email" });
  }
  isEmailResendVisible.value = true;
  startTimer();
  try {
    await authStore.resetPassword(values.email);
  } catch (error) {
    return setErrors({ email: "Please enter your email" });
  }
});
</script>

<template>
  <AuthLayout title="Forgot Password">
    <template #header />

    <template #body>
      <form @submit.prevent="onSubmit" class="flex flex-col gap-xl">
        <Input
          labelText="Email"
          id="email"
          type="email"
          placeholder="Enter your email address"
          name="email"
          intent="default"
          size="large"
        >
        </Input>

        <div class="w-full flex flex-col gap-2xl">
          <Button
            v-if="!submitedBefore"
            :disabled="isEmailResendVisible"
            intent="primary"
            size="large"
            type="submit"
          >
            <template v-if="isFormSubmitted">
              <Icon name="LoadingSpinner" />
            </template>
            <template v-else-if="isEmailResendVisible">
              Resend Email: {{ timerDisplay }}
            </template>
            <template v-else> Request </template>
          </Button>

          <Button
            v-else
            @click="onResendEmail"
            :disabled="isEmailResendVisible"
            intent="primary"
            size="large"
            type="button"
          >
            <template v-if="isEmailResendVisible">
              Resend Email: {{ timerDisplay }}
            </template>
            <template v-else> Resend Email </template>
          </Button>
          <p class="text-center text-[14px] leading-5 text-fg-subtle">
            Back to
            <Link to="/signup"> Sign In</Link>
          </p>
        </div>
      </form>
    </template>
  </AuthLayout>
</template>
