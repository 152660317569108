<!-- eslint-disable vue/multi-word-component-names -->

<script setup lang="ts">
import SidebarLink from "@/components/ui/links/SidebarLink.vue";
import SidebarFooter from "./SidebarFooter.vue";

import Icon from "@/components/ui/icons/Icon.vue";
import { type IconName } from "@/components/ui/icons/index";
import { backend } from "@/main";
import { useAuthStore } from "@/stores/AuthStore";
import { ref } from "vue";
import { toast } from "vue3-toastify";

export interface ILinks {
  title: string;
  iconName: IconName;
  to: string;
  linkId: string;
}
const links: ILinks[] = [
  { title: "Home", iconName: "Home", to: "/", linkId: "home-id" },
  { title: "Playground", iconName: "Chat", to: "/chat", linkId: "chat-id" },
  { title: "Policy", iconName: "Policy", to: "/policy", linkId: "policy-id" },
  {
    title: "Attacks",
    iconName: "Shield",
    to: "/prompts",
    linkId: "terminal-id",
  },
  { title: "Logs", iconName: "OpenEye", to: "/logs", linkId: "logs-id" },
  { title: "Reports", iconName: "Graph", to: "/reports", linkId: "reports-id" },
  {
    title: "Settings",
    iconName: "Gear",
    to: "/settings",
    linkId: "settings-id",
  },
];

const authStore = useAuthStore();

const feedbackMessage = ref<string>("");

function sendFeedback() {
  backend
    .post("feedback", {
      uid: authStore.user.uid,
      text: feedbackMessage.value,
      sender: authStore.user.email,
    })
    .then(() => {
      toast.success("Thanks for your feedback", { autoClose: 1000 });
      feedbackMessage.value = "";
    })
    .catch(() => {
      toast.error("Error sending feedback, please try again later", {
        autoClose: 1000,
      });
    });
}
</script>

<template>
  <div
    style="backdrop-filter: blur(3px); position: absolute; z-index: 300000"
    class="modal fade"
    id="feedback-model"
    tabindex="-1"
    aria-labelledby="feedbackModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="feedbackModelLabel">Feedback</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <textarea
            class="form-control mb-2"
            id="addWord1"
            aria-describedby="addWord1"
            v-model="feedbackMessage"
            required
          >
          </textarea>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn"
            style="background-color: #bbbefc"
            data-bs-dismiss="modal"
            :disabled="feedbackMessage === ''"
            @click="sendFeedback"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
  <aside
    class="bg-subtle border-base border-r min-h-screen mh-[100%] flex-col flex justify-between"
  >
    <div>
      <header
        class="px-4xl flex justify-center flex-col h-[60px] items-center self-stretch border-base border-b"
      >
        <div class="px-xl w-[250px]">
          <RouterLink to="/" class="cursor-pointer">
            <Icon styles="min-w-[150px] min-h-[23px]" name="ZenguardLogoText" />
          </RouterLink>
        </div>
      </header>
      <div class="p-4xl">
        <div class="flex flex-col gap-2xs">
          <SidebarLink
            v-for="link of links"
            :to="link.to"
            :key="link.title"
            :linkId="link.linkId"
            :link="link"
            class="flex flex-row items-center gap-small"
          >
          </SidebarLink>
          <a
            class="flex flex-row items-center gap-small hover:bg-[#EEEFF3] w-[250px] text-fg-subtle transition py-small pl-xl pr-4xl rounded-small hover:text-fg-subtle"
            href="https://docs.zenguard.ai/"
          >
            <Icon styles="inline" name="Book" />
            <span class="text-[14px] font-medium leading-5">Documentation</span>
          </a>
          <button
            class="flex flex-row items-center gap-small hover:bg-[#EEEFF3] w-[250px] text-fg-subtle transition py-small pl-xl pr-4xl rounded-small hover:text-fg-subtle"
            data-bs-toggle="modal"
            data-bs-target="#feedback-model"
          >
            <Icon styles="inline" name="Star" />
            <span class="text-[14px] font-medium leading-5">Feedback</span>
          </button>
        </div>
      </div>
    </div>
    <SidebarFooter />
  </aside>
</template>
