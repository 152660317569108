<!-- eslint-disable vue/multi-word-component-names -->

<script setup lang="ts">
import { defineProps } from "vue";
import { cn } from "@/utils/utils";

const props = defineProps<{
  to: string;
  styles?: string;
  isDefaultLink?: boolean;
}>();
</script>

<template>
  <!-- Use the v-if and v-else directives to conditionally render RouterLink or a standard anchor tag -->
  <RouterLink
    v-if="!props.isDefaultLink"
    :class="
      cn(
        props.styles,
        'text-[14px] transition leading-5 text-fg-link cursor-pointer hover:text-[#538dcf]'
      )
    "
    :to="props.to"
  >
    <slot />
  </RouterLink>
  <a
    v-else
    :class="
      cn(
        props.styles,
        'text-[14px] transition leading-5 text-fg-link cursor-pointer hover:text-[#538dcf]'
      )
    "
    :href="props.to"
  >
    <slot />
  </a>
</template>
