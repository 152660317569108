import { cva, type VariantProps } from "class-variance-authority";

export type selectTriggerVariantsProps = VariantProps<
  typeof selectTriggerVariants
>;
export const selectTriggerVariants = cva(
  "inline-flex gap-xs items-center rounded-small border-[1px] text-[13px] select-none leading-5 font-medium transition px-xl",
  {
    variants: {
      intent: {
        base: "border-base bg-subtle text-fg-base shadow-select-default active:bg-[#E2E3E9] focus:shadow-select-focus outline-brand  hover:bg-[#EEEFF3]",
      },
      size: { small: "py-xs", medium: "py-medium" },
    },
  }
);
