import { defineStore } from "pinia";
import { ref } from "vue";

export type DetectorsResponses = {
  responses: Array<{
    detector: string;
    common_response: Record<string, unknown>;
  }>;
};

export interface ChatResponse {
  color: string;
  title: string;
  text?: string;
  detected?: boolean;
  stream_complete?: boolean;
  position?: number;
  detectorsResponse?: DetectorsResponses;
  allDetectorsResponses?: DetectorsResponses;
  messageID?: string;
  latency?: number;
}
export const useChatStore = defineStore("chatStore", () => {
  const response_data = ref<Array<ChatResponse>>([]);

  const chatLastPrompt = ref("");

  const setChat = (
    newChat: {
      color: string;
      title: string;
      text: string;
      detected?: boolean;
      stream_complete?: boolean;
    }[]
  ) => (response_data.value = newChat);
  const getChat = () => response_data;

  const clearChat = () => (response_data.value = []);

  return {
    setChat,
    getChat,
    clearChat,
    chatLastPrompt,
  };
});
