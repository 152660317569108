<script setup lang="ts">
import { reactive, computed, ref, type InputHTMLAttributes } from "vue";
import { useAuthStore } from "@/stores/AuthStore";

import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { SignUpSchema } from "@/validations/auth/SignUpSchema";
import {
  isAlphabetic,
  isLowerCase,
  isUpperCase,
  isNumber,
} from "@/validations/auth/utils";

import { toast } from "vue3-toastify";

import AuthLayout from "@/components/auth/AuthLayout.vue";
import PasswordScore from "@/components/auth/PasswordScore.vue";

import Link from "@/components/ui/links/Link.vue";
import Input from "@/components/ui/input/Input.vue";
import Icon from "@/components/ui/icons/Icon.vue";
import Button from "@/components/ui/button/Button.vue";
import Separator from "@/components/ui/separator/Separator.vue";

import "vue3-toastify/dist/index.css";

const authStore = useAuthStore();

const { values, handleSubmit, setErrors } = useForm({
  validationSchema: toTypedSchema(SignUpSchema),
  initialValues: {
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
  },
});
const isFormSubmitted = ref<boolean>(false);

const passwordVisibility = reactive<{ type: InputHTMLAttributes["type"] }>({
  type: "password",
});
const confirmPasswordVisibility = reactive<{
  type: InputHTMLAttributes["type"];
}>({
  type: "password",
});
const isPasswordScoreShowed = ref<boolean>(false);

const minLength = 8;
const passwordValidations = computed(() => [
  {
    isValid: values.password.length >= minLength,
    message: "At least 8 characters long",
  },
  {
    isValid: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(values.password),
    message: "At least one special character",
  },
  {
    isValid: isLowerCase(values.password),
    message: "At least one lowercase letter",
  },
  {
    isValid: isUpperCase(values.password),
    message: "At least one uppercase letter",
  },
  {
    isValid: isNumber(values.password),
    message: "At least one number",
  },
]);

const onTogglePasswordVisibility = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (target.id === "password")
    passwordVisibility.type =
      passwordVisibility.type === "password" ? "text" : "password";
  else if (target.id === "confirmPassword")
    confirmPasswordVisibility.type =
      confirmPasswordVisibility.type === "password" ? "text" : "password";
};
const showPasswordScore = (event: FocusEvent) => {
  if (event) isPasswordScoreShowed.value = true;
};

const submitedBefore = ref<boolean>(false);
const onResendEmail = async () => {
  isEmailResendVisible.value = true;
  startTimer();
  await authStore.resendVerificationEmail();
};

const isEmailResendVisible = ref(false);
const timerEnabled = ref(false);
const timerDisplay = ref("");
const timerSeconds = 60;

const startTimer = () => {
  timerEnabled.value = true;
  let timer = timerSeconds;
  const interval = setInterval(() => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    timerDisplay.value = `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    if (--timer < 0) {
      clearInterval(interval);
      timerEnabled.value = false;
      timerDisplay.value = "";
      submitedBefore.value = true;
      isEmailResendVisible.value = false;
    }
  }, 1000);
};

const onSubmit = handleSubmit(async (values) => {
  if (isFormSubmitted.value === true) return; // to prevent multiple submits

  try {
    if (!isAlphabetic(values.name)) {
      return setErrors({ name: "Use only alphabetic letters" });
    }

    if (values.password !== values.confirmPassword)
      return setErrors({
        password: "Password must match",
        confirmPassword: "Password must match",
      });

    for (let i = 0; i < passwordValidations.value.length; i++)
      if (!passwordValidations.value[i].isValid)
        return setErrors({
          password: "Password must satisfy criteria:",
          confirmPassword: "Password must satisfy criteria.",
        });

    isFormSubmitted.value = true;

    authStore.signup({
      email: values.email,
      name: values.name,
      password: values.password,
    });

    isEmailResendVisible.value = true;
    startTimer();
  } catch (error) {
    console.log(error);
    toast.error("Failed to sign up", { autoClose: 500 });
  } finally {
    isFormSubmitted.value = false;
  }
});

const signInWithGoogle = async () => {
  try {
    await authStore.signInWithGoogle();
  } catch (error) {
    toast.error("Failed to sign up with Google", { autoClose: 500 });
  }
};
</script>

<template>
  <AuthLayout title="Sign Up">
    <template #body>
      <form @submit.prevent="onSubmit" class="flex flex-col gap-xl">
        <Input
          labelText="Email"
          id="email"
          type="email"
          placeholder="Enter your email address"
          name="email"
          intent="default"
          size="large"
        />
        <p class="text-[12px] leading-5 text-fg-subtle">
          Competitors are prohibited from signing up for ZenGuard AI. By
          registering, you confirm that you are not a competitor of ZenGuard AI.
        </p>
        <Input
          labelText="Name"
          id="name"
          type="text"
          placeholder="Enter your name"
          name="name"
          intent="default"
          size="large"
        />
        <div class="flex gap-small flex-col">
          <Input
            @onDisplayPasswordScore="showPasswordScore"
            labelText="Password"
            id="password"
            :type="passwordVisibility.type"
            placeholder="Enter your password "
            name="password"
            intent="default"
            size="large"
          >
            <template v-if="passwordVisibility.type === 'password'" #iconRight>
              <Icon
                id="password"
                @click="onTogglePasswordVisibility"
                styles="cursor-pointer stroke-[#666C89]"
                name="OpenEye"
              />
            </template>

            <template v-else #iconRight>
              <Icon
                id="password"
                @click="onTogglePasswordVisibility"
                styles="cursor-pointer"
                name="ClosedEye"
              />
            </template>
          </Input>

          <PasswordScore
            v-if="isPasswordScoreShowed"
            :passwordValidations="passwordValidations"
            :passwordValue="values.password"
          />
        </div>

        <Input
          labelText="Confirm Password"
          id="confirmPassword"
          :type="confirmPasswordVisibility.type"
          placeholder="Enter your password "
          name="confirmPassword"
          intent="default"
          size="large"
        >
          <template
            v-if="confirmPasswordVisibility.type === 'password'"
            #iconRight
          >
            <Icon
              id="confirmPassword"
              @click="onTogglePasswordVisibility"
              styles="cursor-pointer stroke-[#666C89]"
              name="OpenEye"
            />
          </template>

          <template v-else #iconRight>
            <Icon
              id="confirmPassword"
              @click="onTogglePasswordVisibility"
              styles="cursor-pointer"
              name="ClosedEye"
            />
          </template>
        </Input>

        <div class="w-full flex flex-col gap-2xl">
          <Button
            v-if="!submitedBefore"
            :disabled="isEmailResendVisible"
            intent="primary"
            size="large"
            type="submit"
          >
            <template v-if="isFormSubmitted">
              <Icon name="LoadingSpinner" />
            </template>
            <template v-else-if="isEmailResendVisible">
              Resend Email: {{ timerDisplay }}
            </template>
            <template v-else> Sign Up </template>
          </Button>

          <Button
            v-else
            @click="onResendEmail"
            :disabled="isEmailResendVisible"
            intent="primary"
            size="large"
            type="button"
          >
            <template v-if="isEmailResendVisible">
              Resend Email: {{ timerDisplay }}
            </template>
            <template v-else> Resend Email </template>
          </Button>

          <div class="flex items-center flex-row gap-[19px]">
            <Separator intent="muted" styles="flex-1" />
            <p>or</p>
            <Separator intent="muted" styles="flex-1" />
          </div>

          <Button
            @click="signInWithGoogle"
            intent="secondary"
            type="button"
            size="large"
          >
            <template #iconLeft>
              <Icon name="GoogleLogo" />
            </template>
            <template #default>Continue with Google</template>
          </Button>

          <p class="text-center text-[14px] leading-5 text-fg-subtle">
            Already have and acccount?
            <Link to="/login"> Sign In </Link>
          </p>

          <Separator intent="muted" />

          <p class="text-center text-[12px] leading-5 text-fg-subtle">
            By signing up you agree to our
            <br class="block md:hidden" />
            <a
              href="https://docs.zenguard.ai/terms-of-service/"
              class="text-fg-link cursor-pointer hover:text-[#538dcf] tranistion"
            >
              Terms of Service
            </a>
            and
            <a
              href="https://docs.zenguard.ai/privacy-policy/"
              class="text-fg-link cursor-pointer hover:text-[#538dcf] tranistion"
              >Privacy Policy</a
            >
          </p>
        </div>
      </form>
    </template>
  </AuthLayout>
</template>
