<script setup lang="ts">
import { InputHTMLAttributes, reactive, ref } from "vue";

import { useAuthStore } from "@/stores/AuthStore";

import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { LoginSchema } from "@/validations/auth/LoginSchema";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import AuthLayout from "@/components/auth/AuthLayout.vue";

import Link from "@/components/ui/links/Link.vue";
import Icon from "@/components/ui/icons/Icon.vue";
import Input from "@/components/ui/input/Input.vue";
import Button from "@/components/ui/button/Button.vue";
import Separator from "@/components/ui/separator/Separator.vue";

const { setErrors, handleSubmit } = useForm({
  validationSchema: toTypedSchema(LoginSchema),
  initialValues: {
    email: "",
    password: "",
  },
});

const authStore = useAuthStore();

const isFormSubmitted = ref<boolean>(false);

const passwordVisibility = reactive<{ type: InputHTMLAttributes["type"] }>({
  type: "password",
});

const onTogglePasswordVisibility = () =>
  (passwordVisibility.type =
    passwordVisibility.type === "password" ? "text" : "password");

const onSubmit = handleSubmit(async (values) => {
  try {
    if (isFormSubmitted.value) return; // in case if user try to click form submission fastly several times
    isFormSubmitted.value = true;

    await authStore.login({ email: values.email, password: values.password });
  } catch (error) {
    console.log(error);
    setErrors({
      email: "Invalid credentials",
      password: "Invalid credentials",
    });
  } finally {
    isFormSubmitted.value = false;
  }
});

const signInWithGoogle = async () => {
  try {
    await authStore.signInWithGoogle();
  } catch (error) {
    toast.error("Failed to sign in with Google", { autoClose: 500 });
  }
};
</script>

<template>
  <AuthLayout title="Sign In">
    <template #header>
      <p class="text-fg-muted text-[14px] leading-5 font-normal">
        Please ensure that you are visiting:
      </p>
      <div
        class="inline-flex flex-row items-center p-2xs w-[215px] gap-2xs bg-subtle border-[1px] rounded-xs border-muted"
      >
        <Icon styles="stroke-brand" name="Lock" />
        <p class="text-[14px] leading-5 font-normal text-fg-subtle">
          https://console.zenguard.ai
        </p>
      </div>
    </template>

    <template #body>
      <form @submit.prevent="onSubmit" class="flex flex-col gap-xl">
        <Input
          labelText="Email"
          id="email"
          type="email"
          placeholder="Enter your email address"
          name="email"
          intent="default"
          size="large"
        >
        </Input>

        <Input
          labelText="Password"
          id="password"
          :type="passwordVisibility.type"
          placeholder="Enter your password "
          name="password"
          intent="default"
          size="large"
        >
          <template v-if="passwordVisibility.type === 'password'" #iconRight>
            <Icon
              @click="onTogglePasswordVisibility"
              styles="cursor-pointer stroke-[#666C89]"
              name="OpenEye"
            />
          </template>
          <template v-else #iconRight>
            <Icon
              @click="onTogglePasswordVisibility"
              styles="cursor-pointer"
              name="ClosedEye"
            />
          </template>
        </Input>
        <Link class="flex flex-row-reverse pb-2xl" to="/recovery"
          >Forgot Password</Link
        >

        <div class="w-full flex flex-col gap-2xl">
          <Button intent="primary" size="large">
            <template v-if="isFormSubmitted">
              <Icon name="LoadingSpinner" />
            </template>

            <template v-else> Sign In </template>
          </Button>

          <div class="flex items-center flex-row gap-[19px]">
            <Separator intent="muted" styles="flex-1" />
            <p>or</p>
            <Separator intent="muted" styles="flex-1" />
          </div>

          <Button
            @click="signInWithGoogle"
            intent="secondary"
            type="button"
            size="large"
          >
            <template #iconLeft>
              <Icon name="GoogleLogo" />
            </template>
            <template #default>Continue with Google</template>
          </Button>

          <p class="text-center text-[14px] leading-5 text-fg-subtle">
            Don't have an account?
            <Link to="/signup"> Sign Up </Link>
          </p>
        </div>
      </form>
    </template>
  </AuthLayout>
</template>
