<script setup lang="ts">
import { onMounted, onUpdated, onUnmounted, ref, watch, type Ref } from "vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useChatStore, type ChatResponse } from "@/stores/ChatStore";
import useChatDetails, { getDetectorTitle } from "@/composables/useDetails";
import { usePromptsStore } from "@/stores/PromptsStore";

import { createSocket } from "@/socket";
import { backend } from "@/main";
import { nanoid } from "nanoid";
import { gzip } from "pako";

import { toast } from "vue3-toastify";
import Draggable from "vuedraggable";

import {
  SelectGroup,
  SelectPortal,
  SelectRoot,
  SelectViewport,
} from "radix-vue";

import SelectTrigger from "@/components/ui/select/selectTrigger/SelectTrigger.vue";
import SelectContent from "@/components/ui/select/SelectContent.vue";
import SelectItem from "@/components/ui/select/SelectItem.vue";

import SystemMessage from "@/components/chat/SystemMessage.vue";
import UserMessage from "@/components/chat/UserMessage.vue";

import ChatPrompts from "@/components/chat/ChatPrompts/ChatPrompts.vue";
import ChatPrompt from "@/components/chat/ChatPrompts/ChatPrompt.vue";

import Link from "@/components/ui/links/Link.vue";
import Button from "@/components/ui/button/Button.vue";
import Icon from "@/components/ui/icons/Icon.vue";
import Separator from "@/components/ui/separator/Separator.vue";

import Tooltip from "@/components/ui/Tooltip.vue";

const inputRef = ref<HTMLInputElement | null>(null);
const isInputEmpty = ref<boolean>(true);
const chatScreenElement = ref<Element | null>(null);
const chatScrollHeight = ref<string>("auto");
const chatDOMElement = ref<Element | null>(null);
const chatIsScrolled = ref<boolean>(true);
const chatDetectors = useChatDetails().chatDetectors;
const inParallel = ref<boolean>(true);
const enableAll = ref<boolean>(false);
const showAllResponses = ref<boolean>(false);
const MIN_PROMPT_LENGTH_FOR_COMPRESSION = 100;
const { selectedModel } = useChatDetails();

const authStore = useAuthStore();
const token = authStore?.user?.token;
const socket = createSocket(token);
authStore.setSocket(socket);
const MAX_RESPONSE_DATA_LENGTH = 1000; // 500 questions and 500 answers.
const HEGHT_MESSAGE_FIELD_WITH_LOGO_IN_PX = 61;
let lastSentMessageID: string = undefined;
const isRequestInProccess = ref(false);

function handleInputChanging(event: Event) {
  (event.target as HTMLInputElement).value.trim().length !== 0
    ? (isInputEmpty.value = false)
    : (isInputEmpty.value = true);
}

const models = ref<string[]>([]);

const isSidebarCollapsed = ref<boolean>();

const response_data: Ref<ChatResponse[]> = useChatStore().getChat();

authStore.setSocket(socket);

function checkResponseDataLength() {
  if (response_data.value.length > MAX_RESPONSE_DATA_LENGTH) {
    response_data.value = response_data.value.slice(-MAX_RESPONSE_DATA_LENGTH);
  }
}

function scrollChatToBottom() {
  chatDOMElement.value.scrollTop = chatDOMElement.value.scrollHeight;
}
backend.get("/supported_llms").then((payload) => {
  if (payload.status === 200) {
    models.value = payload.data.llms;
  } else {
    throw new Error("Unexpected status code: " + payload.status);
  }
});

onMounted(() => {
  scrollChatToBottom();
  updateChatScrollHeight();
  window.addEventListener("resize", updateChatScrollHeight);
});

onUnmounted(() => {
  socket.disconnect();
  window.removeEventListener("resize", updateChatScrollHeight);
});

onUpdated(() => {
  const length = response_data.value.length;
  if (length === 0) return;
  const lastIndex = length - 1;
  if (
    (response_data.value[lastIndex].position === 0 ||
      response_data.value[lastIndex].position === -1) &&
    chatIsScrolled.value === false
  ) {
    scrollChatToBottom();
    chatIsScrolled.value = true;
  }
  const shouldScroll =
    Math.round(chatDOMElement.value.scrollTop) +
      chatDOMElement.value.clientHeight >=
    chatDOMElement.value.scrollHeight - HEGHT_MESSAGE_FIELD_WITH_LOGO_IN_PX;
  if (shouldScroll) scrollChatToBottom();
});

async function sendPromptToBackend(prompt: string) {
  useChatStore().chatLastPrompt = prompt;
  response_data.value.push({
    color: "text-primary",
    title: (authStore.user.name ? authStore.user.name : authStore.user.email)
      .split("@")[0]
      .charAt(0)
      .toUpperCase(),
    text: prompt ?? "",
    position: -1,
  });
  chatIsScrolled.value = false;
  const messageID = nanoid();
  isRequestInProccess.value = true;
  socket.emit("message", {
    uid: authStore.user.uid,
    email: authStore.user.email,
    llm: selectedModel.value.toLowerCase().replaceAll(/\s/g, ""),
    message:
      prompt.length > MIN_PROMPT_LENGTH_FOR_COMPRESSION ? gzip(prompt) : prompt,
    message_id: messageID,
    detect_in_parallel: inParallel.value,
  });
  lastSentMessageID = messageID;
  checkResponseDataLength();
}

async function onSubmit() {
  isRequestInProccess.value = true;
  if (inputRef.value?.value.trim().length === 0) return;

  const userQuery = inputRef.value?.value;

  try {
    // This is the user request text
    await sendPromptToBackend(userQuery);
  } catch (error) {
    console.log(error);
  }

  if (inputRef.value) {
    inputRef.value.value = "";
    inputRef.value.focus();
  }
}

function clearChatWindow() {
  if (confirm("The chat window will be cleared. Are you sure?")) {
    useChatStore().clearChat();
    lastSentMessageID = undefined;
    isRequestInProccess.value = false;
  }
}

async function clearCache() {
  try {
    if (confirm("The cache will be cleared. Are you sure?")) {
      await backend.put(`/policy/cache/clear/${authStore.user.uid}`);
      isRequestInProccess.value = false;
    }
  } catch (error) {
    console.error(error);
    toast.error("Failed to clear cache", { autoClose: 500 });
  }
}

function printDetectorsResponse(detectorsResp, messageID, latency) {
  detectorsResp.dangerous_detectors = detectorsResp.dangerous_detectors || [];
  const isDangerous = detectorsResp.dangerous_detectors.length > 0;
  const allDetectorsResponses = { ...detectorsResp };
  if (detectorsResp.responses && !showAllResponses.value) {
    detectorsResp.responses = detectorsResp.responses.filter(
      (resp) =>
        resp.common_response.is_detected ||
        detectorsResp.dangerous_detectors.includes(resp.detector)
    );
  }

  if (
    detectorsResp.response &&
    detectorsResp.responses.length === 0 &&
    !latency
  )
    return;

  const maxLatency = 800;
  const latencyDecimalPart = Number((latency.toFixed(2) % 1).toFixed(2));
  const latencyIntegralPart = Math.floor(latency) % 100;
  const latencyRemainder = latencyIntegralPart + latencyDecimalPart;

  response_data.value.push({
    color: isDangerous ? "text-danger" : "text-success",
    title: "ZenGuard",
    detectorsResponse: detectorsResp,
    allDetectorsResponses,
    messageID,
    position: -1,
    latency:
      latency > maxLatency
        ? (latencyRemainder + (maxLatency - 100)).toFixed(2)
        : latency.toFixed(2),
  });
}

socket.on("message", (payload) => {
  const data = JSON.parse(payload);

  if (data["message_id"] !== lastSentMessageID) return;

  if (data["position"] === -1) {
    isRequestInProccess.value =
      data["state"] === "detectors_response" &&
      JSON.parse(data["message"])["dangerous_detectors"].length === 0
        ? true
        : false;
  } else {
    isRequestInProccess.value = true;
  }

  if (!data["message"]) {
    return;
  }

  if (data["state"] != "success") {
    if (data["state"] === "detectors_response") {
      const detectorsResp = JSON.parse(data["message"]);
      printDetectorsResponse(
        detectorsResp,
        data["message_id"],
        data["latency"]
      );
    } else {
      response_data.value.push({
        color: "text-danger",
        title: "ZenGuard",
        detected:
          data["state"] === "pii_detected" ||
          data["state"] === "keyword_detected"
            ? true
            : false,
        text: data["message"],
        position: -1,
      });
    }
    chatIsScrolled.value = false;
    return;
  }

  if (data["position"] == 0) {
    response_data.value.push({
      color: "text-success",
      title: selectedModel.value,
      text: data["message"],
      position: 0,
    });
    chatIsScrolled.value = false;
    return;
  }
  const last = response_data.value.length - 1;
  // if cached success message
  if (
    data["position"] === -1 &&
    response_data.value[last].title !== selectedModel.value
  ) {
    response_data.value.push({
      color: "text-success",
      title: selectedModel.value,
      text: data["message"],
      position: -1,
    });
    chatIsScrolled.value = false;
    return;
  }

  response_data.value[last].text += data["message"];
  response_data.value[last].position = data["position"];
});

async function throwRandomPI() {
  const prompts = usePromptsStore().prompts;
  try {
    if (prompts.length === 0) {
      console.error("No available attacks");
      return;
    }
    const randomIndex = Math.floor(Math.random() * prompts.length);
    const maliciousPromptString = prompts[randomIndex].prompts_string;
    // This is the user request text
    isRequestInProccess.value = true;
    await sendPromptToBackend(maliciousPromptString);
  } catch (error) {
    toast.error("Error when Try Prompt Injection", { autoClose: 500 });
    return;
  }
}

function loadChatDetectors() {
  if (!authStore.user) return;
  const path = `/policy/${authStore.user.uid}/${authStore.user.email}`;
  backend
    .get(path)
    .then((payload) => {
      chatDetectors.selectedDetectors = payload.data.policy.detectors;
      chatDetectors.defaultDetectors = [
        ...chatDetectors.selectedDetectors,
        ...chatDetectors.defaultDetectors.filter(
          (v) => !chatDetectors.selectedDetectors.includes(v)
        ),
      ];
      if (
        chatDetectors.selectedDetectors.length ===
        chatDetectors.defaultDetectors.length
      )
        enableAll.value = true;
    })
    .catch((err) => {
      console.log(err);
      toast.error("Failed to load detectors", { autoClose: 500 });
    });
}
loadChatDetectors();

let detectorsController: AbortController;
function getDetectorsController(): AbortController {
  if (detectorsController) detectorsController.abort();
  detectorsController = new AbortController();
  return detectorsController;
}

async function onChangeDetectors() {
  chatDetectors.selectedDetectors = chatDetectors.defaultDetectors.filter((v) =>
    chatDetectors.selectedDetectors.includes(v)
  );

  try {
    const signal = getDetectorsController().signal;
    await backend.put(
      `/policy/detectors/update/${authStore.user.uid}`,
      { signal },
      {
        params: {
          detectors: chatDetectors.selectedDetectors,
        },
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  } catch (err) {
    console.log(err);
    toast.error("Failed to update detectors", { autoClose: 500 });
  }
}

watch(enableAll, (value) => {
  chatDetectors.selectedDetectors = value ? chatDetectors.defaultDetectors : [];
  onChangeDetectors();
});
watch(selectedModel, () => {
  toast(`You changed the model to ${selectedModel.value}`, {
    autoClose: 1000,
  });
});

const updateChatScrollHeight = () => {
  chatScrollHeight.value =
    chatScreenElement.value.getBoundingClientRect().height - 14 + "px";
};

const toggleSidebar = () => {
  isSidebarCollapsed.value
    ? (isSidebarCollapsed.value = false)
    : (isSidebarCollapsed.value = true);
};

function onStop(): void {
  lastSentMessageID = "";
  isRequestInProccess.value = false;
  isInputEmpty.value = true;
}
</script>

<template>
  <div class="flex w-full gap-6xl">
    <div class="w-[100%] flex flex-col py-6xl pl-6xl max-h-screen">
      <div class="relative flex-1 w-auto" ref="chatScreenElement">
        <section
          class="flex flex-col overflow-y-auto w-full"
          ref="chatDOMElement"
        >
          <div style="max-height: 80vh" class="flex flex-col gap-xl">
            <div
              class="px-medium flex flex-col whitespace-break-spaces break-words"
              v-for="(response, index) in response_data"
              :key="index"
            >
              <SystemMessage
                v-if="
                  response.title.trim() === 'ZenGuard' ||
                  response.title.trim() === 'ChatGPT' ||
                  response.title.trim() === 'Claude' ||
                  response.title.trim() === 'Llama 2' ||
                  response.title.trim() === 'Gemini'
                "
                :textColor="response.color"
                :allDetectorsResponses="response.allDetectorsResponses"
                :detectorsResponse="response.detectorsResponse"
                :messageID="response.messageID"
                :latency="response.latency"
                :iconName="response.title"
                :title="response.title"
                :text="response.text"
              />

              <UserMessage v-else>{{ response.text }}</UserMessage>
            </div>
          </div>

          <ChatPrompts
            :response_data="response_data"
            :sendPromptToBackend="sendPromptToBackend"
          />
        </section>
      </div>

      <div class="flex flex-col items-center justify-between">
        <div class="w-full">
          <form
            @submit.prevent="onSubmit"
            id="input-form"
            class="flex gap-xl flex-row py-xs pl-xl pr-xs border-[1px] border-base rounded-small"
          >
            <input
              type="text"
              id="input-prompt-chat"
              class="flex-1 text-[14px] leading-5 text-fg-base font-normal"
              placeholder="Type your message here..."
              aria-label="LLM Query"
              aria-describedby="Input Chat"
              autofocus
              ref="inputRef"
              @input="handleInputChanging"
            />

            <Tooltip
              v-if="isRequestInProccess"
              :delayDuration="400"
              :sideOffset="8"
              side="top"
              tooltipText="Stop"
            >
              <template #TooltipTrigger>
                <Button
                  type="button"
                  size="small-icon"
                  intent="tertiary"
                  @click="onStop"
                >
                  <Icon name="Cross" />
                </Button>
              </template>
            </Tooltip>
            <Tooltip
              v-else
              :delayDuration="400"
              :sideOffset="8"
              side="top"
              tooltipText="Send Message"
            >
              <template #TooltipTrigger>
                <Button
                  type="submit"
                  size="small-icon"
                  intent="tertiary"
                  :disabled="isInputEmpty || isRequestInProccess"
                >
                  <Icon name="Arrow" />
                </Button>
              </template>
            </Tooltip>
          </form>
        </div>
      </div>
    </div>

    <!--  RIGHT sidebar -->
    <div
      v-if="isSidebarCollapsed"
      class="py-6xl pl-5xl pr-6xl border-l border-muted"
    >
      <Tooltip
        :delayDuration="400"
        :sideOffset="5"
        side="left"
        tooltipText="Expand Sidebar"
      >
        <template #TooltipTrigger>
          <Button
            intent="secondary"
            size="small-icon"
            styles="max-w-[36px] items-center"
            @click="toggleSidebar"
          >
            <Icon styles="max-h-[20px] max-w-[20px]" name="ExpandSidebar" />
          </Button>
        </template>
      </Tooltip>

      <Separator styles="my-4xl" intent="muted" />

      <Tooltip
        :delayDuration="400"
        :sideOffset="5"
        side="left"
        tooltipText="Clear Chat"
      >
        <template #TooltipTrigger>
          <Button
            intent="secondary"
            size="small-icon"
            styles="max-w-[36px] items-center"
            @click="clearChatWindow"
          >
            <Icon
              styles="max-h-[20px] max-w-[20px] stroke-[#666C89]"
              name="Tassel"
            />
          </Button>
        </template>
      </Tooltip>
    </div>

    <div
      v-else
      class="min-w-[264px] flex flex-col justify-between py-6xl pl-5xl pr-6xl border-l-[1px] border-muted"
    >
      <div>
        <Tooltip
          :delayDuration="400"
          :sideOffset="5"
          side="left"
          classes="mb-[13px]"
          tooltipText="Collapse Sidebar"
        >
          <template #TooltipTrigger>
            <Button
              intent="secondary"
              size="small-icon"
              styles="max-w-[36px] items-center mb-xl"
              @click="toggleSidebar"
            >
              <Icon styles="max-h-[20px] max-w-[20px]" name="CollapseSidebar" />
            </Button>
          </template>
        </Tooltip>

        <div class="flex flex-col gap-medium">
          <div>
            <h2
              class="text-[14px] font-medium leading-5 text-fg-base pb-medium"
            >
              LLM
            </h2>

            <SelectRoot v-model="selectedModel">
              <SelectTrigger size="small" styles="max-w-[110px]" />

              <SelectPortal>
                <SelectContent :side-offset="5" side="bottom" position="popper">
                  <SelectViewport class="p-[5px]">
                    <SelectGroup>
                      <SelectItem :items="models" value="any" />
                    </SelectGroup>
                  </SelectViewport>
                </SelectContent>
              </SelectPortal>
            </SelectRoot>
          </div>

          <div>
            <h2 class="text-[14px] font-medium leading-5 text-fg-base pb-3xs">
              Detectors
            </h2>
            <p class="text-[13px] leading-5 font-normal text-fg-base">
              Enable detectors to test. <br />
              Re-order detectors to change their priority.
            </p>
          </div>

          <Draggable
            v-model="chatDetectors.defaultDetectors"
            tag="div"
            class="flex flex-col gap-xs text-[13px] detector-items relative"
            item-key="element"
            @change="onChangeDetectors()"
          >
            <template #item="{ element: detector }">
              <div>
                <label
                  :for="detector"
                  class="flex items-center flex-row gap-xs justify-start py-xs px-medium border-[1px] border-base rounded-xs hover:bg-subtle transition cursor-pointer"
                >
                  <input
                    class="m-0 appearance-none bg-white pr-xl check__input"
                    type="checkbox"
                    :id="detector"
                    :value="detector"
                    v-model="chatDetectors.selectedDetectors"
                    @change="onChangeDetectors()"
                  />
                  <span
                    class="absolute w-xl h-xl -mr-xl rounded-[3px] bg-base hover:bg-subtle shadow-checkbox-base focus:shadow-checkbox-focus check__box"
                  />
                  {{ getDetectorTitle(detector) }}
                </label>
              </div>
            </template>
          </Draggable>

          <div class="flex flex-col gap-xs">
            <div class="flex items-center justify-between py-2xs">
              <label
                for="enableAllCheckbox"
                class="text-[13px] leading-5 text-fg-base"
              >
                Enable All
              </label>
              <input
                type="checkbox"
                v-model="enableAll"
                class="checkbox"
                role="switch"
                id="enableAllCheckbox"
                value="enableAllCheckbox"
              />
              <label class="switch pl-5xl" for="enableAllCheckbox" />
            </div>

            <div class="flex items-center justify-between py-2xs">
              <label
                for="flexSwitchCheckDefault"
                class="text-[13px] leading-5 text-fg-base"
              >
                Process in parallel
              </label>
              <input
                type="checkbox"
                v-model="inParallel"
                class="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                value="flexSwitchCheckDefault"
              />
              <label class="switch pl-5xl" for="flexSwitchCheckDefault" />
            </div>

            <div class="flex items-center justify-between py-2xs">
              <label
                for="showAllResponses"
                class="text-[13px] leading-5 text-fg-base"
              >
                Show empty responses
              </label>
              <input
                type="checkbox"
                v-model="showAllResponses"
                class="checkbox"
                role="switch"
                id="showAllResponses"
                value="showAllResponses"
              />
              <label class="switch pl-5xl" for="showAllResponses" />
            </div>
          </div>
        </div>

        <Separator class="my-xl" intent="base" />

        <div>
          <h3 class="text-[14px] leading-5 text-fg-base font-medium pb-medium">
            Actions
          </h3>

          <ChatPrompt
            @click="throwRandomPI"
            :disabled="isRequestInProccess"
            class="justify-center w-[100%] mb-1"
            prompt="Prompt Injection"
          />
          <ChatPrompt
            @click="sendPromptToBackend(useChatStore().chatLastPrompt)"
            :disabled="
              isRequestInProccess || useChatStore().chatLastPrompt === ''
            "
            class="justify-center w-[100%]"
            prompt="Repeat last prompt"
          />
        </div>

        <Separator class="my-xl" intent="base" />
        <Button
          class="w-[100%] mb-[10px]"
          intent="secondary"
          size="small"
          @click="clearChatWindow()"
        >
          <template #iconLeft>
            <Icon name="Tassel" styles="stroke-[#959AB1]" />
          </template>

          Clear Chat
        </Button>
        <Button
          class="mb-[40px] w-[100%]"
          intent="secondary"
          size="small"
          @click="clearCache"
        >
          <template #iconLeft>
            <Icon name="Tassel" styles="stroke-[#959AB1]" />
          </template>

          Clear Cache
        </Button>
      </div>

      <div
        class="bg-subtle p-medium border-[1px] border-base rounded-xs flex gap-medium"
      >
        <Icon name="LightBulb" />
        <p class="text-[13.5px] leading-5 font-normal text-fg-subtle">
          Test your policy settings. <br />
          Change settings <Link to="/policy">Here</Link>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.chat-options {
  display: flex;
  justify-content: center;
  gap: 60px;
  align-items: last baseline;
}

.chat-column {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.response {
  color: black;
}

.warning {
  color: red;
}

.request {
  color: black;
}

.input-textarea {
  display: flex;
  flex: 1;
  flex-direction: column;
  input {
    flex: 1;
  }
}

.details {
  width: 350px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: block;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  display: flex;
  flex-direction: column;
  margin-right: 0.75rem;
}

.prompts-cont {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 35px;
  margin-right: 20px;
}
.prompts-cont > button:disabled {
  background-color: #d4d7ff;
}

.chat-details-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 25%;
}

.detected {
  @apply w-full bg-black p-[10px] rounded-[10px] m-[10px] text-[15px];
  width: 100%;
}

.not-detected {
  opacity: 0.85;
}

.detected,
.detected code {
  color: #7870ff;
}

.btn.btn-outline-secondary:hover {
  background-color: #bbbefc;
}

.collapse {
  visibility: visible;
}
.collapse-header.collapsed {
  cursor: pointer;

  .right {
    display: inline;
  }

  .down {
    display: none;
  }
}
.collapse-header {
  .right {
    display: none;
  }

  .down {
    display: inline;
  }
}
.right,
.down {
  cursor: pointer;
  margin-right: 10px;
  width: 20px;
}

/*Checked*/
.check__input:checked + .check__box {
  @apply bg-checkbox-on bg-bottom bg-brand;
}
.check__input:checked + .check__box:hover {
  @apply bg-checkbox-on bg-bottom bg-[#908AFF];
}
/*Focused*/
.check__input:focus + .check__box {
  @apply shadow-checkbox-focus;
}
</style>
