<script setup lang="ts">
import { defineProps } from "vue";

import Icon from "../ui/icons/Icon.vue";

import { cn } from "@/utils/utils";

const props = defineProps<{
  styles?: string;
  title: string;
}>();
</script>
<template>
  <div
    :class="
      cn(
        'max-w-[460px] mx-auto flex align-center justify-center flex-col h-[95vh] phone-480:px-2xl'
      )
    "
  >
    <div>
      <header class="pb-6xl">
        <a
          target="_blank"
          href="https://zenguard-landing-gwlrf6iakq-uc.a.run.app/"
        >
          <Icon styles="mb-5xl" name="ZenguardLogoText" />
        </a>

        <div class="flex flex-col gap-2xs">
          <h2 class="text-[18px] text-fg-base leading-6 font-medium">
            {{ props.title }}
          </h2>
          <slot name="header" />
        </div>
      </header>

      <slot name="body" />
    </div>
  </div>
  <p class="text-center text-[12px] py-medium leading-5 text-fg-muted">
    © 2024 ZenGuard AI.
  </p>
</template>
