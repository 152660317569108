<script setup lang="ts">
import { usePromptsStore, MaliciousPrompt } from "@/stores/PromptsStore";
import { ref, onMounted, watch } from "vue";

import Icon from "@/components/ui/icons/Icon.vue";

import "vue3-toastify/dist/index.css";

const data_list = ref([]);
const maxGenerationOfPrompts = 5;

function generateRandomPromptList(
  prompts: MaliciousPrompt[]
): MaliciousPrompt[] {
  prompts = prompts.concat(
    Array.from({ length: 15 }, () => {
      const promptsArray = Array(
        Math.floor(Math.random() * maxGenerationOfPrompts) + 1
      ).fill("example");
      return {
        author: "anonymous",
        source: "malicious",
        prompts: promptsArray,
        prompts_string: promptsArray.join(" "),
      };
    })
  );

  return prompts;
}

onMounted(() => {
  data_list.value = generateRandomPromptList(usePromptsStore().prompts);
});

watch(usePromptsStore(), () => {
  data_list.value = generateRandomPromptList(usePromptsStore().prompts);
});
</script>

<template>
  <div class="py-7xl px-6xl flex flex-col">
    <h2 class="page-title pb-2xs">Prompt Injection Attack Examples</h2>

    <p class="paragraph pb-4xl">
      Examples of the prompt injections attacks we protect from. Our internal DB
      contains thousands of attacks that we protect from by default.
    </p>
    <div
      class="text-fg-subtle text-[13px] leading-5 font-weight min-w-[1056px] flex flex-row items-center justify-between border-[1px] border-base rounded-small bg-subtle overflow-x-scroll"
    >
      <div class="pl-2xl pr-medium py-medium w-[43px]">#</div>
      <div class="px-2xl flex-1 max-w-[188px]">Author</div>
      <div class="px-2xl flex-1 max-w-[120px]">Source</div>
      <div class="px-2xl flex-1 max-w-[637px]">Array of prompts</div>
      <div class="mr-2xl flex-1 max-w-[34px]">Fixed</div>
    </div>
    <div
      v-for="(singleData, index) in data_list"
      :key="index"
      class="text-fg-base text-[14px] leading-5 font-normal flex flex-row items-center justify-between relative min-w-[1056px]"
    >
      <div
        class="pl-2xl pr-medium py-medium w-[43px]"
        style="font-family: Geist Mono Variable"
      >
        {{ index + 1 }}
      </div>

      <!--  BLUR -->
      <div
        v-if="index === 4"
        class="z-10 top-[0px] absolute w-full backdrop-blur-[4.5px] border-[1px] border-muted rounded-small h-[600px] desktop-2360:h-[630px]"
      >
        <div
          class="w-[300px] relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-row items-center gap-xs bg-base py-small px-2xl border-[1px] rounded-small border-base shadow-btn-neutral text-[14px] leading-6 font-medium text-fg-base"
        >
          <Icon styles="min-w-[20px] max-w-[20px]" name="CheckMarkFilled" />
          Intellectual Property of ZenGuard AI
        </div>
      </div>

      <div class="px-2xl flex-1 max-w-[188px]">
        {{ singleData.author }}
      </div>
      <div class="px-2xl flex-1 max-w-[120px]">{{ singleData.source }}</div>
      <div
        class="px-2xl py-[10px] flex-1 w-full max-w-[637px] overflow-hidden text-nowrap truncate"
      >
        [ "{{ singleData.prompts_string }}" ]
      </div>

      <div class="mr-2xl flex-1 max-w-[34px]">
        <Icon name="CheckMarkFilled" />
      </div>
    </div>
  </div>
</template>
