import { reactive, toRefs } from "vue";

const chatState = reactive<{ selectedModel: string; models: string[] }>({
  selectedModel: "Gemini",
  models: [],
});
const selectedDetectorsAPI = reactive({});
const allDetectors = [
  ["Prompt Injection", "prompt_injection"],
  ["PII", "pii"],
  ["Allowed Topics", "allowed_subjects"],
  ["Banned Topics", "banned_subjects"],
  ["Secrets", "secrets"],
  ["Keywords", "keywords"],
  ["Toxicity", "toxicity"],
];

const defaultDetectors = allDetectors.map((v) => v[1]);

export function getDetectorTitle(detector: string): string {
  return allDetectors.find((v) => v[1] === detector)[0];
}

const chatDetectors = reactive({
  selectedDetectors: [],
  defaultDetectors: defaultDetectors,
  selectedDetectorsAPI: selectedDetectorsAPI,
});

export default function useChatDetails() {
  return {
    ...toRefs(chatState),
    chatDetectors,
  };
}

const codeState = reactive({
  debugCodeModel: "CM starcoder-q4_0",
  debugCodeUser: "Galym",
});

export function useCodeDetails() {
  return {
    ...toRefs(codeState),
  };
}
