import { io } from "socket.io-client";

export function createSocket(token) {
  const socket = io(process.env.VUE_APP_API_BACKEND, {
    extraHeaders: {
      Authorization: `Bearer ${token}`,
    },
    reconnectionDelay: 5000,
    reconnectionDelayMax: 10000,
  });

  socket.on("connect", () => {
    console.log("connected");
  });

  socket.on("disconnect", () => {
    console.log("disconnected");
  });

  return socket;
}
