<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

import { useReportsStore } from "@/stores/ReportsStore";
import useChatDetails, { getDetectorTitle } from "@/composables/useDetails";

import {
  SelectGroup,
  SelectPortal,
  SelectRoot,
  SelectViewport,
} from "radix-vue";

import SelectTrigger from "@/components/ui/select/selectTrigger/SelectTrigger.vue";
import SelectContent from "@/components/ui/select/SelectContent.vue";
import SelectItem from "@/components/ui/select/SelectItem.vue";

import Icon from "@/components/ui/icons/Icon.vue";

import NumberOfRequestPerLLM from "@/components/reports/NumberOfRequestPerLLM.vue";

const numberOfRequesstsPerLLMSeries = ref([]);
const lineChartsOptions = ref();

const detectionStatsSeries = ref([]);
const detectionStatsOptions = ref();

const piiActionChartSeries = ref([]);
const piiActionChartOptions = ref();

const piiTypeStatsChartSeries = ref([]);
const piiTypeStatsChartOptions = ref();

const detectedCount = ref(0);
const totalRequests = ref(0);
const percentSuccessRequests = ref(0);

const mostCommonWords = ref([]);
const mostCommonRequests = ref([]);

const selectedDays = ref(7);

const allReportsFor = ref<string[]>(["Playground"]);
const selectedReportsFor = ref("Playground");
const reports = ref(undefined);

function setAllReportsFor() {
  const allRepsForValue = [];
  if (useReportsStore().userReports) allRepsForValue.push("Playground");
  if (useReportsStore().perApiKeyReports) {
    Object.keys(useReportsStore().perApiKeyReports).forEach((apiKeyName) => {
      allRepsForValue.push(`API key: ${apiKeyName}`);
    });
  }
  allReportsFor.value = allRepsForValue;
}

watch(useReportsStore(), () => {
  setAllReportsFor();
  updateReports();
});

watch(selectedReportsFor, () => {
  updateReports();
});

function updateReports() {
  if (selectedReportsFor.value === "Playground") {
    reports.value = useReportsStore().userReports;

    return;
  }
  if (!useReportsStore().perApiKeyReports) return;
  const apiKey = selectedReportsFor.value.replace("API key: ", "");
  if (!apiKey) return;
  if (!Object.keys(useReportsStore().perApiKeyReports).includes(apiKey)) return;

  reports.value = useReportsStore().perApiKeyReports[apiKey];
}

const selectDays = (day: number) => {
  selectedDays.value = day;
  updateNumberOfRequestsPerLlm(day);
};

const updatePiiActionChart = () => {
  if (!reports.value) return;

  const piiActionStats = reports.value.pii_report.pii_action_stats.map(
    (piiActionStat) => ({
      label: piiActionStat.action_type,
      count: piiActionStat.detected_count,
    })
  );

  piiActionChartSeries.value = piiActionStats
    .filter((stat) => stat.count > 0)
    .map((piiActionStat) => piiActionStat.count);
  piiActionChartOptions.value = {
    labels: piiActionStats.map((piiActionStat) => piiActionStat.label),

    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },

    tooltip: {
      enabled: true,

      custom: function ({ seriesIndex, w }) {
        const seriesInfo = w.globals.seriesNames.map((name, index) => ({
          name,
          index,
          data: w.globals.series[index],
        }));
        return `<div class="text-[13px] leading-5 text-fg-base bg-white py-medium px-large border-[1px] border-base rounded-small" >       <div style="background-color: ${
          w.globals.colors[seriesInfo[seriesIndex].index]
        }" class="w-[8px] h-[8px] inline-block mr-[6px] rounded-full-round"></div> ${
          seriesInfo[seriesIndex].name
        } <span class="pl-medium text-fg-subtle" style="font-family: 'Geist Mono Variable'">  ${
          seriesInfo[seriesIndex].data
        } </span> </div>`;
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    responsive: [
      {
        options: {},
      },
    ],
  };
};

const updateMostCommonWords = () => {
  if (!reports.value) return;
  mostCommonWords.value = Object.entries(
    reports.value.topics_report.popular_topics
  ).map((value) => {
    return {
      word: value[0],
      count: value[1],
    };
  });
};

const updateMostCommonRequests = () => {
  if (!reports.value) return;
  mostCommonRequests.value = reports.value.requests_report.most_common_requests;
};

const updateDetectionsChart = () => {
  if (!reports.value) return;

  const detections = [];

  reports.value.states_report.states_stats.forEach((stat) => {
    const detector = useChatDetails().chatDetectors.defaultDetectors.find(
      (detectorName) => stat.state === detectorName
    );

    if (!detector) return;

    detections.push({
      label: getDetectorTitle(detector),
      count: stat.detected_count,
    });
  });

  detectionStatsSeries.value = detections
    .filter((detection) => detection.count > 0)
    .map((detection) => detection.count);
  detectionStatsOptions.value = {
    labels: detections.map((detection) => detection.label),

    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },

    tooltip: {
      enabled: true,

      custom: function ({ seriesIndex, w }) {
        const seriesInfo = w.globals.seriesNames.map((name, index) => ({
          name,
          index,
          data: w.globals.series[index],
        }));
        return `<div class="text-[13px] leading-5 text-fg-base bg-white py-medium px-large border-[1px] border-base rounded-small" >       <div style="background-color: ${
          w.globals.colors[seriesInfo[seriesIndex].index]
        }" class="w-[8px] h-[8px] inline-block mr-[6px] rounded-full-round"></div> ${
          seriesInfo[seriesIndex].name
        } <span class="pl-medium text-fg-subtle" style="font-family: 'Geist Mono Variable'">  ${
          seriesInfo[seriesIndex].data
        } </span> </div>`;
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    responsive: [
      {
        options: {},
      },
    ],
  };
};

const updateDigitalReport = () => {
  if (!reports.value) return;
  detectedCount.value = reports.value.states_report.states_stats.reduce(
    (acc, stat) =>
      useChatDetails().chatDetectors.defaultDetectors.includes(stat.state)
        ? acc + stat.detected_count
        : acc,
    0
  );
  totalRequests.value = reports.value.states_report.states_stats.reduce(
    (acc, stat) => (acc = acc + Number(stat.detected_count)),
    0
  );
  percentSuccessRequests.value = reports.value.states_report.states_stats.find(
    (stat) => stat.state === "success"
  )
    ? Math.floor(
        (100 *
          reports.value.states_report.states_stats.find(
            (stat) => stat.state === "success"
          ).detected_count) /
          totalRequests.value
      )
    : 0;
};

const updateChartPiiType = () => {
  if (!reports.value) return;

  const piiTypeStats = reports.value.pii_report.pii_type_stats.map((stat) => ({
    label: stat.pii_type,
    count: stat.detected_count,
  }));

  (piiTypeStatsChartSeries.value = piiTypeStats
    .filter((stat) => stat.count > 0)
    .map((stat) => stat.count)),
    (piiTypeStatsChartOptions.value = {
      labels: piiTypeStats.map((stat) => stat.label),

      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },

      tooltip: {
        enabled: true,

        custom: function ({ seriesIndex, w }) {
          const seriesInfo = w.globals.seriesNames.map((name, index) => ({
            name,
            index,
            data: w.globals.series[index],
          }));
          return `<div class="text-[13px] leading-5 text-fg-base bg-white py-medium px-large border-[1px] border-base rounded-small" >       <div style="background-color: ${
            w.globals.colors[seriesInfo[seriesIndex].index]
          }" class="w-[8px] h-[8px] inline-block mr-[6px] rounded-full-round"></div> ${
            seriesInfo[seriesIndex].name
          } <span class="pl-medium text-fg-subtle" style="font-family: 'Geist Mono Variable'">  ${
            seriesInfo[seriesIndex].data
          } </span> </div>`;
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: { show: false },
      responsive: [
        {
          options: {},
        },
      ],
    });
};

const updateNumberOfRequestsPerLlm = (days: number) => {
  if (!reports.value?.llm_report?.stats) return;

  const dates = [...Array(days)].map((value, i) =>
    new Date(Date.now() - i * 86400000).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  );
  const uiDiagramDates = [...Array(days)].map((value, i) =>
    new Date(Date.now() - i * 86400000).toLocaleDateString("en-US", {
      month: "long",
      day: "2-digit",
    })
  );

  let numberOfRequestsPerLlm = reports.value.llm_report.stats.map((stat) => {
    const requestsCountByLlm = {
      llm: stat.llm_type,
      counts: [],
    };
    console.log(reports.value);

    for (const date of dates) {
      const currentDates = stat.request_dates[date] ?? 0;
      console.log(stat.request_dates);
      requestsCountByLlm.counts.unshift(currentDates);
    }

    return requestsCountByLlm;
  });

  const datasets = numberOfRequestsPerLlm.map((numReqPerLlmItem) => {
    const llmMapping = {
      chatgpt: "ChatGPT",
      gemini: "Gemini",
      claude: "Claude",
      llama2: "Llama2",
    };

    const llmNameConverted = llmMapping[numReqPerLlmItem.llm] || "Unknown";

    return {
      name: llmNameConverted,
      data: numReqPerLlmItem.counts,
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let customLegendFormatter = (seriesName: string, opts: any) => {
    const arrSum = (arr: number[]) =>
      arr.reduce((a: number, b: number) => a + b, 0);
    return `
        <div class="inline-block flex gap-medium leading-5">
        ${seriesName}
        <span style="font-family: 'Geist Mono Variable'">
        ${arrSum(opts.w.globals.series[opts.seriesIndex])}
        </span>
        </div>
    `;
  };

  numberOfRequesstsPerLLMSeries.value = datasets;
  lineChartsOptions.value = {
    chart: {
      toolbar: {
        show: true,
      },
      height: 280,
      type: "line",
      zoom: {
        enabled: false,
      },
    },

    stroke: {
      curve: "smooth",
    },
    colors: ["#2DA44E", "#218BFF", "#7870FF", "#A475F9"],

    grid: {
      borderColor: "#eeeff3",
      strokeDashArray: 4,
      row: {
        colors: ["#fff"],
      },
    },
    xaxis: {
      categories: uiDiagramDates.reverse(),
      lines: {
        show: true,
      },

      labels: {
        style: {
          colors: "#959AB1",
          fontSize: "12px",
          fontFamily: "Geist Mono Variable",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#959AB1",
          fontSize: "12px",
          fontFamily: "Geist Mono Variable",
        },
      },
    },

    tooltip: {
      followCursor: true,

      custom: function ({ dataPointIndex, w }) {
        const seriesInfo = w.globals.seriesNames.map((name, index) => ({
          name,
          index,
          data: w.globals.series[index],
        }));

        const htmlContent = seriesInfo
          .map(
            (info) =>
              `
    <div class="px-xl flex items-center justify-between">
        <div class="flex gap-[6px] items-center text-[12px] text-fg-base leading-5">
          <div style="background-color: ${
            w.globals.colors[info.index]
          }" class="w-[8px] h-[8px] rounded-full-round"></div>
          ${info.name}
        </div>
        <div style="font-family: 'Geist Mono Variable'" class="text-[13px] text-fg-subtle leading-5">${
          info.data[dataPointIndex]
        }</div>
    </div>
  `
          )
          .join(" ");

        return `
    <div class="min-w-[228px] border-[1px] border-muted rounded-small">
      <div style="font-family: 'Geist Mono Variable'" class="text-[12px] font-normal leading-5 text-fg-muted py-xs px-xl bg-subtle border-muted border-b">${w.globals.categoryLabels[dataPointIndex]}</div>
      <div class="flex flex-col gap-xs py-medium">
        ${htmlContent}
      </div>
    </div>`;
      },
    },

    legend: {
      fontSize: "13px",
      fontFamily: "Geist Mono Variable",
      show: true,
      position: "top",
      horizontalAlign: "left",
      formatter: customLegendFormatter,
      itemMargin: {
        horizontal: 0,
        vertical: 0,
      },
    },
  };
};

onMounted(() => {
  setAllReportsFor();
  updateReports();
  updateDetectionsChart();
  updatePiiActionChart();
  updateDigitalReport();
  updateChartPiiType();
  updateMostCommonWords();
  updateMostCommonRequests();
  updateNumberOfRequestsPerLlm(selectedDays.value);
});

watch(reports, () => {
  updateDetectionsChart();
  updatePiiActionChart();
  updateDigitalReport();
  updateChartPiiType();
  updateMostCommonWords();
  updateMostCommonRequests();
  updateNumberOfRequestsPerLlm(selectedDays.value);
});
</script>

<template>
  <section class="py-4xl px-6xl">
    <div class="flex flex-wrap gap-xl pb-xl">
      <div
        class="flex-1 p-4xl items-center border-[1px] border-base rounded-small text-[13px] leading-5 font-medium text-fg-base"
      >
        <span class="pb-xs"> Number of requests:</span>
        <br />
        <span
          style="font-family: 'Geist Mono Variable'"
          class="text-[24px] leading-[26px]"
        >
          {{ totalRequests }}
        </span>
      </div>

      <div
        class="flex-1 p-4xl items-center border-[1px] border-base rounded-small text-[13px] leading-5 font-medium text-fg-base"
      >
        <span class="pb-xs"> Number of detections:</span><br />
        <span
          style="font-family: 'Geist Mono Variable'"
          class="text-[24px] leading-[26px]"
        >
          {{ detectedCount }}
        </span>
      </div>
      <div
        class="flex-1 p-4xl items-center border-[1px] border-base rounded-small text-[13px] leading-5 font-medium text-fg-base"
      >
        <span class="pb-xs">Percentage of successful requests:</span><br />
        <span
          style="font-family: 'Geist Mono Variable'"
          class="text-[24px] leading-[26px]"
        >
          {{ percentSuccessRequests }}%
        </span>
      </div>

      <div class="flex-1 flex items-center">
        <SelectRoot v-model="selectedReportsFor">
          <SelectTrigger size="small" />

          <SelectPortal>
            <SelectContent :side-offset="5" side="bottom" position="popper">
              <SelectViewport class="p-[5px]">
                <SelectGroup>
                  <SelectItem :items="allReportsFor" value="any" />
                </SelectGroup>
              </SelectViewport>
            </SelectContent>
          </SelectPortal>
        </SelectRoot>
      </div>
    </div>

    <NumberOfRequestPerLLM
      :selectedReportsFor="selectedReportsFor"
      :selectDays="selectDays"
      :selectedDays="selectedDays"
      :line-charts-options="lineChartsOptions"
      :numberOfRequesstsPerLLMSeries="numberOfRequesstsPerLLMSeries"
    />

    <div>
      <div class="w-full flex gap-xl pb-xl">
        <div
          class="flex-1 max-w-[33%] p-xl border-[1px] border-base rounded-small"
        >
          <h2 class="text-[13px] font-medium leading-5 text-fg-base">
            Detection Stats
          </h2>

          <div
            v-if="detectionStatsSeries.length !== 0"
            class="min-h-[170px] min-w-[170px] flex items-center py-[38px] px-[66px]"
          >
            <apexchart
              type="donut"
              height="170"
              :options="detectionStatsOptions"
              :series="detectionStatsSeries"
            />
          </div>
          <div class="flex items-center justify-center h-[352px]" v-else>
            <div class="flex flex-col items-center">
              <Icon
                styles="pb-medium min-w-[30px] min-h-[40px] max-h-[40px] max-w-[30px]"
                name="GraphEmptyData"
              />
              <p class="text-fg-base text-[13px] leading-5">No data yet</p>
            </div>
          </div>
        </div>
        <div
          class="flex-1 max-w-[33%] p-xl border-[1px] border-base rounded-small"
        >
          <h2 class="text-[13px] font-medium leading-5 text-fg-base">
            PII Action Stats
          </h2>

          <div
            v-if="detectionStatsSeries.length !== 0"
            class="min-h-[170px] min-w-[170px] flex items-center py-[38px] px-[66px]"
          >
            <apexchart
              type="donut"
              height="170"
              :options="piiActionChartOptions"
              :series="piiActionChartSeries"
            />
          </div>

          <div class="flex items-center justify-center h-[352px]" v-else>
            <div class="flex flex-col items-center">
              <Icon
                styles="pb-medium min-w-[30px] min-h-[40px] max-h-[40px] max-w-[30px]"
                name="GraphEmptyData"
              />
              <p class="text-fg-base text-[13px] leading-5">No data yet</p>
            </div>
          </div>
        </div>
        <div
          class="p-xl flex-1 max-w-[33%] border-[1px] border-base rounded-small"
        >
          <h2 class="text-[13px] font-medium leading-5 text-fg-base">
            PII Type Stats
          </h2>

          <div
            v-if="detectionStatsSeries.length !== 0"
            class="min-h-[170px] min-w-[170px] flex items-center py-[38px] px-[66px]"
          >
            <apexchart
              type="donut"
              height="170"
              :options="detectionStatsOptions"
              :series="detectionStatsSeries"
            />
          </div>

          <div class="flex items-center justify-center h-[352px]" v-else>
            <div class="flex flex-col items-center">
              <Icon
                styles="pb-medium min-w-[30px] min-h-[40px] max-h-[40px] max-w-[30px]"
                name="GraphEmptyData"
              />
              <p class="text-fg-base text-[13px] leading-5">No data yet</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-xl">
        <div
          class="flex-1 border-[1px] border-base rounded-small bg-white py-4xl px-4xl"
        >
          <h2 class="text-[13px] font-medium leading-5 text-fg-base pb-medium">
            Most Common Requests
          </h2>

          <ol
            v-if="mostCommonRequests.length !== 0"
            class="flex flex-col gap-[11px]"
          >
            <li
              v-for="(item, index) in mostCommonRequests"
              :key="index"
              :data-value="index + 1"
              class="py-xs px-xl bg-[#ECEBFF] rounded-xs"
            >
              {{ item }}
            </li>
          </ol>

          <div class="flex items-center justify-center h-[352px]" v-else>
            <div class="flex flex-col items-center">
              <Icon
                styles="pb-medium min-w-[30px] min-h-[40px] max-h-[40px] max-w-[30px]"
                name="GraphEmptyData"
              />
              <p class="text-fg-base text-[13px] leading-5">No data yet</p>
            </div>
          </div>
        </div>

        <div
          class="flex-1 border-[1px] border-base rounded-small bg-white py-4xl px-4xl"
        >
          <h2
            class="flex justify-between text-[13px] font-medium leading-5 text-fg-base pb-medium"
          >
            Most Common Words <span class="text-fg-muted">Count</span>
          </h2>

          <ol
            v-if="mostCommonWords.length !== 0"
            class="flex flex-col gap-[11px]"
          >
            <div
              v-for="(item, index) in mostCommonWords"
              :key="index"
              :data-value="index + 1"
              class="flex gap-xl items-center"
            >
              <li class="flex-1 py-xs px-xl bg-[#ECEBFF] rounded-xs">
                {{ item.word }}
              </li>
              <li>
                {{ item.count }}
              </li>
            </div>
          </ol>

          <div class="flex items-center justify-center h-[352px]" v-else>
            <div class="flex flex-col items-center">
              <Icon
                styles="pb-medium min-w-[30px] min-h-[40px] max-h-[40px] max-w-[30px]"
                name="GraphEmptyData"
              />
              <p class="text-fg-base text-[13px] leading-5">No data yet</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
