<script setup lang="ts">
import { computed, defineProps } from "vue";
import { RouterLink, useRoute } from "vue-router";
import { cn } from "@/utils/utils";

import Icon from "../icons/Icon.vue";
import { type ILinks } from "../layout/sidebar/Sidebar.vue";
const props = defineProps<{
  link: ILinks;
}>();

const route = useRoute();
const isActive = computed(() => route.path === props.link.to);
</script>

<template>
  <li :id="link.linkId" class="">
    <RouterLink
      :to="link.to"
      :class="
        cn(
          'flex flex-row items-center w-[250px] text-fg-subtle transition gap-small py-small pl-xl pr-4xl rounded-small border-[1px] border-transparent  hover:bg-[#EEEFF3] hover:text-fg-subtle',
          isActive
            ? 'shadow-active-route stroke-[#7870FF] border-base text-brand bg-base hover:bg-base hover:text-brand  '
            : 'stroke-[#666C89]'
        )
      "
    >
      <Icon styles="inline" :name="link.iconName" />
      <span class="text-[14px] font-medium leading-5">
        {{ link.title }}
      </span>
    </RouterLink>
  </li>
</template>
