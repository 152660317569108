<script setup lang="ts">
import { defineProps } from "vue";

import { toast } from "vue3-toastify";

import { type IApiKey } from "@/types/apiKey";

import { backend } from "@/main";
import { useAuthStore } from "@/stores/AuthStore";

import Code from "@/components/ui/Code.vue";
import Icon from "@/components/ui/icons/Icon.vue";

const authStore = useAuthStore();

const props = defineProps<{
  apiKey: IApiKey;
  index: number;
  refresh: () => void;
}>();

const deleteAPIKey = async () => {
  if (props.apiKey.key_name) {
    try {
      await backend.post("/api_key/delete", {
        uid: authStore.user.uid,
        name: props.apiKey.key_name,
      });

      props.refresh();
      toast("API Key deleted");
    } catch (error) {
      console.log("Error deleting API key", error);
      toast.error("Error deleting API key", { autoClose: 500 });
    }
  }
};
</script>
<template>
  <div
    class="text-fg-base text-[14px] leading-5 font-normal flex flex-row items-center justify-between relative min-w-[1056px] bg-white border-b border-b-muted"
  >
    <div
      class="pl-2xl pr-medium py-medium w-[43px]"
      style="font-family: Geist Mono Variable"
    >
      {{ index + 1 }}
    </div>

    <div class="px-medium flex-1 max-w-[693px]">
      {{ apiKey.key_name }}
    </div>
    <div class="px-medium flex-1 max-w-[101px]">
      <Code>
        {{ apiKey.short_key_value }}
      </Code>
    </div>
    <div
      class="px-medium flex-1 max-w-[205px] text-right text-ellipsis"
      style="font-family: Geist Mono Variable"
    >
      {{ apiKey.created_at }}
    </div>

    <div
      @click="deleteAPIKey"
      class="px-medium flex-1 max-w-[46px] text-right cursor-pointer"
    >
      <Icon
        styles="stroke-danger min-h-[20px] min-w-[20px] max-w-[20px] max-h-[20px] "
        name="TrashBin"
      />
    </div>
  </div>
</template>
