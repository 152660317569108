<script setup lang="ts">
import { ref, defineProps } from "vue";
import { useAuthStore } from "@/stores/AuthStore";

import { cn } from "@/utils/utils";

const authStore = useAuthStore();

defineProps<{ classes?: string }>();

const userInitial = ref(
  (authStore.user.name ? authStore.user.name : authStore.user.email)
    .split("@")[0]
    .charAt(0)
    .toUpperCase()
);
</script>

<template>
  <div class="bg-[#E2E3E9] rounded-full-round p-[1px]">
    <div class="bg-white rounded-full-round p-[1px]">
      <div
        :class="
          cn(
            'rounded-full-round text-[14px] text-black leading-5 font-medium bg-[#E2E3E9] py-[5px] px-[10px]',
            classes
          )
        "
      >
        {{ userInitial }}
      </div>
    </div>
  </div>
</template>
