import { cva } from "class-variance-authority";
export { default as Separtor } from "./Separator.vue";

export const separatorVariants = cva("h-[1px]", {
  variants: {
    intent: {
      base: "bg-[#E2E3E9]",
      muted: "bg-[#EEEFF3]",
    },
  },
});
