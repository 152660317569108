<script setup lang="ts">
import { reactive, computed, ref, InputHTMLAttributes } from "vue";

import { useRoute } from "vue-router";
import router from "@/router";

import { useAuthStore } from "@/stores/AuthStore";
import { useForm } from "vee-validate";
import { isLowerCase, isNumber, isUpperCase } from "@/validations/auth/utils";
import { toTypedSchema } from "@vee-validate/zod";
import { RecoverPasswordSchema } from "@/validations/auth/RecoverPasswordSchema";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import AuthLayout from "@/components/auth/AuthLayout.vue";
import PasswordScore from "@/components/auth/PasswordScore.vue";

import Input from "@/components/ui/input/Input.vue";
import Button from "@/components/ui/button/Button.vue";
import Icon from "@/components/ui/icons/Icon.vue";

const { values, setErrors, handleSubmit } = useForm({
  validationSchema: toTypedSchema(RecoverPasswordSchema),
  initialValues: { password: "", confirmPassword: "" },
});

const isFormSubmitted = ref<boolean>(false);

const passwordVisibility = reactive<{ type: InputHTMLAttributes["type"] }>({
  type: "password",
});
const confirmPasswordVisibility = reactive<{
  type: InputHTMLAttributes["type"];
}>({
  type: "password",
});
const isPasswordScoreShowed = ref<boolean>(false);

const showPasswordScore = (event: FocusEvent) => {
  if (event) isPasswordScoreShowed.value = true;
};

const minLength = 8;
const passwordValidations = computed(() => [
  {
    isValid: values.password.length >= minLength,
    message: "At least 8 characters long",
  },
  {
    isValid: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(values.password),
    message: "At least one special character",
  },
  {
    isValid: isLowerCase(values.password),
    message: "At least one lowercase letter",
  },
  {
    isValid: isUpperCase(values.password),
    message: "At least one uppercase letter",
  },
  {
    isValid: isNumber(values.password),
    message: "At least one number",
  },
]);

const onTogglePasswordVisibility = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (target.id === "password")
    passwordVisibility.type =
      passwordVisibility.type === "password" ? "text" : "password";
  else if (target.id === "confirmPassword")
    confirmPasswordVisibility.type =
      confirmPasswordVisibility.type === "password" ? "text" : "password";
};

const route = useRoute();
const oobCode = String(route.query.oobCode);
const authStore = useAuthStore();

const onSubmit = handleSubmit(async (values) => {
  if (values.password !== values.confirmPassword)
    return setErrors({
      password: "Password must match",
      confirmPassword: "Password must match",
    });

  for (let i = 0; i < passwordValidations.value.length; i++)
    if (!passwordValidations.value[i].isValid)
      return setErrors({
        password: "Password must satisfy criteria:",
        confirmPassword: "Password must satisfy criteria.",
      });

  try {
    const result = await authStore.saveResetPassword(values.password, oobCode);
    if (result) {
      toast.success("Password reset successful");
    } else {
      toast.error("Password reset failed");
    }
  } catch (error) {
    toast.error("Failed to sign up", { autoClose: 500 });
  }
  setTimeout(() => {
    router.push("/login");
  }, 1000);
});
</script>

<template>
  <AuthLayout title="Forgot Password">
    <template #header />

    <template #body>
      <form @submit.prevent="onSubmit" class="flex flex-col gap-xl">
        <div class="flex gap-small flex-col">
          <Input
            @onDisplayPasswordScore="showPasswordScore"
            labelText="Password"
            id="password"
            :type="passwordVisibility.type"
            placeholder="Enter your password "
            name="password"
            intent="default"
            size="large"
          >
            <template v-if="passwordVisibility.type === 'password'" #iconRight>
              <Icon
                id="password"
                @click="onTogglePasswordVisibility"
                styles="cursor-pointer stroke-[#666C89]"
                name="OpenEye"
              />
            </template>

            <template v-else #iconRight>
              <Icon
                id="password"
                @click="onTogglePasswordVisibility"
                styles="cursor-pointer"
                name="ClosedEye"
              />
            </template>
          </Input>

          <PasswordScore
            v-if="isPasswordScoreShowed"
            :passwordValidations="passwordValidations"
            :passwordValue="values.password"
          />
        </div>

        <Input
          labelText="Confirm Password"
          id="confirmPassword"
          :type="confirmPasswordVisibility.type"
          placeholder="Enter your password "
          name="confirmPassword"
          intent="default"
          size="large"
        >
          <template
            v-if="confirmPasswordVisibility.type === 'password'"
            #iconRight
          >
            <Icon
              id="confirmPassword"
              @click="onTogglePasswordVisibility"
              styles="cursor-pointer stroke-[#666C89]"
              name="OpenEye"
            />
          </template>

          <template v-else #iconRight>
            <Icon
              id="confirmPassword"
              @click="onTogglePasswordVisibility"
              styles="cursor-pointer"
              name="ClosedEye"
            />
          </template>
        </Input>

        <div class="w-full flex flex-col gap-2xl">
          <Button intent="primary" size="large">
            <template v-if="isFormSubmitted">
              <Icon name="LoadingSpinner" />
            </template>

            <template v-else> Submit</template>
          </Button>
        </div>
      </form>
    </template>
  </AuthLayout>
</template>
