<script setup lang="ts">
// to Refer how to use/modify Radix Popover check radix-vue documentation
import { PopoverPortal, PopoverRoot } from "radix-vue";

import { useAuthStore } from "@/stores/AuthStore";

import FlyoutTrigger from "@/components/ui/flyout/shared/FlyoutTrigger.vue";
import FlyoutContent from "@/components/ui/flyout/shared/FlyoutContent.vue";

import AvatarIcon from "@/components/ui/avatar/AvatarIcon.vue";
import Icon from "@/components/ui/icons/Icon.vue";
import FlyoutContentBlock from "../../flyout/shared/FlyoutContentBlock.vue";
import Separator from "../../separator/Separator.vue";

const authStore = useAuthStore();
const { email: userEmail, name: userName } = authStore.user;

const onLogout = async () => {
  authStore.logout();
  window.location.reload();
};
</script>
<template>
  <footer
    class="flex flex-column gap-1 p-large"
    style="position: sticky; left: 0; bottom: 0"
  >
    <PopoverRoot>
      <!-- Visible Default Part -->
      <FlyoutTrigger>
        <AvatarIcon />
        <p class="text-[14px] font-mediuml leading-5 text-black">
          {{ userName }}
        </p>
      </FlyoutTrigger>

      <!-- After clicking what popsup -->
      <PopoverPortal>
        <FlyoutContent side="top" :side-offset="6" class="">
          <FlyoutContentBlock styles="gap-large hover:bg-transparent">
            <AvatarIcon />
            <div class="">
              <h2 class="text-fg-base font-medium">{{ userName }}</h2>
              <p class="text-fg-subtle font-normal">{{ userEmail }}</p>
            </div>
          </FlyoutContentBlock>

          <Separator intent="base" />

          <RouterLink class="hover:text-black" to="/account">
            <FlyoutContentBlock styles="gap-small">
              <Icon
                name="Gear"
                styles="stroke-[#666C89] min-h-[20px] min-w-[20px]"
              />
              <p class="font-normal">Account Settings</p>
            </FlyoutContentBlock>
          </RouterLink>

          <Separator intent="base" />

          <div @click="onLogout" class="cursor-pointer">
            <FlyoutContentBlock styles="gap-small">
              <Icon name="Logout" styles="stroke-[#666C89]" />
              <p class="font-normal">Logout</p>
            </FlyoutContentBlock>
          </div>
        </FlyoutContent>
      </PopoverPortal>
    </PopoverRoot>
  </footer>
</template>
