import { z } from "zod";

export const emailSchema = z
  .string({
    required_error: "Email is required",
    invalid_type_error: "Email must be a string",
  })
  .min(1, { message: "Email address is required" })
  .email("The email address is invalid.")
  .trim();

export const passwordSchema = z
  .string({
    required_error: "Password is required",
    invalid_type_error: "Password must be a string",
  })
  .min(8, {
    message: "Password should be at least 8 characters",
  })
  .trim();

export const nameSchema = z
  .string({
    required_error: "Name is required",
    invalid_type_error: "Name must be a string",
  })
  .min(2, { message: "Name should be at least 2 characters" })
  .trim();

export const isAlphabetic = (text: string) => /^[a-zA-Z\s]+$/.test(text);

export const isLowerCase = (text: string) => /[a-z]/.test(text);

export const isUpperCase = (text: string) => /[A-Z]/.test(text);

export const isNumber = (text: string) => /\d/.test(text);
