import { defineStore } from "pinia";
import { auth } from "@/firebase/config";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onIdTokenChanged,
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { ref } from "vue";
import router from "@/router";
import { backend } from "@/main";
import { toast } from "vue3-toastify";
import { useChatStore } from "@/stores/ChatStore";
interface User {
  uid: string;
  email: string;
  token: string | null;
  emailVerified: boolean | null;
  name: string;
}
export const useAuthStore = defineStore("authStore", () => {
  const user = ref(null as User | null);
  const authIsReady = ref(false);
  const returnUrl = ref("");
  const isBanned = ref(null);
  const socketRef = ref(null);
  const setSocket = (socket) => {
    socketRef.value = socket;
  };
  const init = () => {
    return new Promise((resolve) => {
      const unsubscribe = onIdTokenChanged(auth, async (userDetails) => {
        if (userDetails) {
          userDetails.getIdToken().then(async (idToken) => {
            const name = await getUserName(userDetails.uid, idToken);
            user.value = {
              email: userDetails.email,
              uid: userDetails.uid,
              token: idToken,
              emailVerified: userDetails.emailVerified,
              name: name,
            };
            authIsReady.value = true;
            resolve(userDetails);
            unsubscribe();
          });
        } else {
          user.value = null;
          authIsReady.value = true;
          resolve(null);
          unsubscribe(); //Unsubscribe after initial check
        }
      });
    });
  };

  const getUserRole = async () => {
    const uid = user.value.uid;
    const response = await backend.get(`/user/role/${uid}`);
    isBanned.value = response.data.role === 1; // BANNED = 1
    return response.data.role;
  };
  const saveResetPassword = async (newPassword: string, oobCode: string) => {
    try {
      const response = await backend.get(
        `/auth/reset_password/${oobCode}/${newPassword}`
      );
      return response.status == 200;
    } catch (error) {
      console.error("Error resetting password:", error);
      return false;
    }
  };
  const getUserName = async (uid: string, token: string) => {
    try {
      const response = await backend.get(`/user/name/${uid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.name;
    } catch (error) {
      console.error("Failed to get user name:", error);
      return;
    }
  };

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(
        "Password reset email has been sent. Please check your email inbox.",
        {
          autoClose: 5000,
        }
      );
    } catch (error) {
      toast.error(error.message.replace("Firebase: ", ""), {
        autoClose: 5000,
      });
    }
  };

  const resendVerificationEmail = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        toast.warn(
          "An email confirmation message has been sent to you. Please check your email.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      } else {
        throw new Error("No user is currently logged in.");
      }
    } catch (error) {
      toast.error(error.message.replace("Firebase: ", ""), {
        autoClose: 500,
      });
    }
  };

  const signup = async (credentials: {
    email: string;
    password: string;
    name: string;
  }) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );

      try {
        await sendEmailVerification(userCredential.user);
      } catch (error) {
        toast.error(error.message.replace("Firebase: ", ""), {
          autoClose: 500,
        });
      }

      toast.warn(
        "An email confirmation message has been sent to you. Please check your email.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );

      await backend.post("/user/register", {
        email: userCredential.user.email,
        uid: userCredential.user.uid,
        name: credentials.name,
      });

      const interval = setInterval(async () => {
        await userCredential.user.reload();
        if (!userCredential.user.emailVerified) return;

        clearInterval(interval);

        try {
          const idToken = await auth.currentUser.getIdToken(
            /* forceRefresh */ true
          );
          const name = await getUserName(userCredential.user.uid, idToken);
          user.value = {
            email: userCredential.user.email,
            uid: userCredential.user.uid,
            token: idToken,
            emailVerified: userCredential.user.emailVerified,
            name: name,
          };

          await backend.put(
            `/user/role/${userCredential.user.uid}/promote_verified_role`
          );

          router.push(returnUrl.value || "/");
        } catch (error) {
          toast.error(error.message.replace("Firebase: ", ""), {
            autoClose: 500,
          });
        }
      }, 400);
    } catch (error) {
      toast.error(error.message.replace("Firebase: ", ""), { autoClose: 500 });
    }
  };

  const login = async (credentials: { email: string; password: string }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );
      if (!userCredential.user.emailVerified) {
        toast.warn(
          "An email confirmation message has been sent to you. Please check your email.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        return;
      }
      if (isBanned.value) {
        toast.warn("Please, contact at help@zenguard.ai to request access", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      if (process.env.NODE_ENV === "development") {
        const response = await backend.get(
          `/user/user_exists/${userCredential.user.uid}`
        );
        if (response.status === 200 && response?.data === false) {
          await backend.post("/user/register", {
            email: userCredential.user.email,
            uid: userCredential.user.uid,
          });
        }
      }
      router.push(returnUrl.value || "/");
    } catch (error) {
      if (error?.response?.status === 500) {
        toast.error("Internal server error");
        return;
      }
      throw Error(error);
    }
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        useChatStore().clearChat();
        if (socketRef.value) {
          socketRef.value.disconnect();
        }
        console.log("logout success");
      })
      .catch((error) => {
        toast.error(error.message.replace("Firebase: ", ""), {
          autoClose: 500,
        });
      });
  };
  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const { isNewUser } = getAdditionalUserInfo(result);
      if (isNewUser) {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const googleUserProfile = await fetch(
          `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${token}`
        );
        const userData = await googleUserProfile.json();
        const firstName = userData.given_name;
        await backend.post("/user/register", {
          email: result.user.email,
          uid: result.user.uid,
          name: firstName,
        });
        const idToken = await auth.currentUser.getIdToken(
          /* forceRefresh */ true
        );
        const name = await getUserName(result.user.uid, idToken);
        user.value = {
          email: result.user.email,
          uid: result.user.uid,
          token: idToken,
          emailVerified: result.user.emailVerified,
          name: name,
        };
        await backend.put(
          `/user/role/${result.user.uid}/promote_verified_role`
        );
      }
      if (isBanned.value) {
        toast.warn("Please, contact at help@zenguard.ai to request access", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      router.push(returnUrl.value || "/");
    } catch (error) {
      toast.error(error.message.replace("Firebase: ", "")), { autoClose: 500 };
    }
  };

  return {
    signup,
    login,
    logout,
    user,
    authIsReady,
    init,
    returnUrl,
    signInWithGoogle,
    getUserRole,
    isBanned,
    resetPassword,
    resendVerificationEmail,
    getUserName,
    saveResetPassword,
    setSocket,
  };
});
