<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import { type SelectedLog } from "@/types/logs";

import Icon from "@/components/ui/icons/Icon.vue";
import Button from "@/components/ui/button/Button.vue";

defineProps<{
  openedLog: SelectedLog;
}>();
const emit = defineEmits(["clicked"]);
const handleClick = () => emit("clicked");
</script>
<template>
  <div v-if="openedLog.isLogOpen === true">
    <div
      class="absolute z-20 top-[10px] right-[10px] border-[1px] border-base bg-base w-[552px] h-[97.5vh] rounded-small ani"
    >
      <!-- Drawer Head -->
      <div class="border-b border-b-base">
        <div class="flex items-center justify-between py-2xl px-5xl">
          Details
          <Button :onclick="handleClick" size="small-icon" intent="secondary">
            <Icon name="Cross" />
          </Button>
        </div>
      </div>
      <!-- Drawer Head End-->

      <div
        v-if="'llm' in openedLog.log"
        class="py-4xl px-5xl text-[14px] leading-5 font-normal"
      >
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">LLM</p>
          <p class="text-fg-base flex-1">{{ openedLog.log.llm }}</p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Timestamp</p>
          <p class="text-fg-base flex flex-row items-center gap-xs ffex-1">
            <Icon name="Calendar" />{{ openedLog.log.created_at }}
          </p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Cache Hits</p>
          <p class="text-fg-base flex-1">{{ openedLog.log.cache_hits }}</p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Message</p>
          <p class="text-fg-base flex-1">{{ openedLog.log.message }}</p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Sanitazed</p>
          <p class="text-fg-base flex-1">
            {{
              openedLog.log.sanitized_message.length === 0
                ? "The message was not sanitized"
                : openedLog.log.sanitized_message
            }}
          </p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Warning</p>
          <p class="text-fg-base flex-1">
            {{
              openedLog.log.zenguard_warn.length === 0
                ? "There are no warnings"
                : openedLog.log.zenguard_warn
            }}
          </p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Response</p>
          <p class="text-fg-base flex-1">{{ openedLog.log.llm_output }}</p>
        </div>
      </div>

      <div
        v-if="'response' in openedLog.log"
        class="py-4xl px-5xl text-[14px] leading-5 font-normal"
      >
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Timestamp</p>
          <p class="text-fg-base flex flex-row items-center gap-xs ffex-1">
            <Icon name="Calendar" />{{ openedLog.log.created_at }}
          </p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Cache Hits</p>
          <p class="text-fg-base flex-1">{{ openedLog.log.cache_hits }}</p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Messages</p>
          <p class="text-fg-base flex-1">{{ openedLog.log.messages }}</p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">System Messages</p>
          <p class="text-fg-base flex-1">
            {{ openedLog.log.system_messages }}
          </p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Error</p>
          <p class="text-fg-base flex-1">
            {{
              openedLog.log.error.length === 0
                ? "There are no errors"
                : openedLog.log.error
            }}
          </p>
        </div>
        <div class="flex flex-row items-start py-medium gap-4xl">
          <p class="text-fg-subtle w-[100px]">Response</p>
          <pre class="text-fg-base flex-1">{{
            JSON.stringify(openedLog.log.response, null, 2)
          }}</pre>
        </div>
      </div>
    </div>

    <div
      @click="handleClick"
      class="absolute z-10 top-0 left-0 w-screen h-screen bg-black opacity-[0.05]"
    />
  </div>
</template>
