<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { defineProps, InputHTMLAttributes, defineEmits } from "vue";
import { useField } from "vee-validate";

import { cn } from "@/utils/utils";
import { inputVariants } from "./index";
import type { VariantProps } from "class-variance-authority";

type InputVariants = VariantProps<typeof inputVariants>;

const props = defineProps<{
  labelText?: string;
  captionText?: string;

  errorMessageProps?: string;
  modelValue?: string;
  placeholder: string;
  name: string;
  type: InputHTMLAttributes["type"];
  id: string;

  intent: InputVariants["intent"];
  size: InputVariants["size"];
  styles?: string;
}>();

const { value, errors } = useField(
  () => props.name,
  {
    validateOnValueUpdate: false,
  },
  {
    syncVModel: true,
  }
);

const emit = defineEmits(["onDisplayPasswordScore"]);
emit("onDisplayPasswordScore");
</script>

<template>
  <div class="flex flex-col gap-2xs">
    <label
      :for="id"
      v-if="labelText"
      class="text-[14px] leading-5 font-medium text-fg-base"
      >{{ labelText }}</label
    >

    <div
      :class="
        cn(
          inputVariants({
            intent:
              errors.length > 0 || errorMessageProps ? 'error' : props.intent,
            size: props.size,
          }),
          props.styles
        )
      "
    >
      <div class="flex flex-1 flex-row gap-2xs items-center">
        <slot name="iconleft" />

        <input
          @focus="emit('onDisplayPasswordScore', $event)"
          v-model="value"
          :type="type || 'text'"
          :id="id"
          :placeholder="props.placeholder"
          :class="cn('bg-transparent w-[97%]')"
        />
      </div>
      <slot name="iconRight" />
    </div>

    <div v-if="errors.length > 0">
      <p
        v-for="(error, index) in errors"
        :key="index"
        class="text-danger text-[12px] leading-5"
      >
        {{ error }}
      </p>
      <p v-if="errorMessageProps">{{ errorMessageProps }}</p>
    </div>
    <p v-if="captionText" class="text-fg-muted text-[12px] leading-5">
      {{ captionText }}
    </p>
  </div>
</template>
