<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { defineProps } from "vue";
import { TabsTrigger, type TabsTriggerProps } from "radix-vue";

interface ITabProps extends TabsTriggerProps {
  foo?: string;
}

const props = defineProps<ITabProps>();
</script>

<template>
  <TabsTrigger
    class="bg-[#fff] rounded-small py-xs px-medium h-[45px] text-[14px] leading-5 font-medium text-fg-muted select-none hover:text-fg-subtle hover:bg-subtle data-[state=active]:text-brand outline-none focus-visible:relative transition"
    :value="props.value"
  >
    <slot />
  </TabsTrigger>
</template>
