import { cva } from "class-variance-authority";
export { default as Input } from "./Input.vue";

export const inputVariants = cva(
  "group transition flex flex-row border-[1px] items-center justify-between  rounded-small placeholder:text-[14px] placeholder:leading-5 placeholder:text-fg-muted placeholder:font-normal",
  {
    variants: {
      intent: {
        default:
          "border-base bg-base text-[14px] text-fg-base shadow-input-base leading-5 hover:bg-subtle focus-within:border-brand focus-within:shadow-input-focus",
        error: "border-danger text-danger shadow-input-error",
      },

      size: {
        large: "p-xl",
        medium: "p-medium",
        small: "p-xs",
      },
    },
  }
);
