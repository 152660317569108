<script setup lang="ts">
import { ref } from "vue";

import { cn } from "@/utils/utils";

import Icon from "@/components/ui/icons/Icon.vue";
import { type IconName } from "@/components/ui/icons";

import GeneralView from "@/components/settings/account/GeneralView.vue";
import SecurityView from "@/components/settings/account/SecurityView.vue";

const selectedSection = ref<"General" | "Security">("General");

const nav: { title: string; iconName: IconName; onClick: () => string }[] = [
  {
    title: "General",
    iconName: "Home",
    onClick: () => (selectedSection.value = "General"),
  },
  {
    title: "Security",
    iconName: "Lock",
    onClick: () => (selectedSection.value = "Security"),
  },
];
</script>
<template>
  <div class="py-7xl px-6xl">
    <h2 class="text-fg-base text-[18px] font-medium leading-6 pb-4xl">
      Account Settings
    </h2>

    <section class="flex gap-7xl">
      <div class="flex flex-col gap-2xs">
        <div
          v-for="(item, index) in nav"
          :key="index"
          @click="item.onClick"
          :class="
            cn(
              'flex flex-row items-center w-[250px] text-fg-subtle transition gap-small py-small pl-xl pr-4xl rounded-small border-[1px] border-transparent text-[14px] font-medium leading-5 cursor-pointer hover:bg-[#EEEFF3] hover:text-fg-subtle',
              item.title === selectedSection
                ? 'shadow-active-route stroke-[#7870FF] border-base text-brand bg-base hover:bg-base hover:text-brand'
                : 'stroke-[#666C89]'
            )
          "
        >
          <Icon :name="item.iconName" />
          {{ item.title }}
        </div>
      </div>
      <div v-if="selectedSection === 'General'">
        <GeneralView />
      </div>
      <div v-else>
        <SecurityView />
      </div>
    </section>
  </div>
</template>
