<script setup lang="ts">
import { useAuthStore } from "./stores/AuthStore";
import { onMounted, onUnmounted } from "vue";
import { backend } from "./main";
import {
  useReportsStore,
  UserReportsType,
  PerApiKeyReportsType,
} from "./stores/ReportsStore";
import { toast } from "vue3-toastify";

import Layout from "./components/ui/layout/Layout.vue";

const authStore = useAuthStore();
const reportFetchIntervalTimeout = 3 * 60 * 1000;

function getCachedUserReports(): {
  userReports: UserReportsType;
  perApiKeyReports?: PerApiKeyReportsType;
} | null {
  const cachedData = localStorage.getItem("cachedUserReports");
  return cachedData ? JSON.parse(cachedData) : null;
}

async function cacheUserReports(authStore) {
  if (!authStore.user) return;

  try {
    const apiKeysResponse = await backend.get(
      `/api_key/get/${authStore.user.uid}`
    );
    let reportsResponsesPromises = [];

    if (apiKeysResponse.data.keys && apiKeysResponse.data.keys.length > 0) {
      reportsResponsesPromises = apiKeysResponse.data.keys.map((key) =>
        backend.get(
          `/reports/api_key/${authStore.user.uid}?api_key_id=${key.key_id}`
        )
      );
    }

    reportsResponsesPromises.push(
      backend.get(`/reports/${authStore.user.uid}`)
    );

    const reportsResponses = await Promise.all(reportsResponsesPromises);

    useReportsStore().userReports = reportsResponses.pop().data.report;
    if (reportsResponses.length > 0) {
      reportsResponses.forEach((response, index) => {
        useReportsStore().perApiKeyReports[
          apiKeysResponse.data.keys[index].key_name
        ] = response.data.report;
      });
    }

    localStorage.setItem(
      "cachedUserReports",
      JSON.stringify({
        userReports: useReportsStore().userReports,
        perApiKeyReports: useReportsStore().perApiKeyReports,
      })
    );
  } catch (error) {
    toast("Report fetch failed", { autoClose: 500 });
    console.error(error);
  }
}

let reportFetchIntervalId: number;

onMounted(async () => {
  await authStore.init();
  let cachedReports = getCachedUserReports();
  if (cachedReports) {
    if (cachedReports.userReports)
      useReportsStore().userReports = cachedReports.userReports;
    if (
      cachedReports.perApiKeyReports &&
      Object.keys(cachedReports.perApiKeyReports).length > 0
    ) {
      useReportsStore().perApiKeyReports = cachedReports.perApiKeyReports;
    }
  }
  cacheUserReports(useAuthStore());
  reportFetchIntervalId = setInterval(
    () => cacheUserReports(useAuthStore()),
    reportFetchIntervalTimeout
  );
});

onUnmounted(() => {
  clearInterval(reportFetchIntervalId);
});

const email_verified = (user) => {
  if (user && user.token) {
    return user.emailVerified;
  }
  return false;
};
</script>

<template>
  <div v-if="authStore.authIsReady">
    <div
      v-if="
        authStore.user && email_verified(authStore.user) && !authStore.isBanned
      "
    >
      <Layout />
    </div>

    <RouterView v-else />
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Rubik", sans-serif;
  margin: 0;
}

/* all the links */
a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

h1 {
  padding-top: 1rem;
  font-size: 2rem;
  font-weight: 600;
  /* text-align: center; */
  margin: 0.5rem;
}

.main-column {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
}
/* this sets up the flexible area for the main column */
@media (min-width: 768px) {
  .main-column {
    /* padding-right: 370px; */
    padding-left: 320px;
  }
}

@media (max-width: 768px) {
  .main-column {
    padding-left: 100px;
  }
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
}
.page-details {
  margin: 0.5rem;
  color: #212124;
}
.page-headers {
  color: rgb(33, 37, 41);
  font-size: calc(1.325rem + 0.9vw);
  margin: 0.5rem;
  font-weight: 500;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
}
</style>
