<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { defineEmits } from "vue";
import Icon from "@/components/ui/icons/Icon.vue";

const emit = defineEmits(["clicked"]);

const handleClick = () => emit("clicked");
</script>
<template>
  <div
    class="flex items-center gap-2xs py-xs pl-xl pr-small text-[13px] font-medium leading-5 text-fg-subtle border-base border-[1px] rounded-small bg-subtle"
  >
    <slot />
    <div
      @click="handleClick"
      class="transition rounded-small cursor-pointer hover:bg-[#EEEFF3] active:bg-[#E2E3E9]"
    >
      <Icon name="Cross" />
    </div>
  </div>
</template>
