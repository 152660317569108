<script setup lang="ts">
import { ref, computed, reactive, InputHTMLAttributes } from "vue";

import { useAuthStore } from "@/stores/AuthStore";

import { useForm } from "vee-validate";
import { ZodError } from "zod";
import {
  isLowerCase,
  isNumber,
  isUpperCase,
  emailSchema,
} from "@/validations/auth/utils";
import { newPasswordSchema } from "@/validations/settings/account/ChangePasswordSchema";

import { toast } from "vue3-toastify";

import Icon from "@/components/ui/icons/Icon.vue";
import Input from "@/components/ui/input/Input.vue";
import Button from "@/components/ui/button/Button.vue";
import Separator from "@/components/ui/separator/Separator.vue";
import PasswordScore from "@/components/auth/PasswordScore.vue";
import { toTypedSchema } from "@vee-validate/zod";

const { user } = useAuthStore();

const emailRef = ref<string>(user.email);
const emailErrorMessage = ref<string>("");
const isEmailBtnDisabled = computed(() => emailRef.value === "");
const isEmailBtnEditted = computed(() => emailRef.value === user.email);

const emailSubmit = () => {
  try {
    const parsedNewEmail = emailSchema.parse(emailRef.value);

    const newEmail = parsedNewEmail;

    console.log(newEmail);
    // request to change email goes here...
  } catch (error) {
    console.log(error);
    if (error instanceof ZodError) {
      console.log(error.message);
      emailErrorMessage.value = JSON.parse(error.message)[0].message;
    }
  }
};

const { values, handleSubmit, setErrors } = useForm({
  initialValues: { currentPassword: "", newPassword: "", confirmPassword: "" },
  validationSchema: toTypedSchema(newPasswordSchema),
});

const isPasswordFormEmpty = computed(
  () =>
    values.currentPassword === "" ||
    values.confirmPassword === "" ||
    values.newPassword === ""
);

const isPasswordScoreShowed = ref<boolean>(false);
const showPasswordScore = (event: FocusEvent) => {
  if (event) isPasswordScoreShowed.value = true;
};

const passwordVisibilities = reactive<{
  currentPasswordType: InputHTMLAttributes["type"];
  newPasswordType: InputHTMLAttributes["type"];
  confirmPasswordType: InputHTMLAttributes["type"];
}>({
  currentPasswordType: "password",
  newPasswordType: "password",
  confirmPasswordType: "password",
});

const onTogglePasswordVisibility = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (target.id === "currentPassword")
    passwordVisibilities.currentPasswordType =
      passwordVisibilities.currentPasswordType === "password"
        ? "text"
        : "password";
  else if (target.id === "newPassword")
    passwordVisibilities.newPasswordType =
      passwordVisibilities.newPasswordType === "password" ? "text" : "password";
  else if (target.id === "confirmPassword")
    passwordVisibilities.confirmPasswordType =
      passwordVisibilities.confirmPasswordType === "password"
        ? "text"
        : "password";
};

const minLength = 8;
const passwordValidations = computed(() => [
  {
    isValid: values.newPassword.length >= minLength,
    message: "At least 8 characters long",
  },
  {
    isValid: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(values.newPassword),
    message: "At least one special character",
  },
  {
    isValid: isLowerCase(values.newPassword),
    message: "At least one lowercase letter",
  },
  {
    isValid: isUpperCase(values.newPassword),
    message: "At least one uppercase letter",
  },
  {
    isValid: isNumber(values.newPassword),
    message: "At least one number",
  },
]);

const passwordSubmit = handleSubmit((values) => {
  try {
    console.log(values, "use this to submit");

    if (values.newPassword !== values.confirmPassword)
      return setErrors({
        newPassword: "Password must match",
        confirmPassword: "Password must match",
      });

    for (let i = 0; i < passwordValidations.value.length; i++)
      if (!passwordValidations.value[i].isValid)
        return setErrors({
          newPassword: "Password must satisfy criteria:",
          confirmPassword: "Password must satisfy criteria.",
        });
  } catch (error) {
    console.log(error);
    toast.error("couldnt update password");
  }
});
</script>
<template>
  <section class="min-w-[766px]">
    <div>
      <h2 class="text-[16px] leading-6 font-medium text-fg-base pb-[2px]">
        Email
      </h2>
      <p class="text-[14px] text-fg-subtle font-normal leading-6 pb-xl">
        Please enter your full email address, or an email address you are
        comfortable sharing.
      </p>

      <form @submit.prevent="emailSubmit">
        <fieldset class="mb-xl max-w-[460px]">
          <Input
            v-model="emailRef"
            :errorMessageProps="emailErrorMessage"
            name="emailRef"
            id="emailRefs"
            type="text"
            size="medium"
            intent="default"
            placeholder="Please enter your email"
          />
        </fieldset>

        <Button
          styles="mb-6xl"
          :disabled="isEmailBtnDisabled || isEmailBtnEditted"
          type="submit"
          intent="secondary"
          size="medium"
        >
          Save
        </Button>
      </form>
    </div>
    <Separator styles="my-6xl" intent="muted" />

    <div>
      <h2 class="text-[16px] leading-6 font-medium text-fg-base pb-xl">
        Change Password
      </h2>

      <form @submit.prevent="passwordSubmit">
        <div class="mb-xl flex gap-xl flex-col max-w-[460px]">
          <Input
            labelText="Current Password"
            name="currentPassword"
            id="currentPassword"
            :type="passwordVisibilities.currentPasswordType"
            size="medium"
            intent="default"
            placeholder="Enter current password"
          >
            <template
              #iconRight
              v-if="passwordVisibilities.currentPasswordType === 'password'"
            >
              <Icon
                id="currentPassword"
                @click="onTogglePasswordVisibility"
                styles="cursor-pointer stroke-[#666C89]"
                name="OpenEye"
              />
            </template>

            <template #iconRight v-else>
              <Icon
                id="currentPassword"
                @click="onTogglePasswordVisibility"
                styles="cursor-pointer"
                name="ClosedEye"
              />
            </template>
          </Input>

          <div>
            <Input
              styles="mb-xl"
              @onDisplayPasswordScore="showPasswordScore"
              labelText="New Password"
              name="newPassword"
              id="newPassword"
              :type="passwordVisibilities.newPasswordType"
              size="medium"
              intent="default"
              placeholder="Enter new password"
            >
              <template
                #iconRight
                v-if="passwordVisibilities.newPasswordType === 'password'"
              >
                <Icon
                  id="newPassword"
                  @click="onTogglePasswordVisibility"
                  styles="cursor-pointer stroke-[#666C89]"
                  name="OpenEye"
                />
              </template>

              <template #iconRight v-else>
                <Icon
                  id="newPassword"
                  @click="onTogglePasswordVisibility"
                  styles="cursor-pointer"
                  name="ClosedEye"
                />
              </template>
            </Input>

            <PasswordScore
              v-if="isPasswordScoreShowed"
              :passwordValidations="passwordValidations"
              :passwordValue="values.newPassword"
            />
          </div>

          <Input
            labelText="Confirm New Password"
            name="confirmPassword"
            id="confirmPassword"
            :type="passwordVisibilities.confirmPasswordType"
            size="medium"
            intent="default"
            placeholder="Confirm new password"
          >
            <template
              #iconRight
              v-if="passwordVisibilities.confirmPasswordType === 'password'"
            >
              <Icon
                id="confirmPassword"
                @click="onTogglePasswordVisibility"
                styles="cursor-pointer stroke-[#666C89]"
                name="OpenEye"
              />
            </template>

            <template #iconRight v-else>
              <Icon
                id="confirmPassword"
                @click="onTogglePasswordVisibility"
                styles="cursor-pointer"
                name="ClosedEye"
              />
            </template>
          </Input>
        </div>

        <Button
          styles="mb-6xl"
          :disabled="isPasswordFormEmpty"
          type="button"
          intent="secondary"
          size="medium"
        >
          Save
        </Button>
      </form>
    </div>
  </section>
</template>
