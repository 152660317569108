import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function toSnakeCase(str: string): string {
  // this function is specifically for pii_controls, if you will change pii_controls change this function as well
  return str.toLowerCase().replace(/ /g, "_");
}
export function removeNullKeysFromJson(obj) {
  const stack = [obj];

  while (stack.length > 0) {
    const currentObj = stack.pop();

    for (const key in currentObj) {
      if (currentObj[key] && typeof currentObj[key] === "object") {
        stack.push(currentObj[key]);
      }
      if (currentObj[key] === null) {
        delete currentObj[key];
      }
    }
  }
  return obj;
}
