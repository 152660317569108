<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { ButtonHTMLAttributes, defineProps } from "vue";

import { cn } from "@/utils/utils";

import { type VariantProps } from "class-variance-authority";
import { buttonVariants } from ".";

type ButtonVariants = VariantProps<typeof buttonVariants>;

const props = defineProps<{
  intent: ButtonVariants["intent"];
  size: ButtonVariants["size"];
  styles?: string;
  type?: ButtonHTMLAttributes["type"];
  disabled?: ButtonHTMLAttributes["disabled"];
}>();
</script>

<template>
  <button
    :disabled="props.disabled"
    :type="props.type"
    :class="
      cn(
        buttonVariants({ intent: props.intent, size: props.size }),
        props.styles
      )
    "
  >
    <slot name="iconLeft" />

    <slot />

    <slot name="iconRight" />
  </button>
</template>
