<script setup lang="ts">
import { defineProps, reactive } from "vue";

import Icon from "./Icon.vue";
const props = defineProps<{ text: string }>();

const isCopied = reactive<{ value: boolean }>({ value: false });

const copyText = async (text: string, isCopied: { value: boolean }) => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      isCopied.value = true;
      setTimeout(() => {
        isCopied.value = false;
      }, 2000);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  } else {
    console.error("Clipboard API not available");
  }
};
</script>
<template>
  <button
    class="icon-button p-small transition rounded-small cursor-pointer hover:bg-emphasis-hover active:bg-emphasis-pressed"
    :class="{ 'is-copied': isCopied.value }"
    @click="copyText(props.text, isCopied)"
    aria-label="Copy code"
  >
    <Icon v-if="!isCopied.value" class="icon" name="SquareStack" />
    <Icon
      v-if="isCopied.value"
      class="icon"
      styles="stroke-success"
      name="CheckMark"
    />
  </button>
</template>

<style scoped>
.icon-button {
  transition: transform 0.4s ease;
}

.icon-button .icon {
  transition: transform 0.5s ease-in-out;
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  75% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes expand {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.icon-button:active .icon {
  animation: shrink 0.2s forwards;
}

.is-copied .icon {
  animation: expand 0.3s forwards;
}
</style>
