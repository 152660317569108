<script setup lang="ts">
import { computed, ref } from "vue";

import { useRouter } from "vue-router";

import { useForm } from "vee-validate";
import { useAuthStore } from "@/stores/AuthStore";
import { z } from "zod";
import { toTypedSchema } from "@vee-validate/zod";
import { isAlphabetic, nameSchema } from "@/validations/auth/utils";

import { DialogClose, DialogPortal, DialogRoot } from "radix-vue";
import DialogTitle from "@/components/ui/dialog_windows/dialog_type2/DialogTitle.vue";
import DialogContent from "@/components/ui/dialog_windows/dialog_type2/DialogContent.vue";
import DialogOverlay from "@/components/ui/dialog_windows/dialog_type2/DialogOverlay.vue";
import DialogFooter from "@/components/ui/dialog_windows/dialog_type2/DialogFooter.vue";
import DialogBody from "@/components/ui/dialog_windows/dialog_type2/DialogBody.vue";

import { toast } from "vue3-toastify";

import AvatarIcon from "@/components/ui/avatar/AvatarIcon.vue";
import Separator from "@/components/ui/separator/Separator.vue";

import Input from "@/components/ui/input/Input.vue";
import Button from "@/components/ui/button/Button.vue";

const { user } = useAuthStore();

const router = useRouter();

const { values, resetForm, setErrors, handleSubmit } = useForm({
  validationSchema: toTypedSchema(
    z.object({
      name: nameSchema,
    })
  ),
  initialValues: { name: user.name },
});

const isNameInputEmpty = computed(() => values.name === "");
const isNameInputEditted = computed(() => values.name === user.name);

const onSubmitNewName = handleSubmit(async (values) => {
  if (!isAlphabetic(values.name)) {
    return setErrors({ name: "Use only alphabetic letters" });
  }
  console.log(values);
  try {
    // Use values to get data from user submission
    // request go here...
  } catch (error) {
    console.log(error);
    toast.error("enter error message here");
  } finally {
    resetForm();
  }
  console.log(values.name);
  toast("name submitted");
});

const isDeleteAccountDialogOpen = ref<boolean>(false);
const openDeleteAccountDialog = () => (isDeleteAccountDialogOpen.value = true);
const closeDeleteAccountDialog = () =>
  (isDeleteAccountDialogOpen.value = false);

const deleteInputFieldRef = ref<string>("");
const deleteInputFieldError = ref<string>("");

const onDeleteAccount = () => {
  if (deleteInputFieldRef.value === "DELETE") {
    //   then process to delete account
    // add all needed steps to delete here
    toast("Deleted");
    router.push("account-deletion-confirmation");

    // pushes
    closeDeleteAccountDialog();
  } else {
    deleteInputFieldError.value = "Please, Enter DELETE to delete your account";
  }
};
</script>
<template>
  <section>
    <div>
      <h2 class="text-[16px] leading-6 font-medium text-fg-base pb-medium">
        Avatar
      </h2>
      <div class="max-w-[58px] max-h-[58px]">
        <AvatarIcon classes="text-center py-[18px] px-[19px] " />
      </div>
    </div>

    <Separator styles="my-6xl " intent="muted" />

    <div>
      <h2 class="text-[16px] leading-6 font-medium text-fg-base pb-3xs">
        Display Name
      </h2>
      <p class="text-fg-subtle text-[14px] leading-7 font-normal pb-xl">
        Please enter your full name, or a display name you are comfortable with.
      </p>

      <form @submit.prevent="onSubmitNewName">
        <Input
          class="pb-xl max-w-[460px]"
          type="text"
          id="name"
          name="name"
          placeholder="Enter your name"
          size="medium"
          intent="default"
        />
        <Button
          :disabled="isNameInputEmpty || isNameInputEditted"
          type="submit"
          intent="secondary"
          size="medium"
          >Save
        </Button>
      </form>
    </div>

    <Separator styles=" my-6xl" intent="muted" />

    <div>
      <h2 class="text-[16px] leading-6 font-medium text-danger pb-3xs">
        Display Name
      </h2>

      <p class="text-fg-subtle text-[14px] leading-7 font-normal pb-xl">
        Permanently remove your Personal Account and all of its contents. This
        action is not reversible, so please continue with caution.
      </p>

      <DialogRoot :open="isDeleteAccountDialogOpen">
        <DialogPortal>
          <DialogOverlay @click="closeDeleteAccountDialog" />
          <DialogContent>
            <DialogTitle>Delete Account</DialogTitle>

            <form @submit.prevent="onDeleteAccount">
              <DialogBody class="p-5xl">
                <p class="p-2xs">
                  Are you sure you want to delete your account?
                </p>
                <p class="pb-xl">
                  This can not be undone. All your data will be lost and we will
                  cancel all your subscriptions.
                </p>
                <Input
                  v-model="deleteInputFieldRef"
                  labelText="Type DELETE to confirm"
                  :errorMessageProps="deleteInputFieldError"
                  type="text"
                  id="deleteInputFieldRef"
                  name="deleteInputFieldRef"
                  placeholder="Enter DELETE to delete account"
                  size="medium"
                  intent="default"
                />
              </DialogBody>

              <DialogFooter>
                <DialogClose as-child>
                  <Button
                    type="button"
                    @click="closeDeleteAccountDialog"
                    intent="secondary"
                    size="small"
                  >
                    Cancel
                  </Button>
                </DialogClose>

                <DialogClose as-child>
                  <Button type="submit" intent="danger" size="small">
                    Delete
                  </Button>
                </DialogClose>
              </DialogFooter>
            </form>
          </DialogContent>
        </DialogPortal>
      </DialogRoot>

      <Button
        @click="openDeleteAccountDialog"
        type="button"
        size="medium"
        intent="danger"
      >
        Delete Account
      </Button>
    </div>
  </section>
</template>
