<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { defineProps, ref } from "vue";
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from "radix-vue";

import YAML from "yaml";

import { getDetectorTitle } from "@/composables/useDetails";
import Icon from "@/components/ui/icons/Icon.vue";

import { cn } from "@/utils/utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
defineProps<{ accordionItem: any }>();

const detectorsLength = ref(0);

function getDetectorsRespYaml(common_response): string[] {
  const yamlDoc = new YAML.Document();
  yamlDoc.contents = common_response;
  const detectorsRespAsString = yamlDoc.toString().trimEnd();
  const splittedDetectors = detectorsRespAsString.split("\n");
  detectorsLength.value = splittedDetectors.length;
  return splittedDetectors;
}
const isClicked = ref<boolean>(false);
const toggleClick = () => (isClicked.value = !isClicked.value);
</script>

<template>
  <AccordionRoot
    :onclick="toggleClick"
    class="flex flex-col gap-2xs"
    type="single"
    :collapsible="true"
  >
    <template v-if="accordionItem.detector">
      <AccordionItem
        class="w-[682px] py-medium px-large flex flex-col cursor-default items-center justify-between bg-white border-[1px] border-base rounded-small outline-none group ml-7xl overflow-hidden focus-within:relative focus-within:z-10"
        :value="accordionItem.detector"
      >
        <AccordionHeader class="w-full">
          <AccordionTrigger
            class="flex items-center justify-between w-full flex-row"
          >
            <span
              style="font-family: 'Geist Mono Variable'"
              class="flex gap-xs text-[14px] font-medium leading-5"
            >
              <Icon
                :name="
                  accordionItem.common_response.is_detected === true
                    ? 'RedX'
                    : 'CheckMarkFilled'
                "
              />
              <span
                :class="
                  accordionItem.common_response.is_detected === true
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                {{ getDetectorTitle(accordionItem.detector) }}
              </span>
            </span>
            <span class="flex text-[13px] leading-5 font-medium text-brand">
              Details
              <Icon
                :class="cn('', { 'rotate-180': isClicked })"
                name="ShevronPlaygroundChat"
              />
            </span>
          </AccordionTrigger>
        </AccordionHeader>

        <AccordionContent
          class="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp text-[13px] flex items-start w-full pt-xs"
        >
          <div
            class="text-fg-base pl-[26px] pr-medium"
            style="font-family: 'Geist Mono Variable'"
          >
            {{ getDetectorsRespYaml(accordionItem.common_response) }}
          </div>
        </AccordionContent>
      </AccordionItem>
    </template>
  </AccordionRoot>
</template>
