<script setup lang="ts">
import { defineProps, withDefaults } from "vue";
import { SelectContent, type SelectContentProps } from "radix-vue";

import { cn } from "@/utils/utils";

interface ISelectTriggerProps extends SelectContentProps {
  styles?: string;
}
const props = withDefaults(defineProps<ISelectTriggerProps>(), {
  position: "popper",
  side: "bottom",
  sideOffset: 5,
});
// can be extended
</script>

<template>
  <SelectContent
    :class="
      cn(
        'bg-[#fff] border-[1px] border-base rounded-small p-2xs will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-[100]',
        props.styles
      )
    "
    :side-offset="props.sideOffset"
    :side="props.side"
    :position="props.position"
  >
    <slot />
  </SelectContent>
</template>
