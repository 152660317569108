<template>
  <div class="details-skip"></div>
  <div class="details-box">
    <div class="knobs-box border-bottom">
      <p class="details-text">Model</p>
      <select
        class="chat-llm-selector form-select form-select-lg"
        aria-label="Coding LLMs"
        v-model="debugCodeModel"
      >
        <option value="CM starcoder-q4_0">CM starcoder-q4_0</option>
        <option value="CM FauxPilot">CM FauxPilot</option>
        <option value="CM moyix/codegen-16B-multi-gptj">
          CM moyix/codegen-16B-multi-gptj
        </option>
        <option value="CM replit-code-v1_5-3b-q4_0">
          CM replit-code-v1_5-3b-q4_0
        </option>
        <option value="CM rift-coder-v0-7b-q4_0">
          CM rift-coder-v0-7b-q4_0
        </option>
      </select>
    </div>
    <div class="testing-box border-bottom">
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item" id="accordionDebug">
          <h2 class="accordion-header" id="flush-headingOne">
            <button
              class="accordion-button collapsed details-text"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              Debugging
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            AI tool User
            <select class="form-select form-select-md" v-model="debugCodeUser">
              <option value="Galym">Galym</option>
              <option value="Baur">Baur</option>
              <option value="Non-human machine user">
                Non-human machine user
              </option>
              <option value="non-authenticated-user">
                Non-authenticated user
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="interactive-box">
      <p class="details-text">Notifications</p>
      <section class="chat-alerts pt-2"></section>
    </div>
  </div>
</template>

<script lang="ts">
import { useCodeDetails } from "@/composables/useDetails";

export default {
  name: "CodeDetails",
  setup() {
    const { debugCodeModel, debugCodeUser } = useCodeDetails();

    return {
      debugCodeUser,
      debugCodeModel,
    };
  },
};
</script>

<style scoped>
.details-skip {
  height: 100px;
}

.details-box {
  flex: 1;
  border-radius: 15px;
  margin-bottom: 3.25rem;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px #ededf2;
  min-width: 325px;
  width: 325px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.details-text {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
}

.knobs-box {
  height: fit-content;
  padding: 1rem;
}

.testing-box {
  height: fit-content;
  padding: 1rem;
}

.testing-box .accordion-button {
  padding: 1px;
  background-color: white;
}

.testing-box .accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
  background-color: white;
}

.interactive-box {
  flex: 1;
  padding: 1rem;
}
#.form-control {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}
</style>
