<script setup lang="ts">
import { defineProps, withDefaults } from "vue";
import {
  SelectTrigger,
  SelectValue,
  type SelectTriggerProps,
  type SelectValueProps,
} from "radix-vue";

import Icon from "@/components/ui/icons/Icon.vue";

import { selectTriggerVariants, type selectTriggerVariantsProps } from ".";
import { cn } from "@/utils/utils";

interface ISelectTriggerProps extends SelectTriggerProps, SelectValueProps {
  selectValuePlaceholder?: string;
  styles?: string;
  size: selectTriggerVariantsProps["size"];
}
const props = withDefaults(defineProps<ISelectTriggerProps>(), {
  selectValuePlaceholder: "Choose something",
  size: "medium",
});
</script>

<template>
  <SelectTrigger
    :class="
      cn(
        selectTriggerVariants({ intent: 'base', size: props.size }),
        props.styles
      )
    "
  >
    <SelectValue :placeholder="props.selectValuePlaceholder" />

    <Icon name="ShevronUpDown" styles="min-w-[20px] min-h-[20px]" />
  </SelectTrigger>
</template>
