<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { defineProps, shallowRef } from "vue";

import { cn } from "@/utils/utils";

import { icons, type IIconProps } from "./index";

const props = defineProps<IIconProps>();
const CurrentIcon = shallowRef(icons[props.name]);
</script>

<template>
  <component :is="CurrentIcon" :class="cn(props.styles)" />
</template>
