<script setup lang="ts">
import { defineProps } from "vue";
import { PopoverContent, type PopoverContentProps } from "radix-vue";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFlyoutContent extends PopoverContentProps {}
const props = defineProps<IFlyoutContent>();
</script>
<template>
  <PopoverContent
    :side="props.side"
    :side-offset="props['side-offset']"
    class="popover-shadow rounded-small border-[1px] border-base bg-white will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
  >
    <slot />
  </PopoverContent>
</template>
