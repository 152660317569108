<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { defineProps } from "vue";
import { cn } from "@/utils/utils";
import "non.geist/mono";

const props = defineProps<{ styles?: string }>();
</script>
<template>
  <div
    :class="
      cn(
        'inline px-small text-fg-muted text-[13px] leading-5 font-normal bg-subtle border-[1px] border-base rounded-xs',
        props.styles
      )
    "
    style="font-family: Geist Mono Variable"
  >
    <slot />
  </div>
</template>
