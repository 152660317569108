import { defineStore } from "pinia";
import { ref } from "vue";

export type UserReportsType = {
  quota_report: {
    default_quota: number;
    user_quota: number;
  };
  llm_report: {
    stats: {
      llm_type: string;
      request_dates: Record<string, number>;
    }[];
  };
  pii_report: {
    pii_action_stats: {
      action_type: string;
      detected_count: number;
    }[];
    pii_type_stats: {
      pii_type: string;
      detected_count: number;
    }[];
  };
  states_report: {
    states_stats: {
      state: string;
      detected_count: number;
    }[];
  };
  topics_report: {
    popular_topics: Record<string, number>;
  };
  requests_report: {
    most_common_requests: string[];
  };
};

export type ApiKeyReportsType = Omit<UserReportsType, "llm_report">;

export type PerApiKeyReportsType = Record<string, ApiKeyReportsType>;

export const useReportsStore = defineStore("reportsStore", () => {
  const userReports = ref<UserReportsType>({
    quota_report: {
      user_quota: 0,
      default_quota: 50,
    },
    llm_report: {
      stats: [],
    },
    pii_report: {
      pii_type_stats: [],
      pii_action_stats: [],
    },
    states_report: {
      states_stats: [],
    },
    topics_report: {
      popular_topics: {},
    },
    requests_report: {
      most_common_requests: [],
    },
  });

  const perApiKeyReports = ref<PerApiKeyReportsType>({});

  return {
    userReports,
    perApiKeyReports,
  };
});
