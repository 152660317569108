import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import TextClamp from "vue3-text-clamp";
import { useAuthStore } from "@/stores/AuthStore";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import VueApexCharts from "vue3-apexcharts";

import CanvasJSChart from "@canvasjs/vue-charts";

/* import specific icons */
import {
  faHome,
  faMessage,
  faCode,
  faShieldHalved,
  faMagnifyingGlass,
  faChartLine,
  faGear,
  faPaperclip,
  faPaperPlane,
  faPlus,
  faPenToSquare,
  faTrash,
  faBroom,
  faTerminal,
  faEye,
  faEyeSlash,
  faComment,
  faRobot,
  faKey,
  faChartSimple,
  faUserShield,
  faBan,
  faArrowDown,
  faCircleStop,
  faCopy,
  faBook,
  faSort,
  faAngleRight,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./assets/tailwind.css";

/* add icons to the library */
library.add(
  faHome,
  faPaperPlane,
  faPaperclip,
  faMessage,
  faCode,
  faShieldHalved,
  faPlus,
  faMagnifyingGlass,
  faChartLine,
  faPenToSquare,
  faTrash,
  faGear,
  faBroom,
  faTerminal,
  faEye,
  faEyeSlash,
  faComment,
  faRobot,
  faKey,
  faChartSimple,
  faUserShield,
  faArrowDown,
  faBan,
  faCircleStop,
  faCopy,
  faBook,
  faSort,
  faAngleRight,
  faAngleDown
);

console.log(process.env.NODE_ENV);

export const backend = axios.create({
  baseURL: process.env.VUE_APP_API_BACKEND,
});

createApp(App)
  .use(TextClamp)
  .use(createPinia())
  .use(router)
  .use(CanvasJSChart)
  .use(VueApexCharts)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");

const authStore = useAuthStore();
backend.interceptors.request.use(
  function (config) {
    const token = authStore.user?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
