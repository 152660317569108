<script setup lang="ts">
import { reactive, ref } from "vue";

import { useAuthStore } from "@/stores/AuthStore";
import { useForm } from "vee-validate";

import { backend } from "@/main";
import { type IApiKey } from "@/types/apiKey";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import APIKeysRow from "@/components/settings/APIKeysRow.vue";

import {
  DialogClose,
  DialogPortal,
  DialogRoot,
  DialogTrigger,
} from "radix-vue";

import DialogTitle from "@/components/ui/dialog_windows/dialog_type2/DialogTitle.vue";
import DialogContent from "@/components/ui/dialog_windows/dialog_type2/DialogContent.vue";
import DialogOverlay from "@/components/ui/dialog_windows/dialog_type2/DialogOverlay.vue";
import DialogFooter from "@/components/ui/dialog_windows/dialog_type2/DialogFooter.vue";
import DialogBody from "@/components/ui/dialog_windows/dialog_type2/DialogBody.vue";

import InfoBadge from "@/components/ui/InfoBadge.vue";
import Input from "@/components/ui/input/Input.vue";
import Button from "@/components/ui/button/Button.vue";
import Icon from "@/components/ui/icons/Icon.vue";
import Code from "@/components/ui/Code.vue";

import { toTypedSchema } from "@vee-validate/zod";
import { CreateApiKeySchema } from "@/validations/settings/CreateApiKeySchema";

const { values, resetField, handleSubmit } = useForm({
  validationSchema: toTypedSchema(CreateApiKeySchema),
  initialValues: { apiKeyInput: "" },
});

const isInputDialogOpen = ref<boolean>(false);
const openInputDialog = () => (isInputDialogOpen.value = true);
const closeInputDialog = () => (isInputDialogOpen.value = false);

const apiKeyToShowUser = ref<string>("");
const isApiKeyDialogOpen = ref<boolean>(false);
const openApiKeyDialog = () => (isApiKeyDialogOpen.value = true);
const closeApiKeyDialog = () => (isApiKeyDialogOpen.value = false);

const createdAPIKeys: IApiKey[] = reactive([]);

const authStore = useAuthStore();

const refresh = () => {
  authStore.user &&
    backend
      .get(`/api_key/get/${authStore.user.uid}`)
      .then((payload) => {
        if (!payload.data.keys) {
          toast.error("API keys not found", { autoClose: 500 });
        }
        createdAPIKeys.splice(0, createdAPIKeys.length);
        payload.data.keys.forEach((item: IApiKey) => {
          console.log(payload.data.keys, "keys");
          item.created_at = new Date(item.created_at).toLocaleString();
          createdAPIKeys.push(item);
        });
      })
      .catch((error) => {
        toast.error("Error fetching data", { autoClose: 500 });
        console.log(error);
      });
};

const createAPIKey = async () => {
  const apiKeyName = values.apiKeyInput;

  if (apiKeyName) {
    try {
      const payload = await backend.post("/api_key/register", {
        uid: authStore.user.uid,
        name: apiKeyName,
      });

      if (!payload.data.key_value) {
        throw new Error("Key field is not found");
      }

      apiKeyToShowUser.value = payload.data.key_value;
      copyAPIKey();
      openApiKeyDialog();
      payload.data.key_value = "";

      refresh();

      toast.info(
        `The Policy tab has been created for the API key: ${apiKeyName}`,
        {
          autoClose: 1500,
        }
      );
    } catch (error) {
      toast.error("Error creating API key", { autoClose: 500 });
      console.log("Error creating API key", error);
    }
  }
};

const copyAPIKey = () => navigator.clipboard.writeText(apiKeyToShowUser.value);

const onSubmitNewKey = handleSubmit(async (values) => {
  try {
    const apiKeyName = values.apiKeyInput;
    console.log(values.apiKeyInput);

    const keyNameExists = createdAPIKeys.some(
      (key) => key.key_name === apiKeyName
    );
    if (keyNameExists) {
      toast.warning(`API Key '${apiKeyName}' already exists`, {
        autoClose: 500,
      });
      return;
    }
    await createAPIKey();

    resetField("apiKeyInput");
  } catch (error) {
    console.log(error);
  } finally {
    isInputDialogOpen.value = false;
  }
});

refresh();
</script>

<template>
  <section class="py-7xl px-6xl">
    <div class="flex justify-between gap-4xl">
      <div class="pb-4xl">
        <h2 class="text-[18px] leading-6 font-medium text-fg-base pb-[2px]">
          API Key
        </h2>
        <p
          class="self-stretch text-[14px] leading-6 text-fg-subtle font-normal pb-small"
        >
          Your secret API keys are listed below. Please note that we do not
          display your secret API keys again after you generate them.
        </p>
        <InfoBadge>
          Do not share your API key with others, or expose it in the browser or
          other client-side code.
        </InfoBadge>
      </div>
      <DialogRoot :open="isApiKeyDialogOpen">
        <DialogPortal>
          <DialogOverlay @click="closeApiKeyDialog" />
          <DialogContent>
            <DialogTitle>Save your Key</DialogTitle>

            <DialogBody class="p-5xl">
              <p>
                Please save this secret key somewhere safe and accessible. For
                security reasons, you won't be able to view it again through
                your Zenguard account. If you lose this secret key, you'll need
                to generate a new one. Click API key to copy.
              </p>

              <Code styles="cursor-pointer" @click="copyAPIKey">
                {{ apiKeyToShowUser }}
              </Code>
            </DialogBody>

            <DialogFooter>
              <DialogClose as-child>
                <Button
                  @click="closeApiKeyDialog"
                  intent="primary"
                  size="small"
                >
                  Close
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </DialogPortal>
      </DialogRoot>

      <div v-if="createdAPIKeys.length !== 0">
        <DialogRoot :open="isInputDialogOpen">
          <DialogTrigger>
            <Button @click="openInputDialog" size="small" intent="secondary">
              <template #iconLeft>
                <Icon
                  styles="stroke-[#959AB1] min-h-[20px] min-w-[20px]"
                  name="Plus"
                />
              </template>
              Create API Key
            </Button>
          </DialogTrigger>

          <DialogPortal>
            <DialogOverlay @click="closeInputDialog" />
            <DialogContent>
              <DialogTitle>Create API Key</DialogTitle>

              <form @submit.prevent="onSubmitNewKey">
                <DialogBody class="p-5xl">
                  <Input
                    labelText="API Key"
                    placeholder="Enter API Key Name"
                    intent="default"
                    size="medium"
                    name="apiKeyInput"
                    id="apiKeyInput"
                    type="text"
                  />
                </DialogBody>

                <DialogFooter>
                  <DialogClose @click="closeInputDialog" as-child>
                    <Button type="button" intent="secondary" size="small">
                      Cancel
                    </Button>
                  </DialogClose>

                  <Button type="submit" intent="primary" size="small">
                    Create
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </DialogPortal>
        </DialogRoot>
      </div>
    </div>

    <div>
      <div v-if="createdAPIKeys.length === 0">
        <div
          class="flex flex-col justify-center items-center min-h-[238px] py-[320px] border-[1px] border-base rounded-small"
        >
          <div class="pb-5xl">
            <h3 class="text-[14px] leading-5 font-medium text-fg-base pb-[4px]">
              You don't have any API keys yet
            </h3>
            <p class="text-[13px] leading-5 font-normal text-fg-subtle">
              API Keys are randomly generated.
            </p>
          </div>

          <DialogRoot :open="isInputDialogOpen">
            <DialogTrigger>
              <Button @click="openInputDialog" size="small" intent="primary">
                <template #iconLeft>
                  <Icon
                    styles="stroke-white min-h-[20px] min-w-[20px]"
                    name="Plus"
                  />
                </template>
                Create Secret Key
              </Button>
            </DialogTrigger>

            <DialogPortal>
              <DialogOverlay @click="closeInputDialog" />
              <DialogContent>
                <DialogTitle>Create API Key</DialogTitle>

                <form @submit.prevent="onSubmitNewKey">
                  <DialogBody class="p-5xl">
                    <Input
                      labelText="API Key"
                      placeholder="Enter API Key Name"
                      intent="default"
                      size="medium"
                      name="apiKeyInput"
                      id="apiKeyInput"
                      type="text"
                    />
                  </DialogBody>

                  <DialogFooter>
                    <DialogClose @click="closeInputDialog" as-child>
                      <Button type="button" intent="secondary" size="small">
                        Cancel
                      </Button>
                    </DialogClose>

                    <Button type="submit" intent="primary" size="small">
                      Create
                    </Button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </DialogPortal>
          </DialogRoot>
        </div>
      </div>

      <div v-else>
        <div
          class="text-fg-subtle text-[13px] leading-5 font-weight min-w-[1056px] flex flex-row items-center border-[1px] justify-between border-base rounded-small bg-subtle overflow-x-scroll"
        >
          <div class="pl-2xl pr-medium py-medium w-[43px]">#</div>
          <div class="px-medium flex-1 max-w-[693px]">Name</div>
          <div class="px-medium flex-1 max-w-[101px]">API Key</div>
          <div class="px-medium flex-1 max-w-[175px] text-right">
            Created At
          </div>
          <div
            class="pl-medium pr-2xl flex-1 max-w-[46px] min-w-[46px] h-[40px]"
          />
        </div>

        <div class="min-h-[692px]">
          <APIKeysRow
            :refresh="refresh"
            v-for="(apiKey, index) in createdAPIKeys"
            :key="index"
            :apiKey="apiKey"
            :index="index"
          />
        </div>
      </div>
    </div>
  </section>
</template>
