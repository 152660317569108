import { z } from "zod";

export const CreateApiKeySchema = z.object({
  apiKeyInput: z
    .string()
    .trim()
    .regex(/^[\w ]+$/, {
      message: "Only any letter, digit or underscore or space",
    })
    .min(2, {
      message: "API Key should be at least 2 characters",
    }),
});
