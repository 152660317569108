<script setup lang="ts">
import { computed, defineProps } from "vue";

import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";

import { cn } from "@/utils/utils";

import Icon from "@/components/ui/icons/Icon.vue";

const props = defineProps<{
  passwordValue: string;
  passwordValidations: {
    isValid: boolean;
    message: string;
  }[];
}>();

const options = {
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  translations: zxcvbnEnPackage.translations,
};
const passwordScore = computed(() => {
  const hasValue = props.passwordValue && props.passwordValue.length > 0;

  if (!hasValue) return 0;

  return zxcvbn(props.passwordValue).score + 1;
});

const descriptions = computed(() => [
  {
    color: "text-danger",
    label: "Weak",
  },
  { color: "text-danger", label: "Weak" },
  { color: "text-[#cbc442]", label: "Medium" },
  { color: "text-success", label: "Strong" },
  {
    color: "text-success",
    label: "Strong",
  },
]);

const description = computed(() =>
  props.passwordValue && props.passwordValue.length > 0
    ? descriptions.value[passwordScore.value - 1] //if password field empty
    : {
        color: "text-danger",
        label: "Weak",
      }
);

zxcvbnOptions.setOptions(options);
</script>

<template>
  <div
    class="flex items-start justify-between text-[12px] leading-5 phone-375:flex-col"
  >
    <p class="text-fg-muted">
      Password Strength:
      <span :class="cn(description.color)">{{ description.label }}</span>
    </p>

    <div>
      <p
        v-for="validation in passwordValidations"
        :key="validation.message"
        :class="
          cn(
            'flex gap-2xs justify-start items-center',
            validation.isValid ? 'text-success' : 'text-fg-muted'
          )
        "
      >
        <Icon
          :styles="validation.isValid ? 'stroke-success' : 'stroke-[#959AB1]'"
          name="CheckMark"
        />
        <span>{{ validation.message }}</span>
      </p>
    </div>
  </div>
</template>
