<template>
  <div class="chat-box">
    <section class="chat pt-2" style="white-space: pre-line">
      <div v-for="(item, index) in response_data" :key="index">
        <span :class="item.color">{{ item.user }}:</span>
        {{ item.text }}
      </div>
    </section>
  </div>
  <div class="chat-message">
    <div class="w-75 chat-form-container">
      <form @submit.prevent="onSubmit" id="input-form">
        <div class="input-group input-group-lg">
          <button
            class="btn btn-outline-secondary"
            type="button"
            id="attach-button"
          >
            <font-awesome-icon icon="fas fa-paperclip" />
          </button>
          <input
            type="text"
            class="form-control"
            placeholder="Message..."
            aria-label="LLM Query"
            aria-describedby="Input Code"
            autofocus
            ref="inputRef"
          />
          <button class="btn btn-outline-secondary" type="submit">
            <font-awesome-icon icon="fas fa-paper-plane" />
          </button>
        </div>
      </form>
      <button
        class="btn btn-outline-secondary"
        type="button"
        @click="clearChatWindow()"
        :disabled="working"
      >
        <font-awesome-icon icon="fas fa-broom" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { useCodeDetails } from "@/composables/useDetails";
import { ref } from "vue";
import { createSocket } from "@/socket";
import { useAuthStore } from "@/stores/AuthStore";
export default {
  name: "CodeView",
  setup() {
    const authStore = useAuthStore();
    const token = authStore?.user?.token;
    const socket = createSocket(token);
    authStore.setSocket(socket);
    const inputRef = ref<HTMLInputElement | null>(null);
    const working = ref<boolean>(false);

    const { debugCodeModel, debugCodeUser } = useCodeDetails();

    function onSubmit() {
      try {
        response_data.value.push({
          color: "text-primary",
          user: debugCodeUser.value,
          text: inputRef.value?.value ?? "",
        });
        socket.emit("message", {
          user: debugCodeUser.value,
          model: debugCodeModel.value,
          message: inputRef.value?.value,
        });
        working.value = true;
      } catch (error) {
        console.log(error);
      }

      if (inputRef.value) {
        inputRef.value.value = "";
        inputRef.value.focus();
      }
    }

    const response_data = ref<
      Array<{
        color: string;
        user: string;
        text: string;
        stream_complete?: boolean;
      }>
    >([]);

    function clearChatWindow() {
      if (confirm("The chat window will be cleared. Are you sure?"))
        response_data.value = [];
    }

    const scroll = () => {
      setTimeout(() => {
        const form = document.getElementById("input-form");
        form.scrollIntoView();
      }, 250);
    };

    socket.on("message", (payload) => {
      const data = JSON.parse(payload);
      console.log(data);

      if (data.cached) {
        response_data.value.push({
          color: "text-success",
          user: debugCodeModel.value,
          text: data.response,
        });
        working.value = false;
        scroll();
        return;
      }

      if (data.error != "OK") {
        response_data.value.push({
          color: "text-success",
          user: debugCodeModel.value,
          text: data.error,
        });
        working.value = false;
        scroll();
        return;
      }

      const last = response_data.value.length - 1;

      // note that all contents should have been sent out by this time.
      if ("end_stream" in data && data.end_stream) {
        working.value = false;
        scroll();
        return;
      }

      if (
        "stream_complete" in response_data.value[last] &&
        !response_data.value[last].stream_complete
      ) {
        response_data.value[last].text += data.response;
      } else {
        response_data.value.push({
          color: "text-success",
          user: debugCodeModel.value,
          text: data.response,
          stream_complete: false,
        });
      }
    });
    scroll();
    return {
      inputRef,
      onSubmit,
      response_data,
      clearChatWindow,
      working,
    };
  },
};
</script>

<style scoped>
.chat-options {
  display: flex;
  justify-content: center;
  gap: 60px;
  align-items: last baseline;
}

.chat-box {
  flex: 1;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px #ededf2;
  border-radius: 15px;
  margin-bottom: 1rem;
}

.chat {
  margin-left: 80px;
}

.chat-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chat-form-container {
  margin-top: auto;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-around;
}

#input-form {
  width: 90%;
}

.response {
  color: black;
}

.warning {
  color: red;
}

.request {
  color: black;
}
</style>
