<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { defineProps } from "vue";

import { cn } from "@/utils/utils";

import { type VariantProps } from "class-variance-authority";
import { separatorVariants } from ".";

type SeparatorVariants = VariantProps<typeof separatorVariants>;

const props = defineProps<{
  intent: SeparatorVariants["intent"];
  styles?: string;
}>();
</script>

<template>
  <div :class="cn(separatorVariants({ intent: props.intent }), props.styles)" />
</template>
