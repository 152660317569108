<script setup lang="ts">
import { defineProps } from "vue";
import { cn } from "@/utils/utils";

const props = defineProps<{
  styles?: string;
}>();
</script>
<template>
  <div
    :class="
      cn(
        'py-large px-2xl flex flex-row items-center min-w-[234px] max-w-full text-[13px] leading-5 hover:bg-[#F9F9FB]',
        props.styles
      )
    "
  >
    <slot />
  </div>
</template>
