import { cva } from "class-variance-authority";
export { default as Button } from "./Button.vue";

export const buttonVariants = cva(
  "flex flex-row gap-2xs items-center shadow-btn-shadow justify-center transition border-[1px] rounded-small text-[14px] font-medium leading-5 focus:shadow-btn-focused disabled:cursor-not-allowed",
  {
    variants: {
      intent: {
        primary:
          " bg-btn-primary-default  border-brand text-white hover:bg-btn-primary-hover  focus:bg-btn-primary-default  active:bg-btn-primary-pressed disabled:bg-btn-primary-disabled disabled:border-base disabled:cursor-not-allowed disabled:text-fg-muted",
        secondary:
          " border-muted text-fg-base bg-subtle hover:bg-btn-secondary-hover focus:bg-subtle  active:bg-btn-secondary-pressed disabled:bg-subtle disabled:text-fg-muted",
        danger:
          "bg-danger border-danger text-white hover:bg-[#E21D48] active:bg-[#D41B44] disabled:bg-subtle disabled:border-base",
        tertiary:
          "text-brand bg-btn-tertiary border-tertiary stroke-[#908AFF] active:bg-btn-tertiary-pressed hover:bg-btn-tertiary-hover disabled:bg-[#F9F9FB] disabled:cursor-not-allowed disabled:text-[#C5C2FF] disabled:stroke-[#C5C2FF]",
      },
      size: {
        large: "py-xl px-3xl",
        medium: "py-medium px-2xl",
        small: "py-[6px] px-[8px]",
        "small-icon": "p-xs",
        "tiny-icon": "p-3xs",
      },
    },
  }
);
// TODO:FIX OUTLINE and tab focus
