import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-293c1984"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-box" }
const _hoisted_2 = {
  class: "chat pt-2",
  style: {"white-space":"pre-line"}
}
const _hoisted_3 = { class: "chat-message" }
const _hoisted_4 = { class: "w-75 chat-form-container" }
const _hoisted_5 = { class: "input-group input-group-lg" }
const _hoisted_6 = {
  class: "btn btn-outline-secondary",
  type: "button",
  id: "attach-button"
}
const _hoisted_7 = {
  type: "text",
  class: "form-control",
  placeholder: "Message...",
  "aria-label": "LLM Query",
  "aria-describedby": "Input Code",
  autofocus: "",
  ref: "inputRef"
}
const _hoisted_8 = {
  class: "btn btn-outline-secondary",
  type: "submit"
}
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.response_data, (item, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("span", {
              class: _normalizeClass(item.color)
            }, _toDisplayString(item.user) + ":", 3),
            _createTextVNode(" " + _toDisplayString(item.text), 1)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("form", {
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($setup.onSubmit && $setup.onSubmit(...args)), ["prevent"])),
          id: "input-form"
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", _hoisted_6, [
              _createVNode(_component_font_awesome_icon, { icon: "fas fa-paperclip" })
            ]),
            _createElementVNode("input", _hoisted_7, null, 512),
            _createElementVNode("button", _hoisted_8, [
              _createVNode(_component_font_awesome_icon, { icon: "fas fa-paper-plane" })
            ])
          ])
        ], 32),
        _createElementVNode("button", {
          class: "btn btn-outline-secondary",
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.clearChatWindow())),
          disabled: $setup.working
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "fas fa-broom" })
        ], 8, _hoisted_9)
      ])
    ])
  ], 64))
}