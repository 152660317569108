<script setup lang="ts">
import { defineProps } from "vue";

import {
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  type SelectItemProps,
} from "radix-vue";

import Icon from "../icons/Icon.vue";

interface ISelectItemProps extends SelectItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: string[];
}
const props = defineProps<ISelectItemProps>();
</script>

<template>
  <SelectItem
    v-for="(item, index) in props.items"
    :key="index"
    class="text-[13px] leading-5 font-normal text-fg-base flex items-center py-small px-large select-none rounded-xs gap-small data-[disabled]:text-fg-base data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-subtle cursor-pointer"
    :value="item"
  >
    <div class="w-[20px] h-[20px]">
      <SelectItemIndicator class="items-center justify-center">
        <Icon name="Dot" />
      </SelectItemIndicator>
    </div>

    <SelectItemText>
      {{ item }}
    </SelectItemText>
  </SelectItem>
</template>
